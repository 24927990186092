import { CoachingType, Modals } from '../../enums';

import { useCallback, useState } from 'react';
import { setModal, setModalData } from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/ui/uiSlice';
import { flexUpdate } from '../../api';
import { Flex, User, CoachProgramme } from '../../interfaces';
import { RootState } from '../../redux/store';

function makeid(length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function CoachProgrammeSetupModal() {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const coachProgrammeDisplay = uiState.modalData ?? {};
  const [numberOfSessions, setNumberOfSessions] = useState<string | undefined>(
    coachProgrammeDisplay.numberOfSessions,
  );
  const [coachingType, setCoachingType] = useState<string | undefined>(
    coachProgrammeDisplay.coachingType,
  );
  const [coachingRate, setCoachingRate] = useState<string | undefined>('0.00');
  const [orgCode] = useState<string>(makeid(10).toUpperCase());
  // const [coachees, setCoachees] = useState<Array<User>>([{}, {}]);
  const [coachees, setCoachees] = useState<Array<User>>(
    coachProgrammeDisplay.coachees,
  );

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(orgCode);
  }, [orgCode]);

  const onAccept = useCallback(() => {
    dispatch(showLoader());

    const data: CoachProgramme = {
      ...coachProgrammeDisplay,
      coachingRate: Number((coachingRate ?? '0').replace(',', '.')),
      orgCode: orgCode,
      numberOfSessions: numberOfSessions,
      createdAt: new Date(),
    };

    const flex: Flex = {
      collection: 'coaching-programme',
      model: data,
      _id: coachProgrammeDisplay._id,
    };

    flexUpdate(flex)
      .then(response => {
        dispatch(hideLoader());
        if (response.status === 200) {
          dispatch(setModal(Modals.None));
          dispatch(setModalData(data));
          setTimeout(() => {
            dispatch(setModal(Modals.CoachProgrammeCoaches));
          }, 500);
        } else {
          alert('This operation could not be completed at this time.');
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(hideLoader());
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [
    dispatch,
    coachProgrammeDisplay,
    coachingRate,
    orgCode,
    numberOfSessions,
  ]);

  const onAddCoachee = useCallback(() => {
    let newCoachees = [...coachees];
    newCoachees.push({});
    setCoachees(newCoachees);
  }, [coachees]);

  const onSetCoacheeName = useCallback(
    (index: number, newValue: string) => {
      let newArr = [...coachees];
      newArr[index].firstname = newValue;
      setCoachees(newArr);
    },
    [coachees],
  );

  const onSetCoacheeEmail = useCallback(
    (index: number, newValue: string) => {
      let newArr = [...coachees];
      newArr[index].email = newValue;
      setCoachees(newArr);
    },
    [coachees],
  );

  return (
    <div
      className="modal modal-visible modal-backdrop2"
      id="coachProgrammeSetupModal"
      role="dialog"
      aria-labelledby="coachProgrammeSetupModal"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Coaching Programme Setup</h4>
            <button
              type="button"
              className="btn btn-darkblue close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-12 center">
                <h5 className="modal-sub-title">
                  Edit coaching programme request
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Organisation</div>
              <div className="col-lg-3">
                : {coachProgrammeDisplay.organisation}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Department</div>
              <div className="col-lg-3">
                : {coachProgrammeDisplay.department}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Department Head</div>
              <div className="col-lg-3">
                : {coachProgrammeDisplay.departmentHead}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Programme Name</div>
              <div className="col-lg-3">
                : {coachProgrammeDisplay.programmeName}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h5 className="modal-sub-title">Coachees</h5>
              </div>
              <div className="col-lg-10 detail-table">
                <div className="row">
                  <div className="col-lg-6">
                    <strong>Coachee</strong>
                  </div>
                  <div className="col-lg-6">
                    <strong>Email</strong>
                  </div>
                </div>
                {coachees?.map((coachee, index) => (
                  <div className="row">
                    <div className="col-lg-6">
                      {coachee.firstname ?? (
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput1"
                            placeholder=""
                            value={coachee.firstname}
                            onChange={val =>
                              onSetCoacheeName(index, val.target.value)
                            }
                          />
                          <label>Name</label>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      {coachee.email ?? (
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput2"
                            placeholder=""
                            value={coachee.email}
                            onChange={val =>
                              onSetCoacheeEmail(index, val.target.value)
                            }
                          />
                          <label>Email</label>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="row mt-3">
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-darkblue"
                      onClick={() => onAddCoachee()}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 center">
                <h5 className="modal-sub-title">Programme details</h5>
                <span>you can manually edit programme request details</span>
              </div>
            </div>
            <form className="mt-1">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-floating mb-3">
                    <select
                      className="form-control"
                      id="floatingInput"
                      value={coachingType}
                      onChange={val => setCoachingType(val.target.value)}>
                      <option value=""></option>
                      <option value=""></option>
                      <option value={CoachingType.GroupTeamCoaching}>
                        {CoachingType.GroupTeamCoaching}
                      </option>
                      <option value={CoachingType.StressResilience}>
                        {CoachingType.StressResilience}
                      </option>
                      <option value={CoachingType.ConflictCoaching}>
                        {CoachingType.ConflictCoaching}
                      </option>
                      <option value={CoachingType.EntrepreneurCoaching}>
                        {CoachingType.EntrepreneurCoaching}
                      </option>
                      <option value={CoachingType.SalesCoaching}>
                        {CoachingType.SalesCoaching}
                      </option>
                      <option value={CoachingType.SomaticBodyCoaching}>
                        {CoachingType.SomaticBodyCoaching}
                      </option>
                      <option value={CoachingType.OntologicalCoaching}>
                        {CoachingType.OntologicalCoaching}
                      </option>
                      <option value={CoachingType.LeadershipStrategy}>
                        {CoachingType.LeadershipStrategy}
                      </option>
                      <option value={CoachingType.LeadershipDevelopment}>
                        {CoachingType.LeadershipDevelopment}
                      </option>
                      <option value={CoachingType.ExecutiveCoaching}>
                        {CoachingType.ExecutiveCoaching}
                      </option>
                      <option value={CoachingType.LeadingChange}>
                        {CoachingType.LeadingChange}
                      </option>
                      <option value={CoachingType.LeaderAsCoach}>
                        {CoachingType.LeaderAsCoach}
                      </option>
                      <option value={CoachingType.LeadershipBrandReputation}>
                        {CoachingType.LeadershipBrandReputation}
                      </option>
                      <option value={CoachingType.LanguageForLeaders}>
                        {CoachingType.LanguageForLeaders}
                      </option>
                      <option value={CoachingType.PerceptionManagement}>
                        {CoachingType.PerceptionManagement}
                      </option>
                      <option
                        value={
                          CoachingType.EmotionalLearningForBetterLeadership
                        }>
                        {CoachingType.EmotionalLearningForBetterLeadership}
                      </option>
                    </select>
                    <label>Coaching Type</label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-floating mb-3">
                    <input
                      min={1}
                      max={160}
                      maxLength={3}
                      type="number"
                      className="form-control"
                      id="floatingInput"
                      value={numberOfSessions}
                      onChange={val => setNumberOfSessions(val.target.value)}
                    />
                    <label>Hours</label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-floating mb-3">
                    <input
                      min={1}
                      max={100000}
                      type="number"
                      className="form-control"
                      id="floatingInput"
                      value={coachingRate}
                      onChange={val => setCoachingRate(val.target.value)}
                    />
                    <label>Rate/hr</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      id="floatingInput"
                      value={orgCode}
                    />
                    <label>Organisation code</label>
                  </div>
                </div>
                <div className="col-lg-3 vcenter">
                  <button
                    type="button"
                    className="btn btn-darkblue"
                    onClick={() => onCopy()}>
                    Copy
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-darkblue"
              onClick={() => onAccept()}>
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoachProgrammeSetupModal;
