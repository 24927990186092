import { hideMessage } from "../../redux/ui/uiSlice";
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { RootState } from "../../redux/store";

interface ConfirmProps {
  title: string;
  message: string;
  buttonTitle1: string;
  buttonTitle2: string;
  onClose?: (buttonIndex: number) => void;
}

function ConfirmModal({
  buttonTitle1,
  buttonTitle2,
  title,
  message,
  onClose,
}: ConfirmProps) {
  const onClosePress = useCallback(
    (option: number) => {
      if (onClose) {
        onClose(option);
      }
    },
    [onClose]
  );

  return (
    <div
      className="modal modal-visible modal-backdrop2"
      id="confirmModal"
      role="dialog"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
          </div>
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-darkblue"
                  data-dismiss="modal"
                  onClick={() => onClosePress(1)}
                >
                  {buttonTitle1}
                </button>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-darkblue"
                  data-dismiss="modal"
                  onClick={() => onClosePress(2)}
                >
                  {buttonTitle2}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
