import { AppScreens, Modals, UserType } from '../../enums';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';

import { useCallback } from 'react';
import { setModal, setModalData, setScreen } from '../../redux/ui/uiSlice';
import { Department } from '../../interfaces';
import { setSelectedDepartment } from '../../redux/session/sessionSlice';
import { ReportViewerProps } from '../../modals/report-viewer';

function SideMenu() {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch();

  const onAddOrganisation = useCallback(() => {
    dispatch(setModal(Modals.EditOrg));
  }, [dispatch]);

  const onScreen = useCallback(
    (screenName: AppScreens) => {
      dispatch(setScreen(screenName));
    },
    [dispatch],
  );

  const onAddCoachee = useCallback(
    (department: Department) => {
      dispatch(setSelectedDepartment(department));
      dispatch(setModal(Modals.NewCoacheeRequest));
    },
    [dispatch],
  );

  const getclassName = useCallback(
    (screenName: AppScreens) => {
      if (screenName === uiState.screen) {
        return 'nav-item active';
      } else {
        return 'nav-item';
      }
    },
    [uiState.screen],
  );

  const onTest = useCallback(() => {
    const report: ReportViewerProps = {
      report: 'Attendance Report',
      data: 'abc',
    };
    dispatch(setModalData(report));
    dispatch(setModal(Modals.ReportViewer));
  }, [dispatch]);

  return (
    <>
      <div className="col left-menu d-flex flex-column">
        <div className="text-center">
          <img src="images/logo.png" className="logo" />
        </div>
        <div className="flex-fill">
          {/* <a
            className="nav-link"
            aria-current="page"
            href="#"
            onClick={() => onTest()}>
            Test
          </a> */}
          {sessionState.user?.usertype === UserType.Admin ? (
            <ul className="nav flex-column">
              <li className={getclassName(AppScreens.Dashboard)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.Dashboard)}>
                  <img src="images/svg/dashboard.svg" />
                  Dashboard
                </a>
              </li>
              {/* <li className={getclassName(AppScreens.CoachingAttendanceReport)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.CoachingAttendanceReport)}>
                  <img src="images/svg/clipboard.svg" />
                  Reports
                </a>
              </li> */}
              <li className={getclassName(AppScreens.BillingBreakdown)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.BillingBreakdown)}>
                  <img src="images/svg/billing.svg" alt="" />
                  Billing
                </a>
              </li>
              <li className={getclassName(AppScreens.Coaches)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.Coaches)}>
                  <img src="images/svg/coaching.svg" alt="" />
                  Coaches
                </a>
              </li>
              <li className={getclassName(AppScreens.ClientList)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.ClientList)}>
                  <img src="images/svg/user-group.svg" alt="" />
                  Clients
                </a>
              </li>
              <li className={getclassName(AppScreens.Coachees)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.Coachees)}>
                  <img src="images/svg/coaching.svg" alt="" />
                  Coachees
                </a>
              </li>
              <li className={getclassName(AppScreens.AdminDropBox)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.ManageLibrary)}>
                  <img src="images/svg/admin-box.svg" alt="" />
                  Manage Library
                </a>
              </li>
            </ul>
          ) : (
            <ul className="nav flex-column">
              <li className={getclassName(AppScreens.OrgDashboard)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.OrgDashboard)}>
                  <img src="images/svg/dashboard.svg" />
                  Dashboard
                </a>
              </li>
              {/* <li className={getclassName(AppScreens.OrgReports)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.OrgReports)}>
                  <img src="images/svg/clipboard.svg" />
                  Reports
                </a>
              </li> */}
              <li className={getclassName(AppScreens.OrgBilling)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.OrgBilling)}>
                  <img src="images/svg/billing.svg" alt="" />
                  Billing
                </a>
              </li>
              <li className={getclassName(AppScreens.OrgPastCoaching)}>
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={() => onScreen(AppScreens.OrgPastCoaching)}>
                  <img src="images/svg/coaching.svg" alt="" />
                  Past Coaching
                </a>
              </li>
            </ul>
          )}
        </div>
        {sessionState.user?.usertype === UserType.Admin ? (
          <div
            className="btn btn-white-outline btn-lg"
            onClick={onAddOrganisation}>
            <img src="images/icons/icon_plus.png" alt="" />
            <br />
            Add An Organization
          </div>
        ) : null}
        {sessionState.user?.usertype === UserType.Company ? (
          <>
            <hr className="me-3 mb-0" />
            <div className="nav-item">
              <div className="nav-link">
                <img
                  src="./images/icons/icon_teams.png"
                  className="d-inline-block"
                  alt=""
                />
                <div className="d-inline-block text-white">Active teams</div>
              </div>
            </div>
            <div className="activeTeamContainer">
              {sessionState.org?.departments?.map(department => {
                return (
                  <>
                    {department.department !== undefined ? (
                      <div className="active-team-container me-3 mb-2">
                        <div className="fnt-small">{department.department}</div>
                        <ul className="team-member-list">
                          {/* <li className='team-member'>
                      <img src='./images/avatar.png' />
                    </li>
                    <li className='team-member'>
                      <img src='./images/avatar.png' />
                    </li>
                    <li className='team-member'>
                      <img src='./images/avatar.png' />
                    </li>
                    <li className='team-member'>
                      <img src='./images/avatar.png' />
                    </li>
                    <li className='team-member'>
                      <img src='./images/avatar.png' />
                    </li>
                    <li className='team-member'>
                      <div className='more-members'>+3</div>
                    </li> */}
                          {sessionState.orgUsers === null ? (
                            <li className="team-member">
                              <div className="more-members">0</div>
                            </li>
                          ) : null}
                        </ul>
                        <div>
                          <button
                            type="button"
                            onClick={() => onAddCoachee(department)}
                            className="btn btn-sml btn-transparent mt-3 p-0 text-white light-italic">
                            <i className="fa fa-plus-circle pe-2"></i>Add
                            coachee
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default SideMenu;
