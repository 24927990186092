import { Config } from './config';
import { ListItem, User } from './interfaces';

export const enumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] => {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
};

export const listFromEnum = (en: any): Array<ListItem> => {
  let newList: Array<ListItem> = [];
  for (const value of enumKeys(en)) {
    //@ts-ignore
    newList.push({ label: en[value], value: en[value] });
  }
  return newList;
};

export const makeId = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

// export const getProfilePicture = (user: User | undefined): any => {
//   console.log('user', user?.profilepicture);
//   if (user && user.profilepicture) {
//     return (
//       Config.API_SERVER.replace('/api', '/uploads/') +
//         'small_' +
//         user.profilepicture?.path?.replace('uploads/', '') ?? ''
//     );
//   } else {
//     return '/images/avatar.png';
//   }
// };

export const getProfilePicture = (user: User | undefined): any => {
  if (user && user.profilepicture) {
    return (
      Config.IMAGES_API_SERVER.replace('/api', '/uploads/') +
        'small_' +
        user.profilepicture?.path?.replace('uploads/', '') ?? ''
    );
  } else {
    return '/images/avatar.png';
  }
};
