import {
  hideLoader,
  hideMessage,
  setModal,
  showLoader,
  showMessage,
} from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { RootState } from '../../redux/store';
import { AppScreens, Modals } from '../../enums/index';
import { Flex, Mail, User } from '../../interfaces';
import { flexUpdate } from '../../api';
import { sendMail } from '../../api/comms.api';

function AcceptCoachModal() {
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const [days, setDays] = useState<number | undefined>();

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  const save = useCallback(
    (updatedUser: User) => {
      dispatch(showLoader());
      const flex: Flex = {
        collection: 'users',
        model: updatedUser,
        _id: updatedUser?._id,
      };
      flexUpdate(flex)
        .then(response => {
          dispatch(hideLoader());
          if (response.status === 200) {
            if (updatedUser.accepted) {
              const mail: Mail = {
                to: [updatedUser.email ?? ''],
                subject: 'Nabantu Coach Profile Approved',
                html:
                  '<p>Hi ' +
                  updatedUser.firstname +
                  ',</p><p>Your coaching profile was approved. Please login to the app to update your subscription payment.</p><p>Regards,<br />Nabantu Team',
              };

              sendMail(mail)
                .then(() => {
                  dispatch(
                    showMessage({
                      title: '',
                      message:
                        updatedUser.firstname +
                        ' ' +
                        updatedUser.lastname +
                        ' has been notified and will be automatically added to the app when he/she subscribes.',
                    }),
                  );
                })
                .catch(err => {
                  console.log(err);
                  dispatch(hideLoader());
                  if (err?.data && err.data?.message) {
                    alert(err.data.message);
                  } else {
                    alert('Unable to complete this operation.');
                  }
                });
            }
          } else {
            alert('This operation could not be completed at this time.');
          }
        })
        .catch(err => {
          dispatch(hideLoader());
          if (err?.data && err.data?.message) {
            alert(err.data.message);
          } else {
            alert('Unable to complete this operation.');
          }
        });
    },
    [dispatch],
  );

  const onConfirm = useCallback(() => {
    if ((days ?? '0') !== '0') {
      const trailEndDate: Date = new Date();
      trailEndDate.setDate(trailEndDate.getDate() + Number(days));
      dispatch(showLoader());
      const updateUser: User = {
        ...sessionState.selectedUser,
        trialExpireOn: trailEndDate,
        accepted: true,
        acceptedOn: new Date(),
      };

      const flex: Flex = {
        collection: 'users',
        model: updateUser,
        _id: sessionState.selectedUser?._id,
      };
      flexUpdate(flex)
        .then(() => {
          save(updateUser);
          dispatch(hideLoader());
          dispatch(setModal(Modals.None));
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    } else {
      dispatch(showLoader());
      const updateUser: User = {
        ...sessionState.selectedUser,
        accepted: true,
        acceptedOn: new Date(),
      };

      const flex: Flex = {
        collection: 'users',
        model: updateUser,
        _id: sessionState.selectedUser?._id,
      };
      flexUpdate(flex)
        .then(() => {
          save(updateUser);
          dispatch(hideLoader());
          dispatch(setModal(Modals.None));
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [days, dispatch, sessionState.selectedUser, save]);

  return (
    <div
      className="modal modal-visible modal-backdrop2"
      id="confirmModal"
      role="dialog"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Offer Trial</h4>
          </div>
          <div className="modal-body">
            <p>
              Would you like to offer {sessionState.selectedUser?.firstname}{' '}
              {sessionState.selectedUser?.lastname} coach a free trial?
            </p>
            <form className="mt-1">
              <div className="form-floating mb-3">
                <select
                  className="form-control"
                  id="floatingInput"
                  value={days?.toString()}
                  onChange={val => setDays(Number(val.target.value ?? '0'))}>
                  <option value="" selected>
                    No - 0 Days
                  </option>
                  <option value="7">7 Days</option>
                  <option value="14">14 Days</option>
                  <option value="21">21 Days</option>
                  <option value="30">30 Days</option>
                  <option value="60">60 Days</option>
                  <option value="90">90 Days</option>
                </select>
                <label>Number of Days Trial</label>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-darkblue"
                  data-dismiss="modal"
                  onClick={() => onConfirm()}>
                  Confirm
                </button>
              </div>
              <div className="col-md-1">&nbsp;</div>
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-darkblue"
                  data-dismiss="modal"
                  onClick={() => onClose()}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptCoachModal;
