import {
  setModal,
  setModalData,
  showLoader,
  hideLoader,
  showMessage,
} from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { User, Flex, Mail } from '../../interfaces';
import { useCallback, useState } from 'react';
import { Modals } from '../../enums';
import { flexUpdate } from '../../api';
import { sendMail } from '../../api/comms.api';
import { Config } from '../../config';

function CoachDenialModal() {
  const uiState = useSelector((state: RootState) => state.ui);
  const coach: User = uiState.modalData;
  const [reason, setReason] = useState<string | undefined>();

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(setModalData(null));
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  const onOK = useCallback(() => {
    dispatch(showLoader());

    const data: User = {
      ...coach,
      accepted: false,
      rejected: true,
      rejectedReason: reason,
    };

    const flex: Flex = {
      collection: 'users',
      model: data,
      _id: coach._id,
    };
    flexUpdate(flex)
      .then((response) => {
        const mail: Mail = {
          to: [coach.email ?? ''],
          bcc: [Config.APP_ADMIN],
          subject: 'Coaching Application Denied',
          html:
            '<p>Dear ' +
            coach.firstname +
            '</p>' +
            '<p>Your coaching application was denied because of: ' +
            reason +
            '.</p><p>Regards,<br />Nabantu Team',
        };

        sendMail(mail)
          .then((mailResponse) => {
            console.log('mailResponse', mailResponse);
            dispatch(hideLoader());
            if (response.status === 200) {
              dispatch(setModal(Modals.None));
              dispatch(setModalData(null));
              dispatch(
                showMessage({
                  title: '',
                  message:
                    coach.firstname +
                    ' ' +
                    coach.lastname +
                    ' has been notified on how to improve in order to qualify to be on the app.',
                })
              );
            } else {
              alert('This operation could not be completed at this time.');
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch(hideLoader());
            if (err?.data && err.data?.message) {
              alert(err.data.message);
            } else {
              alert('Unable to complete this operation.');
            }
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [dispatch, coach, reason]);

  return (
    <div
      className='modal modal-visible modal-backdrop2'
      id='exampleModal'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4>&nbsp;</h4>
            <button
              type='button'
              className='btn btn-darkblue close-btn'
              data-dismiss='modal'
              aria-label='Close'
              onClick={() => onClose()}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-lg-2'>
                <img
                  src='./images/avatar.png'
                  className='small-profile'
                  alt=''
                />
              </div>
              <div className='col-lg-10'>
                <h4>
                  {coach.firstname} {coach.lastname}
                </h4>
                <p>Coach Application Denial</p>
                <p>
                  You are about to deny {coach.firstname} {coach.lastname}'s
                  Application to join the Nabantu Coach team.
                </p>
                <p>Provide a reason for the ineligibility below.</p>

                <form className='mt-1'>
                  <div className='form-floating mb-3'>
                    <select
                      className='form-control'
                      id='floatingInput'
                      value={reason}
                      onChange={(val) => setReason(val.target.value)}
                    >
                      <option value=''></option>
                      <option value='Lack of qualification'>
                        Lack of qualification
                      </option>
                      <option value='Other reason 1'>Other reason 1</option>
                      <option value='Other reason 2'>Other reason 2</option>
                    </select>
                    <label>Reason</label>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-darkblue'
              data-dismiss='modal'
              onClick={onOK}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoachDenialModal;
