import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { RootState } from '../../../redux/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CoachProgramme, Flex, User } from '../../../interfaces';
import { hideLoader, showLoader } from '../../../redux/ui/uiSlice';
import { flexGetAll, flexGetOne, flexGetUserByEmail } from '../../../api';
import { Transaction } from '../../../interfaces/transaction';
import moment from 'moment';

function CoacheePastCoaching() {
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const isLoaded = useRef(false);
  const [user, setUser] = useState<User>();
  const [coacheeProgramme, setCoacheeProgramme] = useState<CoachProgramme>();
  const [transaction, setTransaction] = useState<Transaction>();
  const [allTransactions, setAllTransactions] = useState<
    Array<Transaction> | undefined
  >();

  const loadAll = useCallback(() => {
    dispatch(showLoader());
    flexGetUserByEmail(sessionState.selectedCoachee?.email ?? '')
      .then((flexUserResponse) => {
        if (
          flexUserResponse.status === 200 &&
          flexUserResponse.data?.length > 0
        ) {
          const user: User = flexUserResponse.data[0];

          setUser(user);

          const flexTransactions: Flex = {
            collection: 'transactions',
            query: {
              userid: user?._id ?? '',
            },
          };
          flexGetAll(flexTransactions)
            .then((flexTransactionsResponse) => {
              if (
                flexTransactionsResponse.status === 200 &&
                flexTransactionsResponse.data?.length > 0
              ) {
                const transaction: Transaction =
                  flexTransactionsResponse.data[0];
                setTransaction(transaction);
                setAllTransactions(flexTransactionsResponse.data);

                flexGetOne(
                  'coaching-programme',
                  transaction.coachProgrammeid ?? ''
                )
                  .then((flexCoacheeProgrammeResponse) => {
                    if (flexCoacheeProgrammeResponse.status === 200) {
                      setCoacheeProgramme(flexCoacheeProgrammeResponse.data);
                      dispatch(hideLoader());
                    } else {
                      dispatch(hideLoader());
                    }
                  })
                  .catch(() => {
                    dispatch(hideLoader());
                  });
              } else {
                dispatch(hideLoader());
              }
            })
            .catch(() => {
              dispatch(hideLoader());
            });
        } else {
          dispatch(hideLoader());
        }
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  }, [dispatch, sessionState]);

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      loadAll();
    }
  }, [loadAll]);

  const getCompletedPercentage = useCallback(() => {
    if (transaction && coacheeProgramme) {
      return (
        ((transaction.coachingSessions?.filter(
          (item) =>
            item.chemistry1 === false &&
            item.chemistry2 === false &&
            item.coachConfirmedCompleted &&
            item.coacheeConfirmedCompleted
        )?.length ?? 0) /
          (coacheeProgramme?.numberOfSessions ?? 0)) *
        100
      );
    } else {
      return 0;
    }
  }, [transaction, coacheeProgramme]);

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row align-items-center border-bottom'>
              <h1>Coachee Profile</h1>
            </div>

            <div className='row p-3 border-bottom align-items-center'>
              <div className='col-2'>
                <div className='rnd-cnr p-3 bg-medblue text-center text-white'>
                  <div className='image-avatar bordered border-white bg-medblue mb-2'>
                    <img src='./images/avatar.png' alt='' />
                  </div>
                  <h5 className='mb-1'>
                    {user?.firstname} {user?.lastname}
                  </h5>
                  <div className='small darkblue'>
                    {getCompletedPercentage()}% completed
                  </div>
                  &nbsp;
                  <br />
                  &nbsp;
                  <br />
                  {/* <a className="btn btn-sml btn-transparent light-italic p-0 mt-3 text-white">
                    <i className="fa fa-plus-circle me-1"></i> remove coachee
                  </a> */}
                </div>
              </div>
              <div className='col'>
                <h5 className='mb-0 medblue'>Department</h5>
                <div className='mb-2'>{coacheeProgramme?.department}</div>
                <h5 className='mb-0 medblue'>Position</h5>
                <div className='mb-2'>{user?.position}</div>
                <h5 className='mb-0 medblue'>Line Manager</h5>
                <div className='mb-2'>{coacheeProgramme?.departmentHead}</div>
                <h5 className='mb-0 medblue'>Completed ASsessments</h5>
                <div className='mb-2'>Insights</div>
              </div>
            </div>

            <div className='row p-3'>
              <h4 className='medblue'>Past Coaching</h4>
              <div className='col'>
                <table className='table align-middle'>
                  <thead className='text-center'>
                    <tr>
                      <th scope='col'>Coaching type</th>
                      <th scope='col'>Date Completed</th>
                      <th scope='col'>Selected coach</th>
                      <th scope='col'>Number of sessions</th>
                      {/* <th scope="col">Three ways</th> */}
                      <th scope='col'>Late cancellations</th>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    {allTransactions?.map((transaction) => {
                      return (
                        <tr className='tbl-tr'>
                          <td>{transaction.coachingType}</td>
                          <td>
                            {transaction.allCompletedDate
                              ? moment(
                                  new Date(transaction.allCompletedDate ?? '')
                                ).format('YYYY-MM-Dd')
                              : ''}
                          </td>
                          <td></td>
                          <td>{transaction?.numberOfSessions}</td>
                          {/* <td>3/3</td> */}
                          <td>0</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoacheePastCoaching;
