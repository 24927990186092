import React, { useState, useCallback, useEffect } from 'react';
import { CoachProgrammeDisplay, Flex, Org, User } from '../../../interfaces';
import { AppScreens, Modals, UserType } from '../../../enums';
import Comp from '../../../components';
import {
  showLoader,
  hideLoader,
  setModal,
  setModalData,
  setScreen,
} from '../../../redux/ui/uiSlice';
import { useDispatch } from 'react-redux';
import { flexGetAll, flexGetOne } from '../../../api';
import {
  setSelectedOrg,
  setSelectedUser,
} from '../../../redux/session/sessionSlice';

function Dashboard() {
  const [viewAllCoaches, setViewAllCoaches] = useState(false);
  const [viewRecentClients, setViewRecentClients] = useState(false);
  const [viewAllOrgRequests, setViewAllOrgRequests] = useState(false);
  const [orgRequests, setOrgRequests] = useState<
    Array<CoachProgrammeDisplay> | undefined
  >(undefined);
  const [recentOrgs, setRecentOrgs] = useState<
    Array<CoachProgrammeDisplay> | undefined
  >(undefined);
  const [coaches, setCoaches] = useState<Array<User> | undefined>(undefined);
  const dispatch = useDispatch();

  const onViewAllCoaches = useCallback(() => {
    setViewAllCoaches(!viewAllCoaches);
  }, [viewAllCoaches]);

  const onViewAllClients = useCallback(() => {
    setViewRecentClients(!viewRecentClients);
  }, [viewRecentClients]);

  const onViewAllOrgRequests = useCallback(() => {
    setViewAllOrgRequests(!viewAllOrgRequests);
  }, [viewAllOrgRequests]);

  const loadRequests = useCallback(() => {
    dispatch(showLoader());
    const flex: Flex = {
      collection: 'coaching-programme',
      query: {
        accepted: false,
      },
    };
    flexGetAll(flex)
      .then((coachProgrammeResponse) => {
        dispatch(hideLoader());

        const tmpCoachProgrammeList: Array<CoachProgrammeDisplay> = [];

        if (coachProgrammeResponse.data) {
          coachProgrammeResponse.data.map((record: any) => {
            // recentOrgs?.find((item) => item._id === record.orgid);

            flexGetOne('org', record.orgid).then((orgData) => {
              tmpCoachProgrammeList.push({
                ...record,
                organisation: orgData.data.organisation,
              });

              setOrgRequests(tmpCoachProgrammeList);

              // if (
              //   tmpCoachProgrammeList.length ===
              //   coachProgrammeResponse.data.length
              // ) {
              //   const coachProgrammeList: Array<CoachProgrammeDisplay> = [];

              //   tmpCoachProgrammeList.map((coachProgramme) => {
              //     coachProgramme.coachees.map((coachee) => {
              //       coachProgrammeList.push({
              //         ...coachProgramme,
              //         coachee: coachee.firstname,
              //       });
              //     });
              //   });
              //   setOrgRequests(coachProgrammeList);
              // }
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [dispatch]);

  const loadCoaches = useCallback(() => {
    dispatch(showLoader());
    const flex: Flex = {
      collection: 'users',
      query: {
        usertype: UserType.Coach,
        accepted: false,
        rejected: false,
      },
    };
    flexGetAll(flex)
      .then((coachesResponse) => {
        dispatch(hideLoader());
        setCoaches(coachesResponse.data);
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [dispatch]);

  const loadRecent = useCallback(() => {
    dispatch(showLoader());
    const flex: Flex = {
      collection: 'coaching-programme',
      query: {
        accepted: true,
      },
    };
    flexGetAll(flex)
      .then((coachProgrammeResponse) => {
        dispatch(hideLoader());

        const tmpCoachProgrammeList: Array<CoachProgrammeDisplay> = [];

        if (coachProgrammeResponse.data) {
          coachProgrammeResponse.data.map((record: any) => {
            recentOrgs?.find((item) => item._id === record.orgid);

            flexGetOne('org', record.orgid).then((orgData) => {
              tmpCoachProgrammeList.push({
                ...record,
                organisation: orgData.data.organisation,
                org: orgData.data,
              });

              if (
                tmpCoachProgrammeList.length ===
                coachProgrammeResponse.data.length
              ) {
                const coachProgrammeList: Array<CoachProgrammeDisplay> = [];

                tmpCoachProgrammeList.map((coachProgramme) => {
                  coachProgramme.coachees.map((coachee) => {
                    if (
                      coachProgrammeList.filter(
                        (item) => item._id === coachProgramme._id
                      ).length === 0
                    ) {
                      coachProgrammeList.push({
                        ...coachProgramme,
                        coachee: coachee.firstname,
                      });
                    }
                  });
                });
                setRecentOrgs(coachProgrammeList);
              }
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [dispatch, recentOrgs]);

  const onAcceptRequest = useCallback(
    (item: CoachProgrammeDisplay) => {
      dispatch(setModalData(item));
      dispatch(setModal(Modals.CoachProgrammeSetup));
    },
    [dispatch]
  );

  const onViewCoachApplication = useCallback(
    (item: User) => {
      dispatch(setSelectedUser(item));
      dispatch(setScreen(AppScreens.CoachingApplication));
    },
    [dispatch]
  );

  const onDenyCoachApplication = useCallback(
    (item: User) => {
      dispatch(setModalData(item));
      dispatch(setModal(Modals.CoachDenial));
    },
    [dispatch]
  );

  const onAddOrganisation = useCallback(() => {
    dispatch(setModal(Modals.EditOrg));
  }, [dispatch]);

  const onClientProfile = useCallback(
    (org?: Org) => {
      if (org) {
        dispatch(setSelectedOrg(org));
        dispatch(setScreen(AppScreens.ClientProfile));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (orgRequests === undefined) {
      loadRequests();
    }
    if (coaches === undefined) {
      loadCoaches();
    }
    if (recentOrgs === undefined) {
      loadRecent();
    }
  }, [orgRequests, coaches, recentOrgs, loadRequests, loadCoaches, loadRecent]);

  return (
    <>
      <div className='container-fluid h-100 d-flex'>
        <div className='row flex-grow-1 bg-white'>
          <Comp.SideMenu></Comp.SideMenu>
          <div className='col page-content p-0'>
            <Comp.NavBar></Comp.NavBar>
            <div className='p-4'>
              {viewAllCoaches ? (
                <div className='row g-4 p-3'>
                  <div className='row gx-0 align-items-center'>
                    <div className='col page-heading heading-medblue py-4 ps-3 border-bottom'>
                      <div className='row g-0 align-items-center'>
                        <img
                          src='images/svg/coaching.svg'
                          className='heading-image'
                          alt=''
                        />
                        <div className='col'>
                          <h1 className='my-0'>Coach Applications</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  {coaches?.map((item, index) => (
                    <>
                      <div className='col-3' key={'coach' + index}>
                        <div className='n-card p-3 bg-medblue text-center'>
                          <img
                            src='./images/avatar.png'
                            className='profile-img'
                            alt=''
                          />
                          <div className='title mb-0'>
                            {item.firstname} {item.lastname}
                          </div>
                          <div className='subtitle mt-0 mb-3'>
                            {item.rate} Level
                          </div>
                          <button
                            type='button'
                            className='btn btn-sml btn-white'
                            onClick={() => onViewCoachApplication(item)}
                          >
                            View Application
                          </button>
                          <br />
                          &nbsp;
                          <br />
                          <button
                            type='button'
                            className='btn white-text'
                            onClick={() => onDenyCoachApplication(item)}
                          >
                            Deny Application
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className='text-center'>
                    <button
                      type='button'
                      className='btn btn-darkblue'
                      onClick={onViewAllCoaches}
                    >
                      View Less
                    </button>
                  </div>
                </div>
              ) : null}
              {viewRecentClients ? (
                <div className='row g-4 p-3'>
                  <div className='row gx-0 align-items-center'>
                    <div className='col page-heading heading-darkblue py-2 ps-3 border-bottom'>
                      <div className='row g-0 align-items-center'>
                        <img
                          src='images/svg/coaching.svg'
                          className='heading-image'
                        />
                        <div className='col'>
                          <h1 className='my-0'>Client List a</h1>
                          <div>
                            <strong>Organizations: </strong>
                            {recentOrgs?.length}
                          </div>
                        </div>
                        <div className='col-12 ps-5'>
                          <button
                            type='button'
                            onClick={() => onAddOrganisation()}
                            className='btn btn-sml btn-transparent light-italic p-0'
                          >
                            <i className='fa fa-plus-circle me-1'></i> Add an
                            Organization
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {recentOrgs?.map((item, index) => (
                    <>
                      <div className='col-3' key={'org' + index}>
                        <div className='n-card p-3 client'>
                          <div className='client-img-container'>
                            <img src='./images/placeholder.png' alt='' />
                          </div>
                          <div className='client-title large text-center'>
                            {item.organisation}
                          </div>
                          <div className='hrs val large text-center pb-3'>
                            {item.numberOfSessions}
                            <span className='hrs large'>hrs</span>
                          </div>
                          <div className='row align-items-center g-0'>
                            <div className='col-4 text-center'>
                              <img
                                src='images/svg/user-group.svg'
                                className='icon-brightblue'
                                alt=''
                              />
                            </div>
                            <div className='col'>
                              <div>Teams</div>
                              <div>
                                {
                                  item.org?.departments.filter(
                                    (dept) => dept.department !== undefined
                                  ).length
                                }
                              </div>
                            </div>
                          </div>
                          <hr className='m-1' />
                          <div className='row align-items-center g-0'>
                            <div className='col-4 text-center'>
                              <img
                                src='images/svg/coaching.svg'
                                className='icon-brightblue'
                                alt=''
                              />
                            </div>
                            <div className='col'>
                              <div>Coaches</div>
                              <div>{item.eligibleCoacheIds?.length}</div>
                            </div>
                          </div>
                          {/* <hr className="m-1" />
                          <div className="row align-items-center g-0">
                            <div className="col-4 text-center">
                              <img
                                src="images/svg/money.svg"
                                className="icon-brightblue"
                                alt=""
                              />
                            </div>
                            <div className="col">
                              <div>Total money spent</div>
                              <div>R 0</div>
                            </div>
                          </div> */}
                          <hr className='m-1' />
                          <div className='text-center pt-3'>
                            <button
                              type='button'
                              className='btn btn-sml btn-darkblue'
                              onClick={() => {
                                onClientProfile(item.org);
                              }}
                            >
                              Coaching Overview
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className='text-center'>
                    <button
                      type='button'
                      className='btn btn-darkblue'
                      onClick={onViewAllClients}
                    >
                      View Less
                    </button>
                  </div>
                </div>
              ) : null}
              {!viewAllCoaches && !viewRecentClients ? (
                <>
                  <div className='row mb-4'>
                    <div className='col'>
                      <div className='bg-fadedblue p-3 rnd-cnr'>
                        <h5 className='ms-4 mt-2'>Organization Request</h5>
                        <table className='table align-middle'>
                          <thead>
                            <tr>
                              <th scope='col'></th>
                              <th scope='col'>Organisation</th>
                              <th scope='col'># Coachees</th>
                              <th scope='col'>Request Type</th>
                              <th scope='col'>Team</th>
                              <th scope='col'>Coaching Type</th>
                              <th scope='col'>Session</th>
                              <th scope='col'></th>
                            </tr>
                          </thead>
                          <tbody>
                            {orgRequests?.map((item, index) => (
                              <>
                                {!viewAllOrgRequests && index < 3 ? (
                                  <tr
                                    className='tbl-tr'
                                    key={'orgrequest' + index}
                                  >
                                    <td>
                                      <div className='tbl-img'>
                                        <img
                                          src='./images/placeholder.png'
                                          alt=''
                                        />
                                      </div>
                                    </td>
                                    <td>{item.organisation}</td>
                                    <td>{item.coachees.length}</td>
                                    <td>
                                      {item.actionRequest === 'add'
                                        ? 'Coachee Addition'
                                        : 'NA'}
                                    </td>
                                    <td>{item.department}</td>
                                    <td>{item.coachingType}</td>
                                    <td>{item.numberOfSessions} hrs</td>
                                    <td className='text-end'>
                                      <button
                                        type='button'
                                        className='btn btn-darkblue'
                                        onClick={() => onAcceptRequest(item)}
                                      >
                                        Accept
                                      </button>
                                    </td>
                                  </tr>
                                ) : null}
                                {viewAllOrgRequests ? (
                                  <tr
                                    className='tbl-tr'
                                    key={'orgrequest' + index}
                                  >
                                    <td>
                                      <div className='tbl-img'>
                                        <img
                                          src='./images/placeholder.png'
                                          alt=''
                                        />
                                      </div>
                                    </td>
                                    <td>{item.coachee}</td>
                                    <td>
                                      {item.actionRequest === 'add'
                                        ? 'Coachee Addition'
                                        : 'NA'}
                                    </td>
                                    <td>{item.department}</td>
                                    <td>{item.coachingType}</td>
                                    <td>{item.numberOfSessions} hrs</td>
                                    <td className='text-end'>
                                      <button
                                        type='button'
                                        className='btn btn-darkblue'
                                        onClick={() => onAcceptRequest(item)}
                                      >
                                        Accept
                                      </button>
                                    </td>
                                  </tr>
                                ) : null}
                              </>
                            ))}
                          </tbody>
                        </table>
                        {orgRequests && orgRequests?.length > 0 ? (
                          <div className='text-center'>
                            <button
                              type='button'
                              className='btn btn-darkblue'
                              onClick={onViewAllOrgRequests}
                            >
                              {viewAllOrgRequests ? 'View Less' : 'View More'}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {!viewAllOrgRequests ? (
                    <div className='row gx-4'>
                      <div className='col'>
                        <div className='bg-fadedblue p-3 rnd-cnr'>
                          <h5 className='ms-4 mt-2'>Coach Applications</h5>
                          <div className='row g-4 p-3'>
                            {coaches?.map((item, index) => (
                              <>
                                {index < 3 ? (
                                  <div className='col-6' key={'coach' + index}>
                                    <div className='n-card p-3 bg-medblue text-center'>
                                      <img
                                        src='./images/avatar.png'
                                        className='profile-img'
                                        alt=''
                                      />
                                      <div className='title mb-0'>
                                        {item.firstname} {item.lastname}
                                      </div>
                                      <div className='subtitle mt-0 mb-3'>
                                        {item.rate} Level
                                      </div>
                                      <button
                                        type='button'
                                        className='btn btn-sml btn-white'
                                        onClick={() =>
                                          onViewCoachApplication(item)
                                        }
                                      >
                                        View Application
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ))}
                          </div>
                          {coaches && coaches?.length > 0 ? (
                            <div className='text-center'>
                              <button
                                type='button'
                                className='btn btn-white'
                                onClick={onViewAllCoaches}
                              >
                                View More
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col'>
                        <div className='bg-fadedblue p-3 rnd-cnr'>
                          <h5 className='ms-4 mt-2'>Recent Programmes</h5>
                          <div className='row g-4 p-3'>
                            {recentOrgs?.map((item, index) => (
                              <>
                                {index < 3 ? (
                                  <div className='col'>
                                    <div className='n-card p-3 bg-white text-center'>
                                      <div className='client-img-container'>
                                        <img
                                          src='./images/placeholder.png'
                                          alt=''
                                        />
                                      </div>
                                      <div className='client-title'>
                                        {item.organisation}
                                      </div>
                                      <div className='hrs large val'>
                                        {item.numberOfSessions}
                                        <span className='hrs large'>hrs</span>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ))}
                          </div>
                          {recentOrgs && recentOrgs?.length > 0 ? (
                            <div className='text-center'>
                              <button
                                type='button'
                                className='btn btn-darkblue'
                                onClick={onViewAllClients}
                              >
                                View More
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
