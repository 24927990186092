import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCoacheesForProgramme,
  getOrgProfile,
} from '../../../api/report.api';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { Flex, Mail, OrgProfile } from '../../../interfaces';
import { RootState } from '../../../redux/store';
import { hideLoader, showLoader } from '../../../redux/ui/uiSlice';
import { CoacheeTransactions } from '../../../interfaces/coachee-transactions';
import { TransactionDisplay } from '../../../interfaces/transaction';
import FileUploader from '../../../components/file-uploader';
import { Config } from '../../../config';
import { flexDelete, flexGetAll, flexInsert } from '../../../api/flex.api';
import { createNotification } from '../../../api/comms.api';
import { NotificationType } from '../../../enums';
import { sendMail } from '../../../api/comms.api';
import { read } from 'fs';
import { Library } from '../../../interfaces/library';

function ManageLibrary() {
  const dispatch = useDispatch();
  const [documentTitle, setDocumentTitle] = useState('');
  const [documents, setDocuments] = useState<Array<Library>>([]);
  const called = useRef(false);

  const reloadDocuments = useCallback(() => {
    dispatch(showLoader());
    const flex: Flex = {
      collection: 'library',
    };
    flexGetAll(flex)
      .then(results => {
        dispatch(hideLoader());
        setDocuments(results.data);
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  }, [dispatch]);

  const onDocumentUploaded = (section: string, fileName: string) => {
    if (documentTitle === '') {
      alert('Document title is required!');
      return;
    }
    const flex: Flex = {
      collection: 'library',
      model: {
        section: section,
        title: documentTitle,
        pages: 0,
        link:
          Config.API_SERVER.replace('/api', '/uploads/') + fileName + '.pdf',
      },
    };
    flexInsert(flex).then(() => {
      setDocumentTitle('');
      alert('Document uploaded successfully!');
      reloadDocuments();
    });
  };

  useEffect(() => {
    if (!called.current) {
      called.current = true;
      reloadDocuments();
    }
  }, [reloadDocuments]);

  const onDeleteDocument = useCallback(
    (document: Library) => {
      const flex: Flex = {
        collection: 'library',
        _id: document._id,
      };
      flexDelete(flex)
        .then(() => {
          dispatch(hideLoader());
          reloadDocuments();
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    },
    [reloadDocuments, dispatch],
  );

  return (
    <div className="container-fluid h-100 d-flex">
      <div className="row flex-grow-1 bg-white">
        <SideMenu />
        <div className="col page-content p-0">
          <NavBar />
          <div className="p-5">
            <div className="row gx-0 align-items-center">
              <div className="col page-heading heading-darkblue py-4 ps-3 border-bottom">
                <div className="row g-0 align-items-center">
                  {/* <img
                    src="images/svg/coaching.svg"
                    className="heading-image"
                  /> */}
                  <div className="col">
                    <h1 className="my-0">Manage Library</h1>
                  </div>
                </div>
              </div>
            </div>
            &nbsp; <br />
            <div className="col">
              <h4 className="my-0">Document Title</h4>
            </div>
            &nbsp; <br />
            <div className="row">
              <div className="col-4">
                <input
                  className="form-control me-2"
                  type="text"
                  placeholder="Document Title"
                  aria-label="Search"
                  value={documentTitle}
                  onChange={val => setDocumentTitle(val.target.value)}
                />
              </div>
            </div>
            &nbsp;
            <br />
            <div className="row">
              <div className="col-3">
                <FileUploader
                  label={'Upload to Reading Material'}
                  onFileUploaded={(fileName: string) => {
                    onDocumentUploaded('Reading Material', fileName);
                  }}></FileUploader>
              </div>
              <div className="col-3">
                <FileUploader
                  label={'Upload to Coaching Tools'}
                  onFileUploaded={(fileName: string) => {
                    onDocumentUploaded('Coaching Tools', fileName);
                  }}></FileUploader>
              </div>
            </div>
            <hr />
            <div className="row align-items-center">
              <div className="col-5">
                <table className="table align-middle">
                  <thead className="text-center">
                    <tr>
                      <th scope="col">Section</th>
                      <th scope="col">Document</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {documents.map(document => {
                      return (
                        <tr>
                          <td>{document.section}</td>
                          <td>{document.title}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sml btn-darkblue mt-2"
                              onClick={() => onDeleteDocument(document)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageLibrary;
