import { AppScreens } from '../../enums';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { sessionLogout } from '../../redux/session/sessionSlice';
import { setScreen } from '../../redux/ui/uiSlice';
import { flexGetAll } from '../../api';
import { Flex } from '../../interfaces';
import { Config } from '../../config';

function NavBar() {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<
    Array<Notification> | undefined
  >(undefined);

  const onLogout = useCallback(() => {
    dispatch(sessionLogout());
    dispatch(setScreen(AppScreens.Login));
  }, [dispatch]);

  const onNotifications = useCallback(() => {
    dispatch(setScreen(AppScreens.Notifications));
  }, [dispatch]);

  const loadNotifications = useCallback(() => {
    const flex: Flex = {
      collection: 'notifications',
      query: {
        receiverid: Config.ADMIN_USER,
        deletedAt: null,
      },
    };
    flexGetAll(flex)
      .then(NotificationsResponse => {
        setNotifications(NotificationsResponse.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (notifications === undefined) {
      loadNotifications();
    }
  }, [notifications, loadNotifications]);

  return (
    <nav className="navbar navbar-expand-sm sticky-top mt-4 ms-4 me-4">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
          <div className="col-2"></div>
          <div className="col-6">
            {/* <form className="d-flex">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
            </form> */}
          </div>
          <div className="col-2"></div>
          <div className="col-2 text-end">
            <a href="#" onClick={onNotifications}>
              <img
                src="./images/svg/bell.svg"
                className="navbar-icon icon-darkblue"
              />
              {notifications && notifications.length > 0 ? (
                <span className="badge">{notifications?.length}</span>
              ) : null}
            </a>
            {/* <img src="./images/profile_logo.png" className="navbar-profile" /> */}
            <a href="#" onClick={onLogout}>
              <img src="./images/logout.png" className="navbar-icon" alt="" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
