import { useState, useCallback } from 'react';
import { User } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader, setScreen } from '../../../redux/ui/uiSlice';
import { flexGetOne, login } from '../../../api';
import { sessionLogin, setOrg } from '../../../redux/session/sessionSlice';
import { AppScreens, UserType } from '../../../enums';

function Login() {
  const [user, setUser] = useState<User>({
    // email: 'admin@e.com',
    // password: 'password',
    email: '',
    password: '',
  });
  const dispatch = useDispatch();

  const onSignIn = useCallback(() => {
    if (!user.email) {
      alert('Please enter your email address.');
      return;
    } else if (!user.password) {
      alert('Please enter your password.');
      return;
    } else {
      dispatch(showLoader());
      login(user)
        .then((response) => {
          dispatch(hideLoader());
          if (response.status === 200) {
            if (
              response.data.user.usertype !== UserType.Admin &&
              response.data.user.usertype !== UserType.Company
            ) {
              alert(
                'Backend functionality is only available for Admins and Companies.'
              );
            } else {
              const user: User = response.data.user;
              if (user.orgid) {
                flexGetOne('org', user.orgid)
                  .then((orgData) => {
                    dispatch(sessionLogin(response.data.user));
                    dispatch(setOrg(orgData.data));
                    setUser(response.data.user);
                    if (response.data.user.usertype === UserType.Admin) {
                      dispatch(setScreen(AppScreens.Dashboard));
                    } else {
                      dispatch(setScreen(AppScreens.OrgDashboard));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(hideLoader());
                    if (err?.data && err.data?.message) {
                      alert(err.data.message);
                    } else {
                      alert('Unable to complete this operation.');
                    }
                  });
              } else {
                dispatch(sessionLogin(response.data.user));
                setUser(response.data.user);
                if (response.data.user.usertype === UserType.Admin) {
                  dispatch(setScreen(AppScreens.Dashboard));
                } else {
                  dispatch(setScreen(AppScreens.OrgDashboard));
                }
              }
            }
          } else {
            alert('This operation could not be completed at this time.');
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(hideLoader());
          if (err?.data && err.data?.message) {
            alert(err.data.message);
          } else {
            alert('Unable to complete this operation.');
          }
        });
    }
  }, [dispatch, user]);

  const onForgot = useCallback(() => {
    window.open('mailto:support@nabantu.co.za?subject=Forgot+Password');
  }, []);

  return (
    <div className='bg-image'>
      <div className='row h-100 w-100 gx-5 pe-5 align-content-center'>
        <div className='col-9'></div>
        <div className='col login-container'>
          <div className='login-logo mx-auto'>
            <img src='./images/logo.png' alt='' />
          </div>
          <form className='mt-5'>
            <div className='form-floating mb-3'>
              <input
                type='email'
                className='form-control'
                id='floatingInput'
                placeholder='name@example.com'
                value={user.email}
                onChange={(val) =>
                  setUser({ ...user, email: val.target.value })
                }
              />
              <label>Email</label>
            </div>
            <div className='form-floating'>
              <input
                type='password'
                className='form-control'
                id='floatingPassword'
                placeholder='Password'
                value={user.password}
                onChange={(val) =>
                  setUser({ ...user, password: val.target.value })
                }
              />
              <label>Password</label>
            </div>
            <div className='row mt-3 '>
              <div className='col'>
                <div className='grey'>
                  Forgot password?
                  <button
                    type='button'
                    onClick={onForgot}
                    className='btn btn-white brightblue px-2 d-inline'
                  >
                    Click here
                  </button>
                </div>
              </div>
            </div>
            <div className='d-grid mt-5'>
              <button
                type='button'
                className='btn btn-brightblue'
                onClick={onSignIn}
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
