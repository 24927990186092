import { useState, useCallback, useEffect, useRef } from 'react';
import {
  showLoader,
  hideLoader,
  setScreen,
  setModal,
} from '../../../redux/ui/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import SideMenu from '../../../components/side-menu';
import NavBar from '../../../components/nav-bar';
import {
  setSelectedProgramme,
  setSelectedReportProgramme,
} from '../../../redux/session/sessionSlice';
import { AppScreens, Modals } from '../../../enums';
import { getAllOrgProgrammes } from '../../../api/backend-report.api';
import { OrgProgrammeReport } from '../../../interfaces/backend.report';

function OrgDashboard() {
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();

  const [orgProgrammes, setOrgProgrammes] =
    useState<Array<OrgProgrammeReport>>();
  const initialised = useRef(false);

  const viewMore = useCallback(
    (coachingProgramme: OrgProgrammeReport) => {
      dispatch(setSelectedProgramme(coachingProgramme));
      dispatch(setSelectedReportProgramme(coachingProgramme));
      dispatch(setScreen(AppScreens.OrgReports));
    },
    [dispatch]
  );

  const getActiveCoaches = useCallback(() => {
    let total = 0;
    orgProgrammes?.map((programme) => {
      programme?.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            total += tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.bookingDate
            ).length;
          }
        });
      });
    });
    return total;
  }, [orgProgrammes]);

  const getCurrentMoneySpent = useCallback(() => {
    let total = 0;
    orgProgrammes?.map((programme) => {
      programme?.coachees.map((coachee) => {
        let totalSessions = 0;
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            totalSessions += tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.coachConfirmedCompleted &&
                coachingSession.coacheeConfirmedCompleted
            ).length;
          }
        });
        total += (programme.coachRate ?? 0) * totalSessions;
      });
    });
    return total;
  }, [orgProgrammes]);

  const getActiveCoachees = useCallback(() => {
    let total = 0;
    orgProgrammes?.map((programme) => {
      total += programme?.coachees.length;
    });
    return total;
  }, [orgProgrammes]);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      dispatch(showLoader());
      getAllOrgProgrammes(sessionState.selectedOrg?._id ?? '')
        .then((orgProfileResponse) => {
          dispatch(hideLoader());
          if (orgProfileResponse.status === 200) {
            setOrgProgrammes(orgProfileResponse.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert('Error: ' + JSON.stringify(err));
        });
    }
  }, [dispatch, sessionState]);

  const getHoursBooked = useCallback((programme: OrgProgrammeReport) => {
    let total = 0;

    programme?.coachees.map((coachee) => {
      coachee.transactions.map((tx) => {
        if (tx.coachingSessions) {
          total += tx.coachingSessions?.filter(
            (coachingSession) =>
              !coachingSession.chemistry1 &&
              !coachingSession.chemistry2 &&
              coachingSession.bookingDate
          ).length;
        }
      });
    });
    return total;
  }, []);

  const onAddTeam = useCallback(() => {
    dispatch(setModal(Modals.EditTeam));
  }, [dispatch]);

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row gx-0 align-items-center mb-4'>
              <div className='col page-heading heading-brightblue py-3 ps-3'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/user-group.svg'
                    className='heading-image medblue'
                    alt=''
                  />
                  <div className='col-7'>
                    <div className='fnt-small'>Active Teams</div>
                    <h2 className='my-0'>{orgProgrammes?.length}</h2>
                  </div>
                </div>
              </div>
              <div className='col page-heading heading-brightblue py-3 ps-3'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/user.svg'
                    className='heading-image'
                    alt=''
                  />
                  <div className='col-7'>
                    <div className='fnt-small'>Active Coachees</div>
                    <h2 className='my-0'>{getActiveCoachees()}</h2>
                  </div>
                </div>
              </div>
              <div className='col page-heading heading-brightblue py-3 ps-3'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/coaching.svg'
                    className='heading-image'
                    alt=''
                  />
                  <div className='col-7'>
                    <div className='fnt-small'>Active Coaches</div>
                    <h2 className='my-0'>{getActiveCoaches()}</h2>
                  </div>
                </div>
              </div>
              <div className='col page-heading heading-brightblue py-3 ps-3'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/money.svg'
                    className='heading-image'
                    alt=''
                  />
                  <div className='col-7'>
                    <div className='fnt-small'>Current Money Spent</div>
                    <h2 className='my-0'>R {getCurrentMoneySpent()}</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className='row g-4'>
              <div className='col-6'>
                <div className='p-4 bg-fadedblue rnd-cnr'>
                  <h5>Active Teams</h5>
                  <div className='row g-3 row-cols-md-3'>
                    {orgProgrammes?.map((programme) => (
                      <div className='col'>
                        <div className='n-card p-3 bg-medblue text-center text-white'>
                          <div className='icon-avatar centered bg-white mb-2'>
                            <img
                              src='./images/svg/user-group.svg'
                              className='icon-medblue'
                              alt=''
                            />
                          </div>
                          <div className='small mb-2'>
                            {programme.department}
                          </div>
                          <div className='hrs val'>
                            {getHoursBooked(programme)}
                            <span className='hrs'>hrs</span>
                          </div>
                          <div className='small'>Booked</div>
                          <a
                            onClick={() => viewMore(programme)}
                            href='#'
                            className='btn btn-sml btn-white mt-2'
                          >
                            View More
                          </a>
                        </div>
                      </div>
                    ))}
                    <div className='col'>
                      <div className='n-card bg-grey text-white text-center h-100'>
                        <div className='row h-100 align-content-center'>
                          <i className='fa fa-plus-circle fa-3x mb-2'></i>
                          <a onClick={() => onAddTeam()} href='#'>
                            <div
                              className='small'
                              style={{ color: 'white', textDecoration: 'none' }}
                            >
                              Add new Team
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='n-card p-3 bg-lightgrey rnd-cnr h-100 w-100 d-inline-block empty-card'></div>
                    </div>
                    <div className='col'>
                      <div className='n-card p-3 bg-lightgrey rnd-cnr h-100 w-100 d-inline-block empty-card'></div>
                    </div>
                    <div className='col'>
                      <div className='n-card p-3 bg-lightgrey rnd-cnr h-100 w-100 d-inline-block empty-card'></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-6'>
                <div className='row g-4'>
                  <div className='col-12'>
                    <div className='p-4 bg-fadedblue rnd-cnr'>
                      <h5>Recently completed</h5>
                      <div className='row g-3 row-cols-md-3'>
                        {/* <div className="col">
                          <div className="n-card p-3 bg-darkblue text-center text-white">
                            <div className="icon-avatar centered bg-white mb-2">
                              <img
                                src="./images/svg/user-group.svg"
                                className="icon-darkblue"
                              />
                            </div>
                            <div className="small mb-2">IT Team</div>
                            <div className="hrs val text-white">
                              300<span className="hrs text-white">hrs</span>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className='text-center'>
                        {/* <a href="" className="btn btn-sml btn-white mt-3">
                          View More
                        </a> */}
                        <p>No data</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="p-4 bg-fadedblue rnd-cnr">
                      <h5>Nabantu updates</h5>
                      <p>...</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgDashboard;
