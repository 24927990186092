import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgProfile } from '../../../api/report.api';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import {
  CoachProgramme,
  CoachProgrammeDisplay,
  Coachee,
  Flex,
  OrgProfile,
} from '../../../interfaces';
import { RootState } from '../../../redux/store';
import { hideLoader, showLoader } from '../../../redux/ui/uiSlice';
import moment from 'moment';
import {
  Transaction,
  TransactionDisplay,
} from '../../../interfaces/transaction';
import { flexGetAll } from '../../../api';

function OrgPastCoaching() {
  const sessionState = useSelector((state: RootState) => state.session);

  const [selectedProgramme, setSelectedProgramme] = useState<CoachProgramme>();
  const [orgProfile, setOrgProfile] = useState<OrgProfile | undefined>();
  const [transactions, setTransactions] = useState<
    Array<TransactionDisplay> | undefined
  >();
  const dispatch = useDispatch();
  const isLoaded = useRef(false);

  const getSelectedCoaches = useCallback(() => {
    let total = 0;
    sessionState.selectedReportProgramme?.coachees.map((coachee) => {
      coachee.transactions.map((tx) => {
        if (tx.coachingSessions) {
          total +=
            tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.bookingDate
            ).length > 0
              ? 1
              : 0;
        }
      });
    });
    return total;
  }, [sessionState.selectedReportProgramme]);

  const loadAll = useCallback(() => {
    dispatch(showLoader());

    const flexTransactions: Flex = {
      collection: 'transactions',
      query: {
        orgid: sessionState.org?._id,
      },
    };
    flexGetAll(flexTransactions).then((flexTransactionsResponse) => {
      if (flexTransactionsResponse.status === 200) {
        const newTransactions: Array<TransactionDisplay> = [];
        flexTransactionsResponse.data.map((transaction: TransactionDisplay) => {
          if (
            transaction.allCompletedDate !== undefined &&
            transaction.allCompletedDate !== null
          ) {
            newTransactions.push(transaction);
          }
        });

        // setTransactions(newTransactions);
        dispatch(hideLoader());
      }
    });
  }, [dispatch, sessionState]);

  const getCompletedHours = useCallback(
    (coachProgrammeid: string | undefined) => {
      if (orgProfile !== undefined) {
        let total = 0;
        orgProfile?.transactions?.map((transaction) => {
          if (transaction.coachingSessions) {
            // if (
            //   (coachProgrammeid ?? '') !== '' &&
            //   coachProgrammeid === transaction.coachProgrammeid
            // ) {
            total += transaction.coachingSessions?.filter(
              (item) =>
                item.chemistry1 === false &&
                item.chemistry2 === false &&
                item.coachConfirmedCompleted &&
                item.coacheeConfirmedCompleted
            )?.length;
            // } else if ((coachProgrammeid ?? '') === '') {
            //   total += transaction.coachingSessions?.filter(
            //     (item) =>
            //       item.chemistry1 === false &&
            //       item.chemistry2 === false &&
            //       item.coachConfirmedCompleted &&
            //       item.coacheeConfirmedCompleted
            //   )?.length;
            // }
          }
          return '';
        });
        return total;
      }
    },
    [orgProfile]
  );

  const getCompletedChemistryHours = useCallback(
    (coachProgrammeid: string | undefined) => {
      if (orgProfile !== undefined) {
        let total = 0;
        orgProfile?.transactions?.map((transaction) => {
          if (transaction.coachingSessions) {
            // if (
            //   (coachProgrammeid ?? '') !== '' &&
            //   coachProgrammeid === transaction.coachProgrammeid
            // ) {
            total += transaction.coachingSessions?.filter(
              (item) =>
                item.chemistry1 === true ||
                (item.chemistry2 === true &&
                  item.coachConfirmedCompleted &&
                  item.coacheeConfirmedCompleted)
            )?.length;
            // } else if ((coachProgrammeid ?? '') === '') {
            //   total += transaction.coachingSessions?.filter(
            //     (item) =>
            //       item.chemistry1 === false &&
            //       item.chemistry2 === false &&
            //       item.coachConfirmedCompleted &&
            //       item.coacheeConfirmedCompleted
            //   )?.length;
            // }
          }
        });
        return total;
      }
    },
    [orgProfile]
  );

  const getCoacheeSessionsCompleted = useCallback(
    (coachee: Coachee) => {
      let total = 0;
      transactions?.map((transaction) => {
        if (
          transaction?.user?.email?.toUpperCase().trim() ===
          coachee.email?.toUpperCase().trim()
        ) {
          total +=
            transaction.coachingSessions?.filter(
              (item) =>
                item.chemistry1 === false &&
                item.chemistry2 === false &&
                item.coachConfirmedCompleted &&
                item.coacheeConfirmedCompleted
            )?.length ?? 0;
        }
        // return '';
      });
      return total;
    },
    [transactions]
  );

  const getCoacheeCoach = useCallback(
    (coachee: Coachee) => {
      let coachName = '';
      transactions?.map((transaction) => {
        if (
          transaction?.user?.email?.toUpperCase().trim() ===
          coachee.email?.toUpperCase().trim()
        ) {
          coachName =
            transaction.coach?.firstname + ' ' + transaction.coach?.lastname;
        }
        return '';
      });
      return coachName;
    },
    [transactions]
  );

  useEffect(() => {
    if (orgProfile === undefined && sessionState.org?._id !== '') {
      dispatch(showLoader());
      getOrgProfile(sessionState.org?._id ?? '')
        .then((orgProfileResponse) => {
          dispatch(hideLoader());
          if (orgProfileResponse.status === 200) {
            // setOrgProfile(orgProfileResponse.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert('Error: ' + JSON.stringify(err));
        });
    }
  }, [orgProfile, dispatch, sessionState]);

  const viewProgramme = (coachingProgramme: CoachProgrammeDisplay) => {
    setSelectedProgramme(coachingProgramme);
  };

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      loadAll();
    }
  }, [loadAll]);

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row gx-0 align-items-center mb-4'>
              <div className='col page-heading heading-darkblue py-3 ps-3 border-bottom'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/coaching.svg'
                    className='heading-image'
                    alt=''
                  />
                  <div className='col'>
                    <h1 className='my-0'>Past Coaching</h1>
                  </div>
                </div>
              </div>
            </div>

            {selectedProgramme ? (
              <>
                <div className='row mt-3'>
                  <div className='col n-card p-3 bg-fadedblue'>
                    <div className='row align-items-center'>
                      <div className='col-3'>
                        <div className='n-card p-3 bg-darkblue text-center text-white'>
                          <div className='icon-avatar bg-white m-auto'>
                            <img
                              src='./images/svg/user-group.svg'
                              className='icon-darkblue'
                              alt=''
                            />
                          </div>
                          <div className='mt-2 mb-2'>
                            {selectedProgramme.department}
                          </div>
                          <div className='hrs val text-white'>
                            {selectedProgramme.numberOfSessions}
                            <span className='hrs text-white'> hrs</span>
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <table className='table tbl-no-border mb-0'>
                          <tbody>
                            <tr>
                              <td>Programme kick-off</td>
                              <td>
                                :
                                {selectedProgramme?.createdAt !== undefined
                                  ? moment(
                                      new Date(selectedProgramme?.createdAt)
                                    ).calendar()
                                  : ''}
                              </td>
                            </tr>
                            <tr>
                              <td>Programme completed on</td>
                              <td>: </td>
                            </tr>
                            <tr>
                              <td>Programme type</td>
                              <td>: {selectedProgramme.coachingType}</td>
                            </tr>
                            <tr>
                              <td>NDA signed</td>
                              <td>: N/A</td>
                            </tr>
                            <tr>
                              <td>Chemistry session completed</td>
                              <td>
                                :{' '}
                                {getCompletedChemistryHours(
                                  selectedProgramme._id
                                )}{' '}
                              </td>
                            </tr>
                            <tr>
                              <td>Selected coaches</td>
                              <td>: {getSelectedCoaches()}</td>
                            </tr>
                            <tr>
                              <td>Coaching Sessions completed</td>
                              <td>
                                : {getCompletedHours(selectedProgramme._id)}{' '}
                                hours
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='mb-2' />
                <h4 className='darkblue'>Coachees:</h4>
                <hr className='mt-2' />

                <table className='table align-middle'>
                  <thead className='text-center'>
                    <tr>
                      <th>Coachee</th>
                      <th>Completed sessions</th>
                      {/* <th>Three ways</th> */}
                      <th>Late cancellations</th>
                      <th>Selected coach</th>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    {selectedProgramme?.coachees?.map((coachee) => {
                      return (
                        <tr className='tbl-tr'>
                          <td className='text-start'>
                            <img
                              src='./images/avatar.png'
                              className='tbl-img d-inline mx-2'
                              alt=''
                            />
                            {coachee?.firstname} {coachee?.lastname}
                          </td>
                          <td>{getCoacheeSessionsCompleted(coachee)}</td>
                          {/* <td>2/3</td> */}
                          <td>0</td>
                          <td>{getCoacheeCoach(coachee)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                {orgProfile?.coachingProgrammes?.map((coachingProgramme) => {
                  return (
                    <div className='row mt-3'>
                      <div className='col n-card p-3 bg-fadedblue'>
                        <div className='row align-items-center'>
                          <div className='col-3'>
                            <div className='n-card p-3 bg-darkblue text-center text-white'>
                              <div className='icon-avatar bg-white m-auto'>
                                <img
                                  src='./images/svg/user-group.svg'
                                  className='icon-darkblue'
                                  alt=''
                                />
                              </div>
                              <div className='mt-2 mb-2'>
                                {coachingProgramme.department}
                              </div>
                              <div className='hrs val text-white'>
                                {coachingProgramme.numberOfSessions}
                                <span className='hrs text-white'> hrs</span>
                              </div>
                            </div>
                          </div>
                          <div className='col'>
                            <table className='table tbl-no-border mb-0'>
                              <tbody>
                                <tr>
                                  <td>Programme kick-off</td>
                                  <td>
                                    :
                                    {coachingProgramme?.createdAt !== undefined
                                      ? moment(
                                          new Date(coachingProgramme?.createdAt)
                                        ).calendar()
                                      : ''}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Programme completed on</td>
                                  <td>: </td>
                                </tr>
                                <tr>
                                  <td>Programme type</td>
                                  <td>: {coachingProgramme.coachingType}</td>
                                </tr>
                                <tr>
                                  <td>NDA signed</td>
                                  <td>: N/A</td>
                                </tr>
                                <tr>
                                  <td>Chemistry session completed</td>
                                  <td>
                                    :{' '}
                                    {getCompletedChemistryHours(
                                      coachingProgramme._id
                                    )}{' '}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Selected coaches</td>
                                  <td>: {getSelectedCoaches()}</td>
                                </tr>
                                <tr>
                                  <td>Coaching Sessions completed</td>
                                  <td>
                                    : {getCompletedHours(coachingProgramme._id)}{' '}
                                    hours
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='col-3'>
                        <div className='n-card bg-fadedblue text-white h-100'>
                          <div className='row h-100'>
                            <div className='col text-center align-self-center'>
                              <div>
                                <img
                                  src='images/svg/user-group.svg'
                                  className='icon-60 icon-white'
                                  alt=''
                                />
                              </div>
                              <a
                                onClick={() => viewProgramme(coachingProgramme)}
                                className='btn btn-sml btn-brightblue mt-4'
                              >
                                View Programme
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgPastCoaching;
