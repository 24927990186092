import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { OrgProfile, User } from '../../../interfaces';
import { RootState } from '../../../redux/store';
import { hideLoader, setScreen, showLoader } from '../../../redux/ui/uiSlice';
import {
  getCoacheesForProgramme,
  getOrgProfile,
} from '../../../api/report.api';
import moment from 'moment';
import { CoacheeTransactions } from '../../../interfaces/coachee-transactions';
import {
  Transaction,
  TransactionDisplay,
} from '../../../interfaces/transaction';
import { AppScreens, NotificationType } from '../../../enums';
import { createNotification } from '../../../api/comms.api';
import { Config } from '../../../config';
import {
  OrgProgrammeReport,
  UserForReport,
} from '../../../interfaces/backend.report';

function CoachingProgress() {
  const sessionState = useSelector((state: RootState) => state.session);
  // const [orgProfile, setOrgProfile] = useState<OrgProfile | undefined>();
  const dispatch = useDispatch();
  // const [coacheeTransactions, setCoacheeTransactions] = useState<
  //   Array<CoacheeTransactions> | undefined
  // >();

  const getSelectedCoach = useCallback(
    (coachee: UserForReport) => {
      const coaches = sessionState.selectedReportProgramme?.coaches?.filter(
        (coach) => coach._id?.trim() === coachee.selectedCoachId?.trim()
      );
      if (coaches && coaches?.length > 0) {
        return coaches[0];
      } else {
        return null;
      }
    },
    [sessionState.selectedReportProgramme]
  );

  const getSelectedCoaches = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      programme?.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            total +=
              tx.coachingSessions?.filter(
                (coachingSession) =>
                  !coachingSession.chemistry1 &&
                  !coachingSession.chemistry2 &&
                  coachingSession.bookingDate
              ).length > 0
                ? 1
                : 0;
          }
        });
      });
      return total;
    },
    []
  );

  const getCompletedChemistryHours = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      programme?.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            total += tx.coachingSessions?.filter(
              (coachingSession) =>
                (coachingSession.chemistry1 || coachingSession.chemistry2) &&
                coachingSession.coachConfirmedCompleted &&
                coachingSession.coacheeConfirmedCompleted
            ).length;
          }
        });
      });
      return total;
    },
    []
  );

  const getCompletedHours = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      programme?.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            total += tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.coachConfirmedCompleted &&
                coachingSession.coacheeConfirmedCompleted
            ).length;
          }
        });
      });
      return total;
    },
    []
  );

  const getCompletedChemistrySessions = (transactions: Array<Transaction>) => {
    let total = 0;
    transactions?.map((tx) => {
      total +=
        tx.coachingSessions?.filter(
          (item) =>
            item.chemistry1 === true ||
            (item.chemistry2 === true &&
              item.coachConfirmedCompleted &&
              item.coacheeConfirmedCompleted)
        )?.length ?? 0;
    });
    return total;
  };

  const getCompletedSessions = (transactions: Array<Transaction>) => {
    let total = 0;
    transactions?.map((tx) => {
      total +=
        tx.coachingSessions?.filter(
          (item) =>
            item.chemistry1 === false &&
            item.chemistry2 === false &&
            item.coachConfirmedCompleted &&
            item.coacheeConfirmedCompleted
        )?.length ?? 0;
    });
    return total;
  };

  const onAttendanceReport = () => {
    dispatch(setScreen(AppScreens.CoachingAttendanceReport));
  };

  const onMonthlyReport = () => {
    dispatch(setScreen(AppScreens.MonthlyReport));
  };

  const getAllocatedBudget = (coachee: UserForReport) => {
    let total = 0;

    coachee?.transactions?.map((transaction) => {
      const numberOfTransactions =
        transaction.coachingSessions?.filter(
          (item) => item.chemistry1 === false && item.chemistry2 === false
        )?.length ?? 0;
      total += numberOfTransactions * (transaction.totalAmount ?? 0);
    });
    return total;
  };

  const getBudgetSpent = (coachee: UserForReport) => {
    let total = 0;

    coachee?.transactions?.map((transaction) => {
      const numberOfTransactions =
        transaction.coachingSessions?.filter(
          (item) =>
            item.chemistry1 === false &&
            item.chemistry2 === false &&
            item.coachConfirmedCompleted &&
            item.coacheeConfirmedCompleted
        )?.length ?? 0;
      total += numberOfTransactions * (transaction.totalAmount ?? 0);
    });
    return total;
  };

  const requestReport = (coachee: CoacheeTransactions) => {
    if (coachee?.transactions && coachee?.transactions?.length > 0) {
      dispatch(showLoader());
      createNotification(
        Config.ADMIN_USER ?? '',
        coachee?.transactions[0].coach?._id ?? '',
        'Please send us a report for ' +
          coachee?.user?.firstname +
          ' ' +
          coachee?.user?.lastname +
          '.',
        NotificationType.RequestReport,
        undefined
      ).then(() => {
        dispatch(hideLoader());
        alert('Coach report requested!');
      });
    }
  };

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row gx-0 align-items-center'>
              <div className='col page-heading heading-brightblue py-2 ps-3 border-bottom'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/user-group.svg'
                    className='heading-image'
                  />
                  <div className='col'>
                    <h1 className='my-0'>
                      {sessionState?.selectedReportProgramme?.department}
                    </h1>
                    <div>&nbsp;</div>
                  </div>
                  <hr />
                  <div className='col-9 border-end border-lightblue'>
                    <table className='table tbl-no-border mb-0'>
                      <tbody>
                        <tr>
                          <td>Programme kick-off</td>
                          <td>
                            :
                            {sessionState.selectedReportProgramme?.createdAt !==
                            undefined
                              ? moment(
                                  new Date(
                                    sessionState.selectedReportProgramme?.createdAt
                                  )
                                ).calendar()
                              : ''}
                          </td>
                        </tr>
                        <tr>
                          <td>Programme completed on</td>
                          <td>: N/A</td>
                        </tr>
                        <tr>
                          <td>Programme type</td>
                          <td>
                            :{' '}
                            {sessionState.selectedReportProgramme?.coachingType}
                          </td>
                        </tr>
                        <tr>
                          <td>NDA signed</td>
                          <td>: N/A</td>
                        </tr>
                        <tr>
                          <td>Chemistry session completed</td>
                          <td>
                            :{' '}
                            {getCompletedChemistryHours(
                              sessionState.selectedReportProgramme ?? undefined
                            )}
                            {' hours'}
                          </td>
                        </tr>
                        <tr>
                          <td>Selected coaches</td>
                          <td>
                            :{' '}
                            {getSelectedCoaches(
                              sessionState.selectedReportProgramme ?? undefined
                            )}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Coaching Sessions completed</td>
                          <td>
                            :{' '}
                            {getCompletedHours(
                              sessionState.selectedReportProgramme ?? undefined
                            )}{' '}
                            hours
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='col-3 d-grid ps-3'>
                    <a
                      className='btn btn-sml btn-brightblue btn-block mb-4'
                      onClick={() => onMonthlyReport()}
                    >
                      View monthly reports
                    </a>
                    <a
                      className='btn btn-sml btn-brightblue btn-block'
                      onClick={() => onAttendanceReport()}
                    >
                      Attendance reports
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-4'>
              <div className='row text-center th'>
                <div className='col-3'>Coachee</div>
                <div className='col-1'>Allocated hours</div>
                <div className='col-4'>Progress</div>
                <div className='col-1'>Late cancellations</div>
                <div className='col-2'>Allocated Budget</div>
                {/* <div className="col-1">Remove</div> */}
              </div>
              {sessionState.selectedReportProgramme?.coachees?.map(
                (coachee) => {
                  return (
                    <>
                      {coachee.transactions?.map((transaction: Transaction) => {
                        return (
                          <div className='text-center tbl-tr py-3'>
                            <div className='row align-items-center'>
                              <div className='col-3 text-start d-table ps-4'>
                                <img
                                  src='./images/avatar.png'
                                  className='tbl-img d-table-cell'
                                />
                                <div className='d-table-cell align-middle ps-2'>
                                  {coachee.firstname + ' ' + coachee.lastname}
                                </div>
                              </div>
                              <div className='col-1'>
                                {getCompletedSessions(
                                  coachee?.transactions ?? []
                                )}{' '}
                                hrs
                              </div>
                              <div className='col-4'>
                                <div className='progress bg-fadedblue rounded-pill'>
                                  <div
                                    className='progress-bar bg-brightblue rounded-pill'
                                    role='progressbar'
                                    style={{
                                      width:
                                        (getCompletedSessions(
                                          coachee?.transactions ?? []
                                        ) /
                                          (sessionState.selectedReportProgramme
                                            ?.numberOfSessions ?? 0)) *
                                          100 +
                                        '%',
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className='col-1'>0</div>
                              <div className='col-2'>
                                R {getAllocatedBudget(coachee).toFixed(2)}
                              </div>
                              <div className='col-1'>
                                <a href=''>
                                  <i className='fa fa-minus-circle medblue'></i>
                                </a>
                              </div>
                            </div>
                            <hr className='mx-4' />
                            <div className='row mt-2 px-4 text-start'>
                              <div className='col'>
                                <table className='table tbl-no-border mb-0'>
                                  <tbody>
                                    <tr>
                                      <td>Chemistry Sessions completed</td>
                                      <td>
                                        :{' '}
                                        {getCompletedChemistrySessions(
                                          coachee?.transactions ?? []
                                        )}{' '}
                                        hrs
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Sessions completed</td>
                                      <td>
                                        :{' '}
                                        {getCompletedSessions(
                                          coachee?.transactions ?? []
                                        )}{' '}
                                        hrs
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Three ways</td>
                                      <td>:</td>
                                    </tr>
                                    <tr>
                                      <td>Outstanding reports</td>
                                      <td>: 0</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className='col align-self-center'>
                                <div className='row'>
                                  <div className='col-2 ms-4 text-end'>
                                    <img
                                      src='./images/avatar.png'
                                      className='selected-coach-img'
                                    />
                                  </div>
                                  <div className='col align-self-center'>
                                    <div>Selected Coach</div>
                                    <div className='fnt-wt-400'>
                                      {getSelectedCoach(coachee)?.firstname}{' '}
                                      {getSelectedCoach(coachee)?.lastname}
                                    </div>
                                  </div>
                                  <div className='col-4 text-end align-self-center'>
                                    <a
                                      className='btn btn-sml btn-brightblue btn-block'
                                      onClick={() =>
                                        alert('requestReport(coachee)')
                                      }
                                    >
                                      Request report
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className='mx-4' />
                            <div className='row mt-2 px-4 text-start'>
                              <div className='col-6'>
                                <table className='table tbl-no-border mb-0'>
                                  <tbody>
                                    <tr>
                                      <th className='fnt-large'>
                                        Allocated Budget
                                      </th>
                                      <th className='fnt-large'>
                                        Actual spend
                                      </th>
                                    </tr>
                                    <tr>
                                      <td className='fnt-large'>
                                        R{' '}
                                        {getAllocatedBudget(coachee).toFixed(2)}
                                      </td>
                                      <td className='fnt-large'>
                                        R {getBudgetSpent(coachee).toFixed(2)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <br />
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>Coaching programme rate</td>
                                      <td>
                                        : R{' '}
                                        {coachee?.transactions &&
                                        coachee?.transactions?.length > 0
                                          ? coachee?.transactions[0].totalAmount?.toFixed(
                                              2
                                            )
                                          : 0}{' '}
                                        /hr
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Coach rate</td>
                                      <td>
                                        : R{' '}
                                        {coachee?.transactions &&
                                        coachee?.transactions?.length > 0
                                          ? coachee?.transactions[0].coachPersonalAmount?.toFixed(
                                              2
                                            )
                                          : 0}{' '}
                                        /hr
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoachingProgress;
