import { CoachingType, Modals } from '../../enums';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { setModal, showMessage } from '../../redux/ui/uiSlice';
import { CoachProgramme, Mail } from '../../interfaces';
import { showLoader, hideLoader } from '../../redux/ui/uiSlice';
import { flexInsert } from '../../api';
import { Flex } from '../../interfaces';
import { RootState } from '../../redux/store';
import { Coachee } from '../../interfaces/coachee';
import { Config } from '../../config';
import { sendMail } from '../../api/comms.api';

function NewCoacheeRequestModal() {
  const sessionState = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch();
  const [numberOfSessions, setNumberOfSessions] = useState<
    string | undefined
  >();
  const [programmeName, setProgrammeName] = useState('');
  const [coachingType, setCoachingType] = useState<CoachingType | undefined>();
  const [invitees, setInvitees] = useState<Array<Coachee>>([{}]);

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  const onAddCoachee = useCallback(() => {
    setInvitees([...invitees, {}]);
  }, [invitees]);

  const onSetCoacheeName = useCallback(
    (index: number, newValue: string) => {
      let newArr = [...invitees];
      newArr[index].firstname = newValue;
      setInvitees(newArr);
    },
    [invitees],
  );

  const onSetCoacheeEmail = useCallback(
    (index: number, newValue: string) => {
      let newArr = [...invitees];
      newArr[index].email = newValue;
      setInvitees(newArr);
    },
    [invitees],
  );

  const onSave = useCallback(() => {
    if (programmeName! === '') {
      alert('Please enter the programme name.');
      return;
    } else if (!numberOfSessions) {
      alert('Please select the number of sessions.');
      return;
    } else if (!coachingType) {
      alert('Please select the type of coaching.');
      return;
    } else {
      dispatch(showLoader());

      const data: CoachProgramme = {
        programmeName: programmeName,
        numberOfSessions: Number(numberOfSessions),
        coachingType: coachingType,
        coachees: invitees,
        department: sessionState.selectedDepartment?.department ?? '',
        departmentHead: sessionState.selectedDepartment?.departmentHead ?? '',
        orgid: sessionState.org?._id ?? '',
        eligibleCoacheIds: undefined,
        actionRequest: 'add',
        accepted: false,
      };

      const flex: Flex = {
        collection: 'coaching-programme',
        model: data,
      };
      flexInsert(flex)
        .then(response => {
          dispatch(hideLoader());
          if (response.status === 200) {
            const mail: Mail = {
              to: [Config.APP_ADMIN],
              subject: 'Coaching Request',
              html:
                '<p>A new coaching request was received from ' +
                sessionState.org?.organisation,
            };

            sendMail(mail)
              .then(() => {})
              .catch(err => {
                console.log(err);
                dispatch(hideLoader());
                if (err?.data && err.data?.message) {
                  alert(err.data.message);
                } else {
                  alert('Unable to complete this operation.');
                }
              });
            dispatch(setModal(Modals.None));
            dispatch(
              showMessage({
                title: '',
                message:
                  'Your coachee request has been sent to the Nabantu team. We will be in contact with you.',
              }),
            );
          } else {
            alert('This operation could not be completed at this time.');
          }
        })
        .catch(err => {
          console.log(err);
          dispatch(hideLoader());
          if (err?.data && err.data?.message) {
            alert(err.data.message);
          } else {
            alert('Unable to complete this operation.');
          }
        });
    }
  }, [
    numberOfSessions,
    coachingType,
    dispatch,
    invitees,
    sessionState,
    programmeName,
  ]);

  return (
    <div
      className="modal modal-visible modal-backdrop2"
      id="editOrgModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">New Coachee Request</h4>
          </div>
          <div className="modal-body">
            <form className="mt-1">
              <div className="row">
                <div className="col-lg-6">
                  <div className="col page-heading heading-brightblue py-3 ps-3">
                    <div className="row g-0 align-items-center">
                      <img
                        src="images/svg/user-group.svg"
                        className="heading-image fadedblue"
                        alt=""
                      />
                      <div className="col-7">
                        <h1 className="my-0">
                          {sessionState.selectedDepartment?.department} Team
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder=""
                      value={programmeName}
                      onChange={val => setProgrammeName(val.target.value)}
                    />
                    <label>Programme Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select
                      className="form-control"
                      id="floatingInput"
                      value={numberOfSessions}
                      onChange={val => setNumberOfSessions(val.target.value)}>
                      <option value=""></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                    <label>Number of Sessions</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select
                      className="form-control"
                      id="floatingInput"
                      value={coachingType}
                      onChange={val =>
                        setCoachingType(val.target.value as CoachingType)
                      }>
                      <option value=""></option>
                      <option value=""></option>
                      <option value={CoachingType.GroupTeamCoaching}>
                        {CoachingType.GroupTeamCoaching}
                      </option>
                      <option value={CoachingType.StressResilience}>
                        {CoachingType.StressResilience}
                      </option>
                      <option value={CoachingType.ConflictCoaching}>
                        {CoachingType.ConflictCoaching}
                      </option>
                      <option value={CoachingType.EntrepreneurCoaching}>
                        {CoachingType.EntrepreneurCoaching}
                      </option>
                      <option value={CoachingType.SalesCoaching}>
                        {CoachingType.SalesCoaching}
                      </option>
                      <option value={CoachingType.SomaticBodyCoaching}>
                        {CoachingType.SomaticBodyCoaching}
                      </option>
                      <option value={CoachingType.OntologicalCoaching}>
                        {CoachingType.OntologicalCoaching}
                      </option>
                      <option value={CoachingType.LeadershipStrategy}>
                        {CoachingType.LeadershipStrategy}
                      </option>
                      <option value={CoachingType.LeadershipDevelopment}>
                        {CoachingType.LeadershipDevelopment}
                      </option>
                      <option value={CoachingType.ExecutiveCoaching}>
                        {CoachingType.ExecutiveCoaching}
                      </option>
                      <option value={CoachingType.LeadingChange}>
                        {CoachingType.LeadingChange}
                      </option>
                      <option value={CoachingType.LeaderAsCoach}>
                        {CoachingType.LeaderAsCoach}
                      </option>
                      <option value={CoachingType.LeadershipBrandReputation}>
                        {CoachingType.LeadershipBrandReputation}
                      </option>
                      <option value={CoachingType.LanguageForLeaders}>
                        {CoachingType.LanguageForLeaders}
                      </option>
                      <option value={CoachingType.PerceptionManagement}>
                        {CoachingType.PerceptionManagement}
                      </option>
                      <option
                        value={
                          CoachingType.EmotionalLearningForBetterLeadership
                        }>
                        {CoachingType.EmotionalLearningForBetterLeadership}
                      </option>
                    </select>
                    <label>Coaching Type</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p>Enter Coachees</p>
                  {invitees?.map((invitee, index) => {
                    return (
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput1"
                              placeholder=""
                              value={invitee.firstname}
                              onChange={val =>
                                onSetCoacheeName(index, val.target.value)
                              }
                            />
                            <label>Name</label>
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput2"
                              placeholder=""
                              value={invitee.email}
                              onChange={val =>
                                onSetCoacheeEmail(index, val.target.value)
                              }
                            />
                            <label>Email</label>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          {index === 0 ? (
                            <button
                              onClick={onAddCoachee}
                              type="button"
                              className="btn btn-darkblue">
                              +
                            </button>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-darkblue" onClick={onSave}>
              Send Request
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewCoacheeRequestModal;
