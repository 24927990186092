import { CoachProgramme } from './coach-programme';
import { ListItem } from './listitem';
import { Org } from './org';
import { Transaction } from './transaction';
import { User } from './user';

export interface OrgProfile {
  org?: Org;
  coachingProgrammes?: Array<CoachProgramme>;
  coachCount?: number;
  transactions?: Array<Transaction>;
}

export enum Themes {
  ThemeA = 'ThemeA',
  ThemeB = 'ThemeB',
  ThemeC = 'ThemeC',
  ThemeD = 'ThemeD',
}

export enum Objectives {
  ObjectiveA = 'ObjectiveA',
  ObjectiveB = 'ObjectiveB',
  ObjectiveC = 'ObjectiveC',
  ObjectiveD = 'ObjectiveD',
}

export interface Report {
  _id?: string;
  orgid: string;
  submittedOn?: Date;
  submitedByUserId?: string;
  transaction?: Transaction;
  sessionIndex?: number;
  org?: Org;
  user?: User;
  coach?: User;
  theme?: Themes;
  objective?: Objectives;
  notes?: string;
  include?: boolean;
}

export interface MonthlyReport {
  _id?: string;
  orgid: string;
  createdOn?: Date;
  reports?: Array<Report>;
  themes?: Array<ListItem>;
}
