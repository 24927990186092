import axios, { AxiosResponse } from 'axios';
import { Flex } from '../interfaces';
import { Config } from '../config';

export const flexInsert = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/flex`,
      data: flex,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};

export const flexUpdate = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'put',
      url: `${Config.API_SERVER}/flex`,
      data: flex,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};

export const flexDelete = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'delete',
      url: `${Config.API_SERVER}/flex/${flex.collection}/${flex._id}`,
      data: flex,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};

export const flexGetClosest = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/flex/get-closest`,
      data: flex,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};

export const flexGetOne = (collection: String, id: String) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url: `${Config.API_SERVER}/flex/get/` + collection + '/' + id,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};

export const flexGetAll = (flex: Flex) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/flex/get/` + flex.collection,
      data: flex,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};

export const flexGetUserByEmail = (email: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/flex/getUserByEmail`,
      data: {
        email: email,
      },
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};
