import NavBar from "../../../components/nav-bar";
import SideMenu from "../../../components/side-menu";

function MarketingTeam() {
  return (
    <div className="container-fluid h-100 d-flex">
      <div className="row flex-grow-1 bg-white">
        <SideMenu />
        <div className="col page-content p-0">
          <NavBar />
          <div className="p-5">
            <div className="row gx-0 align-items-center">
              <div className="col page-heading heading-medblue py-2 ps-3 border-bottom">
                <div className="row g-0 align-items-center">
                  <img
                    src="images/svg/user-group.svg"
                    className="heading-image"
                  />
                  <div className="col">
                    <h1 className="my-0">Marketing Team</h1>
                    <div className="row">
                      <div className="col-3">
                        <table className="table tbl-no-border mb-0">
                          <tbody>
                            <tr>
                              <td>Teams</td>
                              <td>: 4</td>
                            </tr>
                            <tr>
                              <td>Coachees</td>
                              <td>: 9</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ps-5">
                    <a className="btn btn-sml btn-transparent light-italic p-0">
                      <i className="fa fa-plus-circle me-1"></i> Add an coachee
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 g-3 row-cols-md-4">
              <div className="col">
                <div className="rnd-cnr p-3 bg-medblue text-center text-white">
                  <div className="icon-avatar centered bordered border-white bg-medblue mb-2">
                    <img
                      src="./images/svg/user-group.svg"
                      className="icon-white"
                    />
                  </div>
                  <div className="mb-1">Team 1</div>
                  <div className="small darkblue">Leadership coaching</div>
                  <div className="small darkblue">2 Coachees</div>
                  <div className="div">
                    <a href="" className="btn btn-sml btn-white mt-2">
                      View Coachees
                    </a>
                  </div>
                  <a className="btn btn-sml btn-transparent light-italic p-0 mt-3 text-white">
                    <i className="fa fa-plus-circle me-1"></i> remove team
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="rnd-cnr p-3 bg-medblue text-center text-white">
                  <div className="icon-avatar centered bordered border-white bg-medblue mb-2">
                    <img
                      src="./images/svg/user-group.svg"
                      className="icon-white"
                    />
                  </div>
                  <div className="mb-1">Team 2</div>
                  <div className="small darkblue">Leadership coaching</div>
                  <div className="small darkblue">2 Coachees</div>
                  <div className="div">
                    <a href="" className="btn btn-sml btn-white mt-2">
                      View Coachees
                    </a>
                  </div>
                  <a className="btn btn-sml btn-transparent light-italic p-0 mt-3 text-white">
                    <i className="fa fa-plus-circle me-1"></i> remove team
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="rnd-cnr p-3 bg-medblue text-center text-white">
                  <div className="image-avatar bordered border-white bg-medblue mb-2">
                    <img src="./images/avatar.png" />
                  </div>
                  <div className="mb-1">Team 3</div>
                  <div className="small darkblue">Leadership coaching</div>
                  <div className="small darkblue">2 Coachees</div>
                  <div className="div">
                    <a href="" className="btn btn-sml btn-white mt-2">
                      View Coachees
                    </a>
                  </div>
                  <a className="btn btn-sml btn-transparent light-italic p-0 mt-3 text-white">
                    <i className="fa fa-plus-circle me-1"></i> remove team
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="rnd-cnr p-3 bg-medblue text-center text-white">
                  <div className="icon-avatar centered bordered border-white bg-medblue mb-2">
                    <img
                      src="./images/svg/user-group.svg"
                      className="icon-white"
                    />
                  </div>
                  <div className="mb-1">Team 4</div>
                  <div className="small darkblue">Leadership coaching</div>
                  <div className="small darkblue">2 Coachees</div>
                  <div className="div">
                    <a href="" className="btn btn-sml btn-white mt-2">
                      View Coachees
                    </a>
                  </div>
                  <a className="btn btn-sml btn-transparent light-italic p-0 mt-3 text-white">
                    <i className="fa fa-plus-circle me-1"></i> remove team
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="n-card p-3 bg-lightgrey rnd-cnr h-100 w-100 d-inline-block empty-card"></div>
              </div>
              <div className="col">
                <div className="n-card p-3 bg-lightgrey rnd-cnr h-100 w-100 d-inline-block empty-card"></div>
              </div>
              <div className="col">
                <div className="n-card p-3 bg-lightgrey rnd-cnr h-100 w-100 d-inline-block empty-card"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketingTeam;
