import { hideMessage } from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from '../../redux/store';

function MessageModal() {
  const uiState = useSelector((state: RootState) => state.ui);

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(hideMessage());
  }, [dispatch]);

  return (
    <div
      className='modal modal-visible modal-backdrop2'
      id='exampleModal'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>{uiState.messageTitle}</h4>
          </div>
          <div className='modal-body'>
            <p>{uiState.messageDetails}</p>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-darkblue'
              data-dismiss='modal'
              onClick={onClose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageModal;
