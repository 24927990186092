/* eslint-disable */
import React from 'react';
import { uploadImage } from '../../api';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/ui/uiSlice';

interface FileUploaderProps {
  label: string;
  onFileUploaded: (fileName: string) => void;
}

const FileUploader = ({ label, onFileUploaded }: FileUploaderProps) => {
  const hiddenFileInput = React.useRef<any>(null);
  const dispatch = useDispatch();

  const handleClick = (event: any) => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: any) => {
    const selectedFile = event.target.files[0];

    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    dispatch(showLoader());

    uploadImage(formData)
      .then((uploadResponse: any) => {
        dispatch(hideLoader());
        if (uploadResponse.status === 200) {
          onFileUploaded(uploadResponse.data.filename);
        }
      })
      .catch(err => {
        dispatch(hideLoader());
        alert('Upload Error: ' + JSON.stringify(err));
      });

    // onSelectFile(fileUploaded);
  };
  return (
    <>
      <a onClick={handleClick} className="btn btn-darkblue">
        {label}
      </a>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
};
export default FileUploader;
