import { useSelector, useDispatch } from 'react-redux';
import Comp from '../../../components';
import { RootState } from '../../../redux/store';
import { useState, useEffect } from 'react';
import { Timesheet } from '../../../interfaces/timesheet';
import { hideLoader, showLoader } from '../../../redux/ui/uiSlice';
import { Flex } from '../../../interfaces';
import { flexGetAll } from '../../../api/flex.api';
import moment from 'moment';

function CoachTimeSheets() {
  const sessionState = useSelector((state: RootState) => state.session);
  const [timesheets, setTimesheets] = useState<Array<Timesheet> | undefined>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoader());
    const flex: Flex = {
      collection: 'timesheets',
      query: {
        coachid: sessionState.selectedCoach?._id ?? '',
      },
    };
    flexGetAll(flex)
      .then(results => {
        dispatch(hideLoader());
        if (results.status === 200) {
          setTimesheets(results.data);
        } else {
          setTimesheets([]);
        }
      })
      .catch(() => {
        setTimesheets([]);
        dispatch(hideLoader());
      });
  }, [dispatch, sessionState]);

  return (
    <>
      <div className="container-fluid h-100 d-flex bordered">
        <div className="row flex-grow-1 bg-white">
          <Comp.SideMenu></Comp.SideMenu>
          <div className="col page-content p-0">
            <Comp.NavBar></Comp.NavBar>
            <div className="p-5">
              <div className="row gx-0 align-items-center">
                <div className="col page-heading heading-darkblue py-2 ps-3 border-bottom">
                  <div className="row g-0 align-items-center">
                    <img
                      src="images/svg/billing.svg"
                      className="heading-image"
                      alt=""
                    />
                    <div className="col">
                      <h1 className="my-0">
                        {sessionState.selectedCoach?.firstname}{' '}
                        {sessionState.selectedCoach?.lastname}
                      </h1>
                      <div>Timesheets</div>
                    </div>
                  </div>
                </div>
              </div>

              <table className="table align-middle">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Timesheet No</th>
                    <th scope="col">Submitted On</th>
                    <th scope="col">Items</th>
                    <th scope="col">Amount</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {timesheets?.map(timesheet => {
                    return (
                      <tr className="tbl-tr">
                        <td className="text-start">{timesheet.timeSheetNo}</td>
                        <td className="text-start">
                          {moment(
                            timesheet.submittedOn ?? new Date(),
                          ).calendar()}
                        </td>
                        <td className="text-start">
                          {timesheet.items?.length}
                        </td>
                        <td className="text-start">
                          R {timesheet.totalAmount?.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoachTimeSheets;
