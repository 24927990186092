import axios, { AxiosResponse } from 'axios';
import { Config } from '../config';

export const getOrgProfile = (orgid: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url: `${Config.API_SERVER}/report/org-profile/` + orgid,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};

export const getCoacheesForProgramme = (programmeid: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url:
        `${Config.API_SERVER}/report/get-coachees-for-programme/` + programmeid,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};
