import { Modals, UserType } from '../../enums';

import { useCallback, useEffect, useState } from 'react';
import { setModal, showMessage } from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/ui/uiSlice';
import { flexUpdate, flexGetAll } from '../../api';
import { Flex, User, CoachProgramme, Mail, Coachee } from '../../interfaces';
import { RootState } from '../../redux/store';
import { sendMail } from '../../api/comms.api';

function CoachProgrammeCoachesModal() {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const coachProgrammeDisplay = uiState.modalData ?? {};

  const [allCoaches, setAllCoaches] = useState<Array<User> | undefined>(
    undefined,
  );
  const [selectedCoaches, setSelectedCoaches] = useState<Array<User>>([]);
  const [selectedCoach, setSelectedCoach] = useState<User>();

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  const onAccept = useCallback(() => {
    if (selectedCoaches.length === 0) {
      dispatch(
        showMessage({
          title: 'Sorry!',
          message: 'You have to select at least one eligable coach.',
        }),
      );
      return;
    }

    dispatch(showLoader());

    const eligableIds: Array<string> = [];
    selectedCoaches.map(coach => {
      eligableIds.push(coach._id ?? '');
    });

    const data: CoachProgramme = {
      ...coachProgrammeDisplay,
      eligibleCoacheIds: eligableIds,
      accepted: true,
    };

    const flex: Flex = {
      collection: 'coaching-programme',
      model: data,
      _id: coachProgrammeDisplay._id,
    };
    flexUpdate(flex)
      .then(response => {
        dispatch(hideLoader());
        if (response.status === 200) {
          // Mail all the coaches
          selectedCoaches.map(coach => {
            const mail: Mail = {
              to: [coach.email ?? ''],
              subject: "You've been invited to a coaching programme.",
              html:
                '<p>Dear Coach ' +
                coach.firstname +
                '</p>' +
                '<p>Your have been invited to participate in a coaching programme for ' +
                coachProgrammeDisplay.coachingType +
                ' at ' +
                coachProgrammeDisplay.organisation +
                '.</p><p>Regards,<br />Nabantu Team',
            };
            sendMail(mail)
              .then(() => { })
              .catch(err => {
                console.log(err);
                dispatch(hideLoader());
                if (err?.data && err.data?.message) {
                  alert(err.data.message);
                } else {
                  alert('Unable to complete this operation.');
                }
              });
          });

          // Mail all the coachees with the orgcode
          // ES NEW DISABLED
          // coachProgrammeDisplay.coachees.map((coachee: Coachee) => {
          //   const mail: Mail = {
          //     to: [coachee.email ?? ''],
          //     subject: "You've been invited to a coaching programme.",
          //     html:
          //       '<p>Dear ' +
          //       coachee.firstname +
          //       '</p>' +
          //       '<p>Your have been invited to participate in a coaching programme for ' +
          //       coachProgrammeDisplay.coachingType +
          //       ' at ' +
          //       coachProgrammeDisplay.organisation +
          //       '.<p>Your organisation code is: ' +
          //       coachProgrammeDisplay.orgCode +
          //       '.</p><p>Regards,<br />Nabantu Team',
          //   };
          //   sendMail(mail)
          //     .then(() => {})
          //     .catch((err) => {
          //       console.log(err);
          //       dispatch(hideLoader());
          //       if (err?.data && err.data?.message) {
          //         alert(err.data.message);
          //       } else {
          //         alert('Unable to complete this operation.');
          //       }
          //     });
          // });

          dispatch(setModal(Modals.None));
        } else {
          alert('This operation could not be completed at this time.');
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(hideLoader());
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [dispatch, coachProgrammeDisplay, selectedCoaches]);

  const onAddCoach = useCallback(() => {
    if (
      selectedCoach &&
      selectedCoaches.filter(coach => coach._id === selectedCoach._id)
        .length === 0
    ) {
      let newCoaches = [...selectedCoaches];
      newCoaches.push(selectedCoach);
      setSelectedCoaches(newCoaches);
    }
  }, [selectedCoaches, selectedCoach]);

  useEffect(() => {
    if (allCoaches === undefined) {
      dispatch(showLoader());
      const flex: Flex = {
        collection: 'users',
        query: {
          usertype: UserType.Coach,
        },
      };
      flexGetAll(flex)
        .then(coachesResponse => {
          dispatch(hideLoader());
          setAllCoaches(coachesResponse.data);
        })
        .catch(err => {
          console.log(err);
          dispatch(hideLoader());
          dispatch(
            showMessage({
              title: 'Sorry!',
              message: 'Operation could not be completed at this time.',
            }),
          );
        });
    }
  }, [allCoaches, dispatch]);

  const selectCoach = useCallback(
    (coachid: string) => {
      if (coachid === 'ALL') {
        setSelectedCoaches(allCoaches ?? []);
      } else {
        setSelectedCoach(allCoaches?.filter(coach => coach._id === coachid)[0]);
      }
    },
    [allCoaches],
  );

  const onAddAllCoaches = useCallback(
    () => {
      setSelectedCoaches(allCoaches ?? []);
    },
    [allCoaches],
  );


  return (
    <div
      className="modal modal-visible modal-backdrop2"
      id="coachProgrammeCoachesModal"
      role="dialog"
      aria-labelledby="coachProgrammeCoachesModal"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Coaching Programme Setup</h4>
            <button
              type="button"
              className="btn btn-darkblue close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-12 center">
                <p>
                  Coaching programme has been successfully set up and
                  participants have been emailed.
                </p>
                <h5>Programme Details</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Organisation</div>
              <div className="col-lg-3">
                : {coachProgrammeDisplay.organisation}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Department</div>
              <div className="col-lg-3">
                : {coachProgrammeDisplay.department}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Department Head</div>
              <div className="col-lg-3">: </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Coachess</div>
              <div className="col-lg-3">
                : {coachProgrammeDisplay.coachees?.length ?? 0}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Coaching Hours</div>
              <div className="col-lg-3">
                : {coachProgrammeDisplay.numberOfSessions ?? '0'} hrs
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Coaching Type</div>
              <div className="col-lg-3">
                : {coachProgrammeDisplay.coachingType ?? ''}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">Programme Price</div>
              <div className="col-lg-3">
                : R{' '}
                {Number(coachProgrammeDisplay.numberOfSessions ?? 0) *
                  Number(coachProgrammeDisplay.coachingRate ?? 0)}{' '}
                (R {coachProgrammeDisplay.coachingRate}/hr)
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h5 className="modal-sub-title">Coaches</h5>
              </div>
              <p>
                This is for coach matching purposes. Select the coaches you
                would to put forward for this programme.
              </p>
              <div className="col-lg-12">
                <p>All Coaches</p>
                <div className="row">
                  <div className="col-lg-4">
                    <select
                      className="form-control"
                      onChange={e => {
                        selectCoach(e.target.value);
                      }}>
                      <option value=""></option>
                      {allCoaches?.map(coach => (
                        <option value={coach._id}>
                          {coach.firstname} {coach.lastname}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-2">
                    <button
                      type="button"
                      className="btn btn-darkblue"
                      onClick={onAddCoach}>
                      Add
                    </button>
                  </div>
                  <div className="col-lg-3">
                    <button
                      type="button"
                      className="btn btn-darkblue"
                      onClick={onAddAllCoaches}>
                      Add All
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 detail-table">
                <div className="row">
                  <div className="col-lg-3">
                    <strong>Coach</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Level</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Accreditation</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Personal rate</strong>
                  </div>
                </div>
                {selectedCoaches?.map((coachee, index) => (
                  <div className="row">
                    <div className="col-lg-6">{coachee.firstname}</div>
                    <div className="col-lg-6">{coachee.email}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-darkblue"
              onClick={() => onAccept()}>
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoachProgrammeCoachesModal;
