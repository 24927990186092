import axios, { AxiosResponse } from 'axios';
import { Mail, Push } from '../interfaces';
import { Config } from '../config';

export const sendMail = (mail: Mail) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/comms/sendmail`,
      data: mail,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};

export const sendNotification = (push: Push) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/comms/sendnotification`,
      data: push,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};

export const createNotification = (
  senderid: string,
  receiverid: string,
  message: string,
  notificationtype: string,
  transactionid?: string,
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Config.API_SERVER}/comms/createnotification`,
      data: {
        senderid,
        receiverid,
        message,
        notificationtype,
        transactionid,
      },
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err.response));
  });
};
