import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppScreens, Modals } from '../../enums';

export type UI = {
  screen: AppScreens;
  modal: Modals;
  modalData: any;
  sideMenuOpen: boolean;
  sideMenu: boolean;
  loading: boolean;
  toolbarVisible: boolean;
  messageTitle: string;
  messageDetails: string;
  messageVisible: boolean;
};

export declare type MessageOptions = {
  title: string;
  message: string;
};

const initialState: UI = {
  screen: AppScreens.Login,
  modal: Modals.None,
  modalData: null,
  sideMenuOpen: false,
  sideMenu: false,
  loading: false,
  toolbarVisible: false,
  messageTitle: '',
  messageDetails: '',
  messageVisible: false,
};

const uiSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    showLoader: state => {
      state.loading = true;
    },
    hideLoader: state => {
      state.loading = false;
    },
    showSideMenuButton: state => {
      state.sideMenu = true;
    },
    hideSideMenuButton: state => {
      state.sideMenu = false;
    },
    showSideMenu: state => {
      state.sideMenuOpen = true;
    },
    hideSideMenu: state => {
      state.sideMenuOpen = false;
    },
    showToolbar: state => {
      state.toolbarVisible = true;
    },
    hideToolbar: state => {
      state.toolbarVisible = false;
    },
    showMessage: (state, action: PayloadAction<MessageOptions>) => {
      state.messageTitle = action.payload.title;
      state.messageDetails = action.payload.message;
      state.messageVisible = true;
    },
    hideMessage: state => {
      state.messageVisible = false;
    },
    setScreen: (state, action: PayloadAction<AppScreens>) => {
      state.screen = action.payload;
    },
    setModal: (state, action: PayloadAction<Modals>) => {
      state.modal = action.payload;
    },
    setModalData: (state, action: PayloadAction<any>) => {
      state.modalData = action.payload;
    },
  },
});

export const {
  showLoader,
  hideLoader,
  showSideMenuButton,
  hideSideMenuButton,
  showSideMenu,
  hideSideMenu,
  showToolbar,
  hideToolbar,
  showMessage,
  hideMessage,
  setScreen,
  setModal,
  setModalData,
} = uiSlice.actions;
export default uiSlice.reducer;
