import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { Flex, OrgProfile, Report, User } from '../../../interfaces';
import { RootState } from '../../../redux/store';
import {
  hideLoader,
  setModal,
  setModalData,
  showLoader,
} from '../../../redux/ui/uiSlice';
import { getOrgProfile } from '../../../api/report.api';
import moment from 'moment';
import { flexGetAll } from '../../../api/flex.api';
import { Modals } from '../../../enums';
import { UserForReport } from '../../../interfaces/backend.report';
import { CoachingSession } from '../../../interfaces/transaction';

function MonthlyReport() {
  const sessionState = useSelector((state: RootState) => state.session);
  const [orgProfile, setOrgProfile] = useState<OrgProfile | undefined>();
  const dispatch = useDispatch();
  const [reports, setReports] = useState<Array<Report> | undefined>();
  const [coachees, setCoachees] = useState<
    Array<User | undefined> | undefined
  >();

  const getTransactionFromSelectedProgramme = useCallback(
    (transactionid: string) => {
      let transaction: any;
      sessionState.selectedReportProgramme?.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx._id === transactionid) {
            transaction = tx;
          }
        });
      });
      return transaction;
    },
    [sessionState.selectedReportProgramme]
  );

  const getSelectedCoaches = useCallback(() => {
    let total = 0;
    sessionState.selectedReportProgramme?.coachees.map((coachee) => {
      coachee.transactions.map((tx) => {
        if (tx.coachingSessions) {
          total +=
            tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.bookingDate
            ).length > 0
              ? 1
              : 0;
        }
      });
    });
    return total;
  }, [sessionState.selectedReportProgramme]);

  const getCompletedChemistryHours = useCallback(() => {
    let total = 0;
    sessionState.selectedReportProgramme?.coachees.map(
      (coachee: UserForReport) => {
        coachee.transactions?.map((transaction) => {
          transaction.coachingSessions?.map((coachingSession) => {
            if (
              (coachingSession.chemistry1 || coachingSession.chemistry2) &&
              coachingSession.coachConfirmedCompleted &&
              coachingSession.coacheeConfirmedCompleted
            ) {
              total++;
            }
          });
        });
      }
    );
    return total;
  }, [sessionState.selectedReportProgramme]);

  const getCompletedHours = useCallback(() => {
    let total = 0;
    sessionState.selectedReportProgramme?.coachees.map(
      (coachee: UserForReport) => {
        coachee.transactions?.map((transaction) => {
          transaction.coachingSessions?.map((coachingSession) => {
            if (
              !coachingSession.chemistry1 &&
              !coachingSession.chemistry2 &&
              coachingSession.coachConfirmedCompleted &&
              coachingSession.coacheeConfirmedCompleted
            ) {
              total++;
            }
          });
        });
      }
    );
    return total;
  }, [sessionState.selectedReportProgramme]);

  useEffect(() => {
    if (orgProfile === undefined && sessionState.selectedOrg?._id !== '') {
      dispatch(showLoader());
      getOrgProfile(sessionState.selectedOrg?._id ?? '')
        .then((orgProfileResponse) => {
          dispatch(hideLoader());
          if (orgProfileResponse.status === 200) {
            setOrgProfile(orgProfileResponse.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert('Error: ' + JSON.stringify(err));
        });
    }
  }, [orgProfile, dispatch, sessionState]);

  useEffect(() => {
    if (reports === undefined) {
      dispatch(showLoader());

      const flex: Flex = {
        collection: 'reports',
        query: {},
      };
      flexGetAll(flex)
        .then((results) => {
          dispatch(hideLoader());
          setReports(results.data);

          const newUsers: Array<User> = [];
          results.data.map((report: Report) => {
            if (
              newUsers.filter((item) => item._id === report?.user?._id)
                .length === 0
            ) {
              if (report?.user) {
                newUsers.push(report?.user);
              }
            }
          });
          setCoachees(newUsers);
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [reports, dispatch, sessionState]);

  const getTotalSessions = (report: Report) => {
    return (
      getTransactionFromSelectedProgramme(
        report?.transaction?._id ?? ''
      ).coachingSessions?.filter(
        (item: CoachingSession) =>
          item.chemistry1 === false &&
          item.chemistry2 === false &&
          item.coachConfirmedCompleted &&
          item.coacheeConfirmedCompleted
      )?.length ?? 0
    );
  };

  const getTotalBudget = () => {
    let total = 0;
    reports?.map((report) => {
      total +=
        (report?.transaction?.numberOfSessions ?? 0) *
        (report?.transaction?.totalAmount ?? 0);
    });
    return total;
  };

  const getTotalSpent = (report: Report) => {
    let total = 0;
    const numberOfSessions = getTransactionFromSelectedProgramme(
      report?.transaction?._id ?? ''
    ).coachingSessions?.filter(
      (item: CoachingSession) =>
        item.chemistry1 === false &&
        item.chemistry2 === false &&
        item.coachConfirmedCompleted &&
        item.coacheeConfirmedCompleted
    )?.length;
    total =
      (numberOfSessions ?? 0) *
      (getTransactionFromSelectedProgramme(report?.transaction?._id ?? '')
        .totalAmount ?? 0);
    return total;
  };

  const getInvoicesSent = (report: Report) => {
    return getTransactionFromSelectedProgramme(
      report?.transaction?._id ?? ''
    ).coachingSessions?.filter(
      (item: CoachingSession) =>
        item.chemistry1 === false &&
        item.chemistry2 === false &&
        item.coachConfirmedCompleted &&
        item.coacheeConfirmedCompleted &&
        item.invoicedOn
    ).length;
  };

  const onEditReport = useCallback(
    (report: Report) => {
      dispatch(setModalData(report));
      dispatch(setModal(Modals.EditMonthlyReport));
    },
    [dispatch]
  );

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row gx-0 align-items-center'>
              <div className='col page-heading heading-brightblue py-2 ps-3 border-bottom'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/user-group.svg'
                    className='heading-image'
                  />
                  <div className='col'>
                    <h1 className='my-0'>
                      {sessionState?.selectedProgramme?.department}
                    </h1>
                    <div>&nbsp;</div>
                  </div>
                </div>
                <hr />
                <div className='row align-items-center'>
                  <div className='col-9'>
                    <table className='table tbl-no-border mb-0'>
                      <tbody>
                        <tr>
                          <td>Programme kick-off</td>
                          <td>
                            :
                            {sessionState.selectedProgramme?.createdAt !==
                            undefined
                              ? moment(
                                  new Date(
                                    sessionState.selectedProgramme?.createdAt
                                  )
                                ).calendar()
                              : ''}
                          </td>
                        </tr>
                        <tr>
                          <td>Programme completed on</td>
                          <td>: N/A</td>
                        </tr>
                        <tr>
                          <td>Programme type</td>
                          <td>
                            : {sessionState.selectedProgramme?.coachingType}
                          </td>
                        </tr>
                        <tr>
                          <td>NDA signed</td>
                          <td>: N/A</td>
                        </tr>
                        <tr>
                          <td>Chemistry session completed</td>
                          <td>
                            : {getCompletedChemistryHours()}
                            {' hours'}
                          </td>
                        </tr>
                        <tr>
                          <td>Selected coaches</td>
                          <td>: {getSelectedCoaches()}</td>
                        </tr>
                        <tr>
                          <td>Coaching Sessions completed</td>
                          <td>: {getCompletedHours()} hours</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-3'>
              {reports?.length === 0 ? (
                <>
                  <span>No reports submitted yet.</span>
                </>
              ) : null}
              {reports?.map((report: Report) => {
                const month = moment(
                  new Date(report?.submittedOn ?? '')
                ).format('MMMM');
                return (
                  <div
                    className='accordion accordion-flush'
                    id='monthAccordion'
                  >
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id={'heading-' + month}>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={'#collapse-' + month}
                          aria-expanded='false'
                          aria-controls='flush-collapseOne'
                        >
                          {month}
                        </button>
                      </h2>
                      <div
                        id={'collapse-' + month}
                        className='accordion-collapse collapse'
                        aria-labelledby={'heading-' + month}
                        data-bs-parent='#monthAccordion'
                      >
                        <div className='accordion-body'>
                          <div className='row px-2'>
                            <div className='col border-end border-lightblue'>
                              <table className='table tbl-alt no-border align-middle'>
                                <thead className='text-center'>
                                  <tr>
                                    <th>Coachees</th>
                                  </tr>
                                </thead>
                                <tbody className='text-center'>
                                  {coachees?.map((coachee) => {
                                    return (
                                      <tr className='tbl-tr'>
                                        <td className='text-start'>
                                          <img
                                            src='./images/avatar.png'
                                            className='tbl-img small d-inline mx-2'
                                          />
                                          {coachee?.firstname +
                                            ' ' +
                                            coachee?.lastname}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div className='col border-end border-lightblue px-3'>
                              <div className='row align-items-center mb-2'>
                                <div className='col-2'>
                                  <img
                                    src='./images/svg/reports.svg'
                                    className='icon-brightblue'
                                  />
                                </div>
                                <div className='col'>
                                  <h5 className='d-inline'>Monthly Report</h5>
                                </div>
                              </div>
                              <div className='mt-3'>Themes and Objectives</div>

                              {report?.objective}

                              <div className='fnt-wt-400 small'>Team Work</div>
                              <div className='mt-2'>
                                Sessions completed this month
                              </div>
                              <div className='fnt-wt-400 small'>
                                {getTotalSessions(report)} hr (with reports)
                              </div>
                              <div className='mt-2'>Additional Notes</div>
                              {report?.notes}
                            </div>
                            <div className='col px-3'>
                              <div className='row align-items-center mb-2'>
                                <div className='col-2'>
                                  <img
                                    src='./images/svg/ccard.svg'
                                    className='icon-brightblue'
                                  />
                                </div>
                                <div className='col'>
                                  <h5 className='d-inline'>Monthly Spent</h5>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col'>
                                  <div className='mt-3'>Project Budget</div>
                                  <div className='fnt-wt-400 small'>
                                    R {getTotalBudget().toFixed(2)}
                                  </div>
                                  <div className='mt-2'>Invoices Sent</div>
                                  <div className='fnt-wt-400 small'>
                                    {getInvoicesSent(report)}/
                                    {getTotalSessions(report)}
                                  </div>
                                </div>
                                <div className='col'>
                                  <div className='mt-3'>Month spend</div>
                                  <div className='fnt-wt-400 small'>
                                    R {getTotalSpent(report).toFixed(2)}
                                  </div>
                                  <div className='mt-2'>Invoices Paid</div>
                                  <div className='fnt-wt-400 small'>0</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col text-end align-self-end'>
                              <a
                                className='btn btn-sml btn-brightblue btn-block'
                                href='#'
                                onClick={() => onEditReport(report)}
                              >
                                Edit report
                              </a>
                            </div>
                            {/* <div className="col-2">
                          <a
                            className="btn btn-sml btn-brightblue btn-block"
                            href="#">
                            Publish report
                          </a>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthlyReport;
