import React, { useState, useCallback, useEffect } from 'react';
import {
  CoachProgramme,
  Coachee,
  CoacheePromo,
  Flex,
  Mail,
  Org,
  User,
} from '../../../interfaces';
import { AppScreens, Modals, UserType } from '../../../enums';
import Comp from '../../../components';
import {
  showLoader,
  hideLoader,
  setModal,
  setScreen,
} from '../../../redux/ui/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { flexGetAll, flexUpdate } from '../../../api';
import { RootState } from '../../../redux/store';
import { Transaction } from '../../../interfaces/transaction';
import { setSelectedCoachee } from '../../../redux/session/sessionSlice';
import { sendMail } from '../../../api/comms.api';
import { getProfilePicture } from '../../../utils';

function Coachees() {
  const sessionState = useSelector((state: RootState) => state.session);
  const [allCoachees, setAllCoachees] = useState<Array<User> | undefined>(
    undefined
  );
  const [coachees, setCoachees] = useState<Array<User> | undefined>(undefined);
  const [filter, setFilter] = useState<string>('');
  const dispatch = useDispatch();

  const getOrgName = useCallback((orgs: Array<Org>, orgid: string) => {
    const org = orgs.filter((item) => item._id === orgid);
    if (org && org.length > 0) {
      return org[0].organisation;
    } else {
      return '';
    }
  }, []);

  const getPastProgrammes = useCallback(
    (transactions: Array<Transaction>, userid: string) => {
      const tran = transactions.filter(
        (item) => item.userid === userid && item.allSessionsCompleted
      );
      if (tran && tran.length > 0) {
        return tran.length;
      } else {
        return 0;
      }
    },
    []
  );

  const wasCoacheePromo = useCallback(
    (transactions: Array<CoacheePromo>, email: string) => {
      const tran = transactions.filter((item) => item.email === email);
      if (tran && tran.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    []
  );

  const toggleCoachMatching = useCallback(
    (toggled: boolean, userid: string) => {
      const newUsers: Array<User> = [];
      coachees?.map((user) => {
        if (user._id === userid) {
          newUsers.push({ ...user, coachMatching: toggled });
        } else {
          newUsers.push(user);
        }
      });
      setCoachees(newUsers);
      setAllCoachees(newUsers);
      const flexUser: Flex = {
        collection: 'users',
        _id: userid,
        model: {
          coachMatching: toggled,
        },
      };
      flexUpdate(flexUser);
    },
    [coachees]
  );

  const loadCoaches = useCallback(() => {
    dispatch(showLoader());

    const flexCoacheePromo: Flex = {
      collection: 'coachee-promo',
    };
    flexGetAll(flexCoacheePromo)
      .then((allCoacheePromosResponse) => {
        const allCoacheePromos: Array<CoacheePromo> =
          allCoacheePromosResponse.data;

        const flexTransactions: Flex = {
          collection: 'org',
        };
        flexGetAll(flexTransactions)
          .then((allTransactionsResponse) => {
            const allTransactions: Array<Transaction> =
              allTransactionsResponse.data;

            const flexOrg: Flex = {
              collection: 'org',
            };
            flexGetAll(flexOrg)
              .then((allOrganisationsResponse) => {
                const allOrgs: Array<Org> = allOrganisationsResponse.data;
                const flexUsers: Flex = {
                  collection: 'users',
                  query: {
                    usertype: UserType.Coachee,
                  },
                };
                flexGetAll(flexUsers)
                  .then((allExistingUsersResponse) => {
                    const flexProgrammes: Flex = {
                      collection: 'coaching-programme',
                    };
                    flexGetAll(flexProgrammes)
                      .then((allCoachingProgrammesResponse) => {
                        dispatch(hideLoader());
                        const newUsers: Array<User> = [];

                        const coachingProgrammes: Array<CoachProgramme> =
                          allCoachingProgrammesResponse.data;
                        const existingUsers: Array<User> =
                          allExistingUsersResponse.data;

                        coachingProgrammes.map((coachingProgramme) => {
                          coachingProgramme.coachees?.map((coachee) => {
                            const isExistingUser = existingUsers.filter(
                              (user) =>
                                user.email?.trim().toLowerCase() ===
                                coachee?.email?.trim().toLowerCase()
                            );
                            if (isExistingUser && isExistingUser.length > 0) {
                              newUsers.push({
                                ...isExistingUser[0],
                                existingUser: true,
                                paymentType: 'Org Code',
                                orgName: getOrgName(
                                  allOrgs,
                                  coachingProgramme.orgid
                                ),
                                pastProgrammes: getPastProgrammes(
                                  allTransactions,
                                  isExistingUser[0]._id ?? ''
                                ),
                                plannedProgramme: coachingProgramme,
                                coachPromo: wasCoacheePromo(
                                  allCoacheePromos,
                                  coachee.email ?? ''
                                ),
                              });
                            } else {
                              newUsers.push({
                                ...coachee,
                                existingUser: false,
                                paymentType: 'Org Code',
                                coachMatching: false,
                                orgName: getOrgName(
                                  allOrgs,
                                  coachingProgramme.orgid
                                ),
                                coachPromo: wasCoacheePromo(
                                  allCoacheePromos,
                                  coachee.email ?? ''
                                ),
                              });
                            }
                          });
                        });

                        existingUsers.map((existingUser) => {
                          const inAllUsersList = newUsers.filter(
                            (newUser) =>
                              newUser.email?.trim().toLowerCase() ===
                              existingUser.email?.trim().toLowerCase()
                          );
                          if (!inAllUsersList || inAllUsersList.length === 0) {
                            newUsers.push({
                              ...existingUser,
                              existingUser: true,
                              paymentType: 'Self Payment',
                              orgName: 'Private',
                              pastProgrammes: getPastProgrammes(
                                allTransactions,
                                existingUser._id ?? ''
                              ),
                              coachPromo: wasCoacheePromo(
                                allCoacheePromos,
                                existingUser.email ?? ''
                              ),
                            });
                          }
                        });

                        setCoachees(newUsers);
                        setAllCoachees(newUsers);
                      })
                      .catch((err) => {
                        console.log(err);
                        dispatch(hideLoader());
                        if (err?.data && err.data?.message) {
                          alert(err.data.message);
                        } else {
                          alert('Unable to complete this operation.');
                        }
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(hideLoader());
                    if (err?.data && err.data?.message) {
                      alert(err.data.message);
                    } else {
                      alert('Unable to complete this operation.');
                    }
                  });
              })
              .catch((err) => {
                console.log(err);
                dispatch(hideLoader());
                if (err?.data && err.data?.message) {
                  alert(err.data.message);
                } else {
                  alert('Unable to complete this operation.');
                }
              });
          })
          .catch((err) => {
            console.log(err);
            dispatch(hideLoader());
            if (err?.data && err.data?.message) {
              alert(err.data.message);
            } else {
              alert('Unable to complete this operation.');
            }
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [dispatch, getOrgName, getPastProgrammes, wasCoacheePromo]);

  useEffect(() => {
    if (coachees === undefined) {
      loadCoaches();
    }
  }, [coachees, loadCoaches]);

  const viewCoacheeProfile = useCallback(
    (coachee: User) => {
      if ((coachee._id ?? '') !== '') {
        dispatch(setSelectedCoachee(coachee));
        dispatch(setScreen(AppScreens.CoacheePastCoaching));
      }
    },
    [dispatch]
  );

  const onInviteCoacheeClick = () => {
    dispatch(setModal(Modals.InviteCoacheeModal));
  };

  const onSendOrgCode = (coachee: User) => {
    if ((coachee?.plannedProgramme?.orgCode ?? '') !== '') {
      dispatch(showLoader());
      const mail: Mail = {
        to: [coachee.email ?? ''],
        subject: "You've been invited to a coaching programme.",
        html:
          '<p>Dear ' +
          coachee.firstname +
          '</p>' +
          '<p>Your have been invited to participate in a coaching programme for ' +
          coachee?.plannedProgramme?.programmeName +
          ' at ' +
          coachee?.orgName +
          '.<p>Your organisation code is: ' +
          coachee?.plannedProgramme?.orgCode +
          '.</p><p>Regards,<br />Nabantu Team',
      };
      sendMail(mail)
        .then(() => {
          dispatch(hideLoader());
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err?.data && err.data?.message) {
            alert(err.data.message);
          } else {
            alert('Unable to complete this operation.');
          }
        });
    } else {
      alert('Organisation request need to be approved first by admin.');
    }
  };

  const onSendNonOrgMessage = (coachee: User) => {
    if ((coachee?.plannedProgramme?.orgCode ?? '') !== '') {
      dispatch(showLoader());
      const mail: Mail = {
        to: [coachee.email ?? ''],
        subject: 'Coach Matching Complete',
        html:
          '<p>Dear ' +
          coachee.firstname +
          '</p>' +
          '<p>Welcome ......</p>' +
          '<p>Regards,<br />Nabantu Team',
      };
      sendMail(mail)
        .then(() => {
          dispatch(hideLoader());
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err?.data && err.data?.message) {
            alert(err.data.message);
          } else {
            alert('Unable to complete this operation.');
          }
        });
    } else {
      alert('Organisation request need to be approved first by admin.');
    }
  };

  useEffect(() => {
    const newCoachees = allCoachees?.filter((coachee) => {
      return (
        coachee.firstname?.toLowerCase().includes(filter.toLowerCase()) ||
        coachee.lastname?.toLowerCase().includes(filter.toLowerCase())
      );
    });
    setCoachees(newCoachees);
  }, [filter, allCoachees]);

  return (
    <>
      <div className='container-fluid h-100 d-flex bordered'>
        <div className='row flex-grow-1 bg-white'>
          <Comp.SideMenu></Comp.SideMenu>
          <div className='col page-content p-0'>
            <Comp.NavBar></Comp.NavBar>
            <div className='p-5'>
              <div className='row gx-0 align-items-center'>
                <div className='col page-heading heading-darkblue py-2 ps-3 border-bottom'>
                  <div className='row g-0 align-items-center'>
                    <img
                      src='images/svg/coaching.svg'
                      className='heading-image'
                      alt=''
                    />
                    <div className='col'>
                      <h1 className='my-0'>Nabantu Coachees</h1>
                      <div>Coachee Account List</div>
                    </div>
                    <div className='col-3 text-end'>
                      <a
                        onClick={onInviteCoacheeClick}
                        className='btn btn-brightblue btn-block'
                      >
                        Invite Coachee
                      </a>
                    </div>
                  </div>
                  <hr />
                  <div className='row align-items-center'>
                    <div className='col-5'>
                      <table className='table tbl-no-border mb-0'>
                        <tbody>
                          <tr>
                            <td width='130'>Org Coachees</td>
                            <td>:0</td>
                          </tr>
                          <tr>
                            <td>Coach Coachees</td>
                            <td>:0</td>
                          </tr>
                          <tr>
                            <td>Private Coachees</td>
                            <td>:0</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-4'>
                <div className='bg-fadedblue p-3 rnd-cnr'>
                  <div className='row align-items-center px-2'>
                    <div className='col'></div>
                    <div className='col'>
                      <input
                        className='form-control me-2'
                        type='search'
                        placeholder='Filter'
                        aria-label='Filter'
                        onChange={(e: any) => {
                          setFilter(e.target.value);
                        }}
                      />
                    </div>
                    {/* <div className="col-2">
                      <div className="dropdown">
                        <a
                          className="btn dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Filter
                        </a>

                        <ul
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              filter 1
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              filter 2
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              filter 3
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                  <table className='table align-middle'>
                    <thead className='text-center'>
                      <tr>
                        <th scope='col'>Coachee</th>
                        <th scope='col'>Organisation</th>
                        <th scope='col'>Payment Type</th>
                        <th scope='col'>Past Programmes</th>
                        <th scope='col'>Coach Matching</th>
                        <th scope='col'>Account Status</th>
                        <th scope='col'></th>
                      </tr>
                    </thead>
                    <tbody className='text-center'>
                      {coachees?.map((coachee) => {
                        return (
                          <tr className='tbl-tr'>
                            <td
                              className='text-start'
                              onClick={() => viewCoacheeProfile(coachee)}
                            >
                              <img
                                src={getProfilePicture(coachee)}
                                className='tbl-img d-inline mx-2'
                                alt=''
                              />
                              {coachee.firstname} {coachee.lastname}
                            </td>
                            <td>
                              {coachee.coachPromo
                                ? 'Coach Promo'
                                : coachee.orgName}
                            </td>
                            <td>{coachee.paymentType}</td>
                            <td>{coachee.pastProgrammes}</td>
                            <td>
                              {(coachee._id ?? '') !== '' ? (
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value='1'
                                  id='flexCheckDefault'
                                  checked={coachee.coachMatching ?? false}
                                  onChange={(val) =>
                                    toggleCoachMatching(
                                      val.target.checked,
                                      coachee._id ?? ''
                                    )
                                  }
                                />
                              ) : null}
                            </td>
                            <td>
                              {coachee.existingUser ? 'Active' : 'Inactive'}
                            </td>
                            <td className='text-end'>
                              {(coachee.orgName ?? '') !== 'Private' &&
                              coachee.existingUser ? (
                                <a
                                  onClick={() => onSendOrgCode(coachee)}
                                  className='btn bg-darkblue text-white'
                                  style={{ width: 200 }}
                                >
                                  Send Code
                                </a>
                              ) : (
                                <a
                                  onClick={() => onSendNonOrgMessage(coachee)}
                                  className='btn bg-medblue text-white'
                                  style={{ width: 200 }}
                                >
                                  Send Message
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Coachees;
