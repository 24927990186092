import Dashboard from '../admin/dashboard';
import ApplicationDetails from '../admin/application-details';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { AppScreens, Modals } from '../../enums';
import ModalScreens from '../../modals';
import Reports from '../admin/reports';
import Billing from '../admin/billing';
import Coaches from '../admin/coaches';
import Coachees from '../admin/coachees';
import ClientList from '../admin/client-list';
import ClientProfile from '../admin/client-profile';
import PastCoaching from '../admin/past-coaching';
import PastCoachingDetails from '../admin/past-coaching-details';
import CoachingProgress from '../admin/coaching-progress';
import CoachingAttendanceReport from '../admin/coaching-attendance-report';
import MonthlyReport from '../admin/monthly-report';
import BillingBreakdown from '../admin/billing-breakdown';
import CoachProfile from '../admin/coach-profile';
import OrgDashboard from '../org/dashboard';
import MarketingTeam from '../org/marketing-team';
import CoacheeProfile from '../org/coachee-profile';
import CoacheePastCoaching from '../org/coachee-past-coaching';
import OrgReports from '../org/org-reports';
import OrgMonthlyReport from '../org/org-monthly-report';
import OrgBilling from '../org/org-billing';
import OrgTeamBilling from '../org/org-team-billing';
import OrgPastCoaching from '../org/org-past-coaching';
import OrgPastTeamCoaching from '../org/org-past-team-coaching';
import Notifications from '../admin/notifications';
import Login from '../auth/login';
import CoachTimeSheets from '../admin/coach-timesheets';
import ManageLibrary from '../admin/library';

function AppContainer() {
  const uiState = useSelector((state: RootState) => state.ui);
  return (
    <>
      {uiState.screen === AppScreens.Login ? <Login></Login> : null}
      {uiState.screen === AppScreens.Dashboard ? <Dashboard></Dashboard> : null}
      {uiState.screen === AppScreens.ClientList ? (
        <ClientList></ClientList>
      ) : null}
      {uiState.screen === AppScreens.Notifications ? (
        <Notifications></Notifications>
      ) : null}
      {uiState.screen === AppScreens.CoachingApplication ? (
        <ApplicationDetails></ApplicationDetails>
      ) : null}
      {uiState.screen === AppScreens.Reports ? <Reports></Reports> : null}
      {uiState.screen === AppScreens.ClientProfile ? (
        <ClientProfile></ClientProfile>
      ) : null}
      {uiState.screen === AppScreens.PastCoaching ? (
        <PastCoaching></PastCoaching>
      ) : null}
      {uiState.screen === AppScreens.MonthlyReport ? (
        <MonthlyReport></MonthlyReport>
      ) : null}
      {uiState.screen === AppScreens.BillingBreakdown ? (
        <BillingBreakdown></BillingBreakdown>
      ) : null}
      {uiState.screen === AppScreens.PastCoachingDetails ? (
        <PastCoachingDetails></PastCoachingDetails>
      ) : null}
      {uiState.screen === AppScreens.CoachingProgress ? (
        <CoachingProgress></CoachingProgress>
      ) : null}
      {uiState.screen === AppScreens.CoacheePastCoaching ? (
        <CoacheePastCoaching></CoacheePastCoaching>
      ) : null}
      {uiState.screen === AppScreens.CoachingAttendanceReport ? (
        <CoachingAttendanceReport></CoachingAttendanceReport>
      ) : null}
      {uiState.screen === AppScreens.Billing ? <Billing></Billing> : null}
      {uiState.screen === AppScreens.CoacheeProfile ? (
        <CoacheeProfile></CoacheeProfile>
      ) : null}
      {uiState.screen === AppScreens.MarketingTeam ? (
        <MarketingTeam></MarketingTeam>
      ) : null}
      {uiState.screen === AppScreens.OrgDashboard ? (
        <OrgDashboard></OrgDashboard>
      ) : null}
      {uiState.screen === AppScreens.CoachProfile ? (
        <CoachProfile></CoachProfile>
      ) : null}
      {uiState.screen === AppScreens.Coaches ? <Coaches></Coaches> : null}
      {uiState.screen === AppScreens.Coachees ? <Coachees></Coachees> : null}
      {uiState.screen === AppScreens.OrgReports ? (
        <OrgReports></OrgReports>
      ) : null}
      {uiState.screen === AppScreens.OrgMonthlyReport ? (
        <OrgMonthlyReport></OrgMonthlyReport>
      ) : null}
      {uiState.screen === AppScreens.OrgBilling ? (
        <OrgBilling></OrgBilling>
      ) : null}
      {uiState.screen === AppScreens.OrgTeamBilling ? (
        <OrgTeamBilling></OrgTeamBilling>
      ) : null}
      {uiState.screen === AppScreens.CoachTimesheets ? (
        <CoachTimeSheets></CoachTimeSheets>
      ) : null}
      {uiState.screen === AppScreens.OrgPastCoaching ? (
        <OrgPastCoaching></OrgPastCoaching>
      ) : null}
      {uiState.screen === AppScreens.ManageLibrary ? (
        <ManageLibrary></ManageLibrary>
      ) : null}
      {uiState.screen === AppScreens.OrgPastTeamCoaching ? (
        <OrgPastTeamCoaching></OrgPastTeamCoaching>
      ) : null}
      {uiState.loading ? (
        <div className='progress-overlay'>
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'></div>
          </div>
        </div>
      ) : null}

      {uiState.modal === Modals.CoachSelectionNotificationModal ? (
        <ModalScreens.CoachSelectionNotificationModal />
      ) : null}
      {uiState.modal === Modals.CoachProgrammeSetup ? (
        <ModalScreens.CoachProgrammeSetupModal />
      ) : null}
      {uiState.modal === Modals.CoachProgrammeCoaches ? (
        <ModalScreens.CoachProgrammeCoachesModal />
      ) : null}
      {uiState.modal === Modals.EditOrg ? <ModalScreens.EditOrgModal /> : null}
      {uiState.modal === Modals.NewCoacheeRequest ? (
        <ModalScreens.NewCoacheeRequestModal />
      ) : null}
      {uiState.modal === Modals.CoachDenial ? (
        <ModalScreens.CoachDenialModal />
      ) : null}
      {uiState.modal === Modals.InviteCoachModal ? (
        <ModalScreens.InviteCoachModal />
      ) : null}
      {uiState.modal === Modals.AcceptCoach ? (
        <ModalScreens.AcceptCoachModal />
      ) : null}
      {uiState.modal === Modals.ReportViewer ? (
        <ModalScreens.ReportViewer />
      ) : null}
      {uiState.modal === Modals.EditMonthlyReport ? (
        <ModalScreens.EditMonthlyReport />
      ) : null}
      {uiState.modal === Modals.InviteCoacheeModal ? (
        <ModalScreens.InviteCoacheeModal />
      ) : null}
      {uiState.modal === Modals.EditTeam ? (
        <ModalScreens.EditTeamModal />
      ) : null}
      {uiState.messageVisible ? <ModalScreens.MessageModal /> : null}
    </>
  );
}

export default AppContainer;
