import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CoachProgramme, Flex } from '../../../interfaces';
import { RootState } from '../../../redux/store';
import { flexGetAll } from '../../../api';
import { hideLoader, setScreen, showLoader } from '../../../redux/ui/uiSlice';
import { Invoice } from '../../../interfaces/invoice';
import {
  setSelectedProgramme,
  setSelectedReportProgramme,
} from '../../../redux/session/sessionSlice';
import { AppScreens } from '../../../enums';
import { OrgProgrammeReport } from '../../../interfaces/backend.report';
import { getAllOrgProgrammes } from '../../../api/backend-report.api';

function OrgBilling() {
  const [orgProgrammes, setOrgProgrammes] =
    useState<Array<OrgProgrammeReport>>();
  const [invoices, setInvoices] = useState<Array<Invoice> | undefined>();
  const dispatch = useDispatch();
  const initialised = useRef(false);
  const sessionState = useSelector((state: RootState) => state.session);

  const getTotalMoneySpent = useCallback(() => {
    let total = 0;
    orgProgrammes?.map((programme) => {
      programme.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            const completed = tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.coachConfirmedCompleted &&
                coachingSession.coacheeConfirmedCompleted
            ).length;
            total += completed * (programme.coachRate ?? 0);
          }
        });
      });
    });
    return total;
  }, [orgProgrammes]);

  const getBudget = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      if (programme === undefined) {
        orgProgrammes?.map((programme) => {
          if (programme.coachees.length === 0) {
          } else {
            total +=
              (programme.coachRate ?? 0) *
              programme.coachees.length *
              programme.numberOfSessions;
          }
        });
      } else {
        total +=
          (programme.coachRate ?? 0) *
          programme.coachees.length *
          programme.numberOfSessions;
      }
      return total;
    },
    [orgProgrammes]
  );

  const getSpent = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      if (programme === undefined) {
        orgProgrammes?.map((programme) => {
          programme.coachees.map((coachee) => {
            coachee.transactions.map((tx) => {
              if (tx.coachingSessions) {
                const completed = tx.coachingSessions?.filter(
                  (coachingSession) =>
                    !coachingSession.chemistry1 &&
                    !coachingSession.chemistry2 &&
                    coachingSession.coachConfirmedCompleted &&
                    coachingSession.coacheeConfirmedCompleted
                ).length;
                total += completed * (programme.coachRate ?? 0);
              }
            });
          });
        });
      } else {
        programme.coachees.map((coachee) => {
          coachee.transactions.map((tx) => {
            if (tx.coachingSessions) {
              const completed = tx.coachingSessions?.filter(
                (coachingSession) =>
                  !coachingSession.chemistry1 &&
                  !coachingSession.chemistry2 &&
                  coachingSession.coachConfirmedCompleted &&
                  coachingSession.coacheeConfirmedCompleted
              ).length;
              total += completed * (programme.coachRate ?? 0);
            }
          });
        });
      }
      return total;
    },
    [orgProgrammes]
  );

  const getBalance = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      return getBudget(programme) - getSpent(programme);
    },
    [getBudget, getSpent]
  );

  const viewTeamBilling = useCallback(
    (coachingProgramme: OrgProgrammeReport) => {
      dispatch(setSelectedProgramme(coachingProgramme));
      dispatch(setSelectedReportProgramme(coachingProgramme));
      dispatch(setScreen(AppScreens.OrgTeamBilling));
    },
    [dispatch]
  );

  // useEffect(() => {
  //   if (!isLoaded.current) {
  //     isLoaded.current = true;
  //     loadAll();
  //   }
  // }, [loadAll]);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      dispatch(showLoader());
      getAllOrgProgrammes(sessionState.org?._id ?? '')
        .then((orgProfileResponse) => {
          dispatch(hideLoader());
          if (orgProfileResponse.status === 200) {
            setOrgProgrammes(orgProfileResponse.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert('Error: ' + JSON.stringify(err));
        });
    }
  }, [dispatch, sessionState]);

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row align-items-center border-bottom'>
              <h1>Cost Recon</h1>
            </div>

            <div className='mt-3'>
              <div className='row align-items-center'>
                <div className='col border-end'>
                  <h1>R 0</h1>
                  <a
                    className='btn btn-sml btn-brightblue btn-block disabled'
                    href=''
                  >
                    Request Invoice{' '}
                    <i className='fa fa-chevron-circle-right ms-2 lightblue'></i>
                  </a>
                </div>
                <div className='col'>
                  <div className='fnt-small'>Total Budget</div>
                  <div>R {getBudget(undefined).toFixed(2)}</div>
                  <div className='fnt-small mt-3'>Budget Spent</div>
                  <div>R {getSpent(undefined).toFixed(2)}</div>
                </div>
                <div className='col-6 text-center darkblue'>
                  <div className='budgetpercent'>
                    <div className='fnt-small fnt-wt-800'>BUDGET</div>
                    <h1 className='fnt-wt-800'>
                      {(getSpent(undefined) / getBudget(undefined)) * 100}%
                    </h1>
                  </div>
                </div>
              </div>

              <div className='row g-3 mt-4'>
                {orgProgrammes?.map((coachingProgramme) => {
                  return (
                    <div className='col-3'>
                      <div className='n-card p-3 bg-medblue text-white'>
                        <div className='d-flex flex-row align-items-center'>
                          <div className='icon-avatar bg-white mb-2'>
                            <img
                              src='./images/svg/user-group.svg'
                              className='icon-medblue'
                              alt=''
                            />
                          </div>
                          <div className='ps-3'>
                            <div className='small'>
                              {coachingProgramme.department}
                            </div>
                            <h2 className='mt-0'>
                              {coachingProgramme.coachees?.length}
                              <span className='fnt-small ms-1'>coachee(s)</span>
                            </h2>
                          </div>
                        </div>
                        <div className='fnt-small mt-3 black'>BUDGET</div>
                        <h1 className='black'>
                          R {getBudget(coachingProgramme)}
                        </h1>
                        <div className='row  black'>
                          <div className='col border-end'>
                            <div className='fnt-small'>Balance</div>
                            <div>R {getBalance(coachingProgramme)}</div>
                          </div>
                          <div className='col'>
                            <div className='fnt-small'>Spent</div>
                            <div className='text-white'>
                              R {getSpent(coachingProgramme)}
                            </div>
                          </div>
                        </div>
                        <div className='text-center'>
                          <a
                            href='#'
                            onClick={() => viewTeamBilling(coachingProgramme)}
                            className='btn btn-sml btn-white mt-4'
                          >
                            More info
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgBilling;
