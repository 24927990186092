import axios, { AxiosResponse } from "axios";
import { User } from "../interfaces";
import { Config } from "../config";

export const login = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: "post",
      url: `${Config.API_SERVER}/auth/login`,
      data: user,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const register = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: "post",
      url: `${Config.API_SERVER}/auth/register`,
      data: user,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const forgotFassword = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: "post",
      url: `${Config.API_SERVER}/auth/forgotpassword`,
      data: user,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const changePassword = (user: User) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: "post",
      url: `${Config.API_SERVER}/auth/changepassword`,
      data: user,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};
