import CoachProgrammeSetupModal from './coach-programme-setup';
import CoachProgrammeCoachesModal from './coach-programme-coaches';

import EditOrgModal from './edit-org';
import EditTeamModal from './edit-team';
import NewCoacheeRequestModal from './new-coachee-request';
import MessageModal from './message';
import CoachDenialModal from './coach-denial';
import CoachSelectionNotificationModal from './coach-selection-notification';
import InviteCoachModal from './invite-coach';
import AttendanceReportFilter from './attendance-report-filter';
import AcceptCoachModal from './accept-coach';
import ReportViewer from './report-viewer';
import EditMonthlyReport from './edit-monthly-report';
import InviteCoacheeModal from './invite-coachee';

export default {
  CoachProgrammeCoachesModal,
  CoachProgrammeSetupModal,
  EditOrgModal,
  NewCoacheeRequestModal,
  MessageModal,
  CoachDenialModal,
  CoachSelectionNotificationModal,
  InviteCoachModal,
  AttendanceReportFilter,
  AcceptCoachModal,
  ReportViewer,
  EditMonthlyReport,
  InviteCoacheeModal,
  EditTeamModal,
};
