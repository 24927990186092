import { useDispatch } from 'react-redux';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Timesheet } from '../../../interfaces/timesheet';
import {
  BillingLine,
  TransactionDisplay,
} from '../../../interfaces/transaction';
import {
  hideLoader,
  setModal,
  setModalData,
  showLoader,
} from '../../../redux/ui/uiSlice';
import { Flex } from '../../../interfaces';
import { flexGetAll, flexGetOne, flexInsert, flexUpdate } from '../../../api';
import { Invoice, InvoiceLineItem } from '../../../interfaces/invoice';
import { ReportViewerProps } from '../../../modals/report-viewer';
import { Modals } from '../../../enums';

function BillingBreakdown() {
  const [timesheets, setTimesheets] = useState<Array<Timesheet> | undefined>();
  const [newInvoices, setNewInvoices] = useState<Array<Invoice> | undefined>();
  const [existingInvoices, setExistingInvoices] = useState<
    Array<Invoice> | undefined
  >();
  const isLoaded = useRef(false);
  const dispatch = useDispatch();

  const loadAll = useCallback(() => {
    dispatch(showLoader());

    const existingInvoices: Flex = {
      collection: 'invoices',
    };
    flexGetAll(existingInvoices).then((existingInvoicesResponse) => {
      if (existingInvoicesResponse.status === 200) {
        setExistingInvoices(existingInvoicesResponse.data);

        const flexTimesheets: Flex = {
          collection: 'timesheets',
          query: {
            allInvoicedAt: undefined,
          },
        };

        let invoices: Array<Invoice> = [];

        flexGetAll(flexTimesheets).then((flexTimesheetsResponse) => {
          if (flexTimesheetsResponse.status === 200) {
            const flexTransactions: Flex = {
              collection: 'transactions',
            };
            setTimesheets(flexTimesheetsResponse.data);
            const timeSheets: Array<Timesheet> | undefined =
              flexTimesheetsResponse.data;
            flexGetAll(flexTransactions).then((flexTransactionsResponse) => {
              if (flexTransactionsResponse.status === 200) {
                dispatch(hideLoader());

                const transactions: Array<TransactionDisplay> | undefined =
                  flexTransactionsResponse.data;

                timeSheets?.map(
                  (timeSheet: Timesheet, timeSheetIndex: number) => {
                    timeSheet.items?.map(
                      (billingLine: BillingLine, lineNo: number) => {
                        const billingTransaction:
                          | TransactionDisplay
                          | null
                          | undefined =
                          transactions?.filter(
                            (txItem) =>
                              txItem._id === billingLine.transactionid &&
                              billingLine.invoicedAt === undefined
                          )!?.length > 0
                            ? transactions?.filter(
                                (txItem) =>
                                  txItem._id === billingLine.transactionid
                              )[0]
                            : null;

                        if (billingTransaction) {
                          const lineItem: InvoiceLineItem = {
                            timesheetid: billingTransaction?._id ?? '',
                            lineno: lineNo,
                            transaction: billingTransaction,
                            clientAmount:
                              (billingTransaction.totalAmount ?? 0) *
                              (billingTransaction.coachingSessions?.filter(
                                (item) =>
                                  item.chemistry1 === false &&
                                  item.chemistry2 === false &&
                                  item.coachConfirmedCompleted &&
                                  item.coacheeConfirmedCompleted
                              )?.length ?? 0),
                            coachAmount:
                              (billingTransaction?.coachAmount ?? 0) *
                              (billingTransaction.coachingSessions?.filter(
                                (item) =>
                                  item.chemistry1 === false &&
                                  item.chemistry2 === false &&
                                  item.coachConfirmedCompleted &&
                                  item.coacheeConfirmedCompleted
                              )?.length ?? 0),
                            coachid: billingTransaction?.coachid,
                            coachname:
                              billingTransaction?.coach.firstname +
                              ' ' +
                              billingTransaction?.coach.lastname,
                            coacheeid: billingTransaction?.userid,
                            coacheename:
                              billingTransaction?.user.firstname +
                              ' ' +
                              billingTransaction?.user.lastname,
                          };

                          const existingInvoices = invoices?.filter(
                            (invItem) =>
                              invItem.orgid === billingTransaction.orgid
                          );

                          flexGetOne(
                            'org',
                            billingTransaction.orgid ?? ''
                          ).then((orgResponse) => {
                            if (existingInvoices?.length === 0) {
                              const newInvoice: Invoice = {
                                createdAt: new Date(),
                                lineItems: [lineItem],
                                orgid: billingTransaction.orgid ?? '',
                                totalAmount: lineItem?.clientAmount,
                                orgName: orgResponse.data.organisation,
                              };
                              invoices.push(newInvoice);
                            } else {
                              const newInvoiceList: Array<Invoice> = [];
                              invoices.map((invItem) => {
                                if (
                                  invItem.orgid !== billingTransaction.orgid
                                ) {
                                  newInvoiceList.push(invItem);
                                } else {
                                  const newInvoice: Invoice = {
                                    ...invItem,
                                  };
                                  newInvoice.lineItems.push(lineItem);
                                  newInvoice.totalAmount =
                                    newInvoice.totalAmount +
                                    lineItem?.clientAmount;
                                  newInvoiceList.push(newInvoice);
                                }
                                return '';
                              });
                              invoices = [...newInvoiceList];
                            }

                            if (timeSheets?.length === timeSheetIndex + 1) {
                              setNewInvoices(invoices);
                            }
                          });
                        }

                        return '';
                      }
                    );

                    return '';
                  }
                );
              }
            });
          }
        });
      }
    });
  }, [dispatch]);

  const sendInvoice = useCallback(
    (invoice: Invoice) => {
      dispatch(showLoader());

      timesheets?.map((timesheet, timesheetIndex) => {
        const newBillingLines: Array<BillingLine> = [];
        timesheet.items.map((billingline, index) => {
          const billingLineInvoices = invoice.lineItems?.filter((lineItem) => {
            return (
              lineItem.transaction?._id === billingline.transactionid &&
              lineItem.lineno === index
            );
          });
          if (billingLineInvoices?.length > 0) {
            newBillingLines.push({ ...billingline, invoicedAt: new Date() });
          } else {
            newBillingLines.push(billingline);
          }
          return '';
        });

        let countBillingLinesInvoiced = 0;
        newBillingLines.map((line) => {
          if (line.invoicedAt) {
            countBillingLinesInvoiced++;
          }
          return '';
        });

        const newTimesheet: Timesheet = {
          ...timesheet,
          items: newBillingLines,
          allInvoicedAt:
            countBillingLinesInvoiced === timesheet?.items?.length
              ? new Date()
              : undefined,
        };

        const updateTimesheet: Flex = {
          collection: 'timesheets',
          model: newTimesheet,
          _id: timesheet._id,
        };

        flexUpdate(updateTimesheet).then(() => {
          if (timesheetIndex + 1 === timesheets?.length) {
            const flexInvoice: Flex = {
              collection: 'invoices',
              model: invoice,
            };

            flexInsert(flexInvoice).then(() => {
              dispatch(hideLoader());
              setNewInvoices(undefined);
              setTimesheets(undefined);
              isLoaded.current = false;
            });
          }
        });
        return '';
      });
    },
    [timesheets, dispatch]
  );

  const countCoachingProgrammes = useCallback(() => {
    let total = 0;
    newInvoices?.filter((invoice) => {
      invoice.lineItems.filter((lineItem) => {
        if (lineItem.transaction.coachProgrammeid) {
          total++;
        }
        return '';
      });
      return '';
    });
    return total;
  }, [newInvoices]);

  const countPaidInvoices = useCallback(() => {
    const total = existingInvoices?.filter(
      (invoice) => invoice.paidAt !== null && invoice.paidAt !== undefined
    );
    return total?.length;
  }, [existingInvoices]);

  const onViewTimeSheets = useCallback(() => {
    const reportParams: ReportViewerProps = {
      report: 'Timesheet Report',
      data: timesheets,
    };
    dispatch(setModalData(reportParams));
    dispatch(setModal(Modals.ReportViewer));
  }, [dispatch, timesheets]);

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      loadAll();
    }
  }, [loadAll]);

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row gx-0 align-items-center'>
              <div className='col page-heading heading-darkblue py-2 ps-3 border-bottom'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/billing.svg'
                    className='heading-image'
                    alt=''
                  />
                  <div className='col'>
                    <h1 className='my-0'>Current Coaching</h1>
                    <div>Billing Breakdown</div>
                  </div>
                </div>
                <hr />
                <div className='row align-items-center'>
                  <div className='col-5'>
                    <table className='table tbl-no-border mb-0'>
                      <tbody>
                        <tr>
                          <td>Programes running</td>
                          <td>: {countCoachingProgrammes()}</td>
                        </tr>
                        <tr>
                          <td>Invoices sent</td>
                          <td>
                            : {newInvoices?.length ?? 0}/
                            {existingInvoices?.length}
                          </td>
                        </tr>
                        <tr>
                          <td>Invoices paid</td>
                          <td>
                            : {countPaidInvoices()}/{existingInvoices?.length}
                          </td>
                        </tr>
                        <tr>
                          <td>Outstanding payments</td>
                          <td>
                            :{' '}
                            {(existingInvoices?.length ?? 0) -
                              (countPaidInvoices() ?? 0)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-3'>
              <h5>CURRENT COACHING COSTS</h5>
              <div className='row align-items-center pb-3 border-bottom'>
                <div className='col border-end border-lightblue'>
                  <div>Invoiced</div>
                  <h1>R 0</h1>
                </div>
                <div className='col'>
                  <div className='fnt-small'>Paid</div>
                  <div>R 0</div>
                  <div className='fnt-small mt-3'>Timesheets due</div>
                  <div>R 0</div>
                </div>
                <div className='col text-end'>
                  <a
                    className='btn btn-sml btn-brightblue btn-block'
                    href='#'
                    onClick={onViewTimeSheets}
                  >
                    View Timesheets{' '}
                    <i className='fa fa-chevron-circle-right ms-2 lightblue'></i>
                  </a>
                </div>
              </div>

              <h5 className='mt-3'>Organisation Billing Breakdown</h5>
              <table className='table align-middle'>
                <thead className='text-center'>
                  <tr>
                    <th scope='col'></th>
                    <th scope='col'>Company</th>
                    <th scope='col'>Team</th>
                    <th scope='col'>Invoiced</th>
                    <th scope='col'>Paid invoices</th>
                    {/* <th scope="col">Coaching Progress</th> */}
                    <th scope='col'></th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {newInvoices?.map((invoice) => {
                    return (
                      <tr className='tbl-tr'>
                        <td>
                          <div className='tbl-img'>
                            <img src='./images/placeholder.png' />
                          </div>
                        </td>
                        <td>{invoice.orgName}</td>
                        <td></td>
                        <td>R {invoice.totalAmount.toFixed(2)}</td>
                        <td>1/2</td>
                        {/* <td>7/50</td> */}
                        <td className='text-end'>
                          <a
                            href='#'
                            onClick={() => sendInvoice(invoice)}
                            className='btn bg-darkblue text-white'
                          >
                            Send invoice
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                  {existingInvoices?.map((invoice) => {
                    return (
                      <tr className='tbl-tr'>
                        <td>
                          <div className='tbl-img'>
                            <img src='./images/placeholder.png' />
                          </div>
                        </td>
                        <td>{invoice.orgName}</td>
                        <td></td>
                        <td>R {invoice.totalAmount.toFixed(2)}</td>
                        <td>0/1</td>
                        {/* <td>7/50</td> */}
                        <td className='text-end'>
                          {/* <a
                            href="#"
                            onClick={() => sendInvoice(invoice)}
                            className="btn bg-darkblue text-white">
                            Send invoice
                          </a> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingBreakdown;
