import "./App.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppContainer from "./pages/app-container";

function App() {
  return (
    <Provider store={store}>
      <AppContainer></AppContainer>
    </Provider>
  );
}

export default App;
