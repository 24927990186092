import { useCallback, useEffect, useRef, useState } from 'react';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { Invoice } from '../../../interfaces/invoice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { hideLoader, showLoader } from '../../../redux/ui/uiSlice';
import { Coachee, Flex } from '../../../interfaces';
import { flexGetAll } from '../../../api';
import {
  Transaction,
  TransactionDisplay,
} from '../../../interfaces/transaction';
import { OrgProgrammeReport } from '../../../interfaces/backend.report';

function OrgTeamBilling() {
  const [invoices, setInvoices] = useState<Array<Invoice> | undefined>();
  const dispatch = useDispatch();
  const sessionState = useSelector((state: RootState) => state.session);

  const getBudget = useCallback((programme: OrgProgrammeReport | undefined) => {
    let total = 0;
    if (programme !== undefined) {
      total +=
        (programme.coachRate ?? 0) *
        programme.coachees.length *
        programme.numberOfSessions;
    }
    return total;
  }, []);

  const getSpent = useCallback((programme: OrgProgrammeReport | undefined) => {
    let total = 0;
    if (programme !== undefined) {
      programme.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            const completed = tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.coachConfirmedCompleted &&
                coachingSession.coacheeConfirmedCompleted
            ).length;
            total += completed * (programme.coachRate ?? 0);
          }
        });
      });
    }
    return total;
  }, []);

  const getCompletedSessions = (transactions: Array<Transaction>) => {
    let total = 0;
    transactions?.map((tx) => {
      total +=
        tx.coachingSessions?.filter(
          (item) =>
            item.chemistry1 === false &&
            item.chemistry2 === false &&
            item.coachConfirmedCompleted &&
            item.coacheeConfirmedCompleted
        )?.length ?? 0;
    });
    return total;
  };

  const getBalance = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      return getBudget(programme) - getSpent(programme);
    },
    [getBudget, getSpent]
  );

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row mt-4 g-x'>
              <div className='col-2'>
                <div className='n-card bg-medblue py-3 px-2 text-center text-white h-100'>
                  <div className='icon-avatar bg-white m-auto'>
                    <img
                      src='./images/svg/user-group.svg'
                      className='icon-medblue'
                    />
                  </div>
                  <div className='h5 mt-2 mb-0'>
                    {sessionState.selectedProgramme?.department}
                  </div>
                  <div className='fnt-wt-400 fnt-small'>
                    {sessionState.selectedProgramme?.coachees?.length} Coachees
                  </div>
                  <a className='btn btn-sml btn-transparent text-white light-italic mt-2'>
                    <i className='fa fa-plus-circle me-1'></i> Add a coachee
                  </a>
                </div>
              </div>
              <div className='col'>
                <div className='n-card p-3 bg-medblue text-white h-100'>
                  <div className='row h-100'>
                    <div className='col-8 border-lightblue'>
                      <div>BUDGET</div>
                      <h1 className='text-white'>
                        R{' '}
                        {getBudget(
                          sessionState.selectedReportProgramme ?? undefined
                        )}
                      </h1>
                      <div>Programme Coaching rate </div>
                      <div>
                        R {sessionState.selectedProgramme?.coachingRate}/hr
                      </div>
                      <div className='row mt-3'>
                        <div className='col'>
                          <div className='budget-label'>Balance</div>
                          <div>
                            R{' '}
                            {getBalance(
                              sessionState.selectedReportProgramme ?? undefined
                            )}
                          </div>
                        </div>
                        <div className='col border-start '>
                          <div className='budget-label'>Spent</div>
                          <div>
                            R{' '}
                            {getSpent(
                              sessionState.selectedReportProgramme ?? undefined
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col text-center text-white align-self-center'>
                      <div className='budgetpercent'>
                        <div className='fnt-small fnt-wt-800'>BUDGET</div>
                        <h1 className='fnt-wt-800 text-white'>
                          {' '}
                          {(getSpent(
                            sessionState.selectedReportProgramme ?? undefined
                          ) /
                            getBudget(
                              sessionState.selectedReportProgramme ?? undefined
                            )) *
                            100}
                          %
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-3'>
              <table className='table tbl-alt no-border tbl-v-border align-middle'>
                <thead className='text-center'>
                  <tr>
                    <th>Coachee</th>
                    <th>Allocated Budget</th>
                    <th>Actual Spent</th>
                    <th>Sessions Completed</th>
                    <th>Late Cancellations</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {sessionState?.selectedReportProgramme?.coachees?.map(
                    (coachee) => {
                      return (
                        <tr className='tbl-tr'>
                          <td className='text-start'>
                            <img
                              src='./images/avatar.png'
                              className='tbl-img small d-inline mx-2'
                            />
                            {coachee?.firstname} {coachee?.lastname}
                          </td>
                          <td>
                            R{' '}
                            {(sessionState?.selectedProgramme
                              ?.numberOfSessions ?? 0) *
                              (sessionState?.selectedProgramme?.coachingRate ??
                                0)}
                          </td>
                          <td>
                            R{' '}
                            {getCompletedSessions(coachee?.transactions) *
                              (sessionState.selectedProgramme?.coachRate ?? 0)}
                          </td>
                          <td>{getCompletedSessions(coachee?.transactions)}</td>
                          <td>0</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgTeamBilling;
