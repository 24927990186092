import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { RootState } from '../../../redux/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CoachProgramme, Flex, User } from '../../../interfaces';
import { hideLoader, setScreen, showLoader } from '../../../redux/ui/uiSlice';
import { flexGetAll, flexGetOne, flexGetUserByEmail } from '../../../api';
import { Transaction } from '../../../interfaces/transaction';
import {
  setSelectedProgramme,
  setSelectedUser,
} from '../../../redux/session/sessionSlice';
import { AppScreens } from '../../../enums';

function CoacheeProfile() {
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const isLoaded = useRef(false);
  const [user, setUser] = useState<User>();
  const [coach, setCoach] = useState<User>();
  const [coacheeProgramme, setCoacheeProgramme] = useState<CoachProgramme>();
  const [transaction, setTransaction] = useState<Transaction>();

  const loadAll = useCallback(() => {
    dispatch(showLoader());
    flexGetUserByEmail(sessionState.selectedCoachee?.email ?? '')
      .then((flexUserResponse) => {
        if (
          flexUserResponse.status === 200 &&
          flexUserResponse.data?.length > 0
        ) {
          const user: User = flexUserResponse.data[0];

          setUser(user);

          const flexTransactions: Flex = {
            collection: 'transactions',
            query: {
              userid: user?._id ?? '',
            },
          };
          flexGetAll(flexTransactions)
            .then((flexTransactionsResponse) => {
              if (
                flexTransactionsResponse.status === 200 &&
                flexTransactionsResponse.data?.length > 0
              ) {
                const transaction: Transaction =
                  flexTransactionsResponse.data[0];
                setTransaction(transaction);

                flexGetOne('users', transaction.coachid ?? '')
                  .then((coachResponse) => {
                    setCoach(coachResponse.data);
                    flexGetOne(
                      'coaching-programme',
                      transaction.coachProgrammeid ?? ''
                    )
                      .then((flexCoacheeProgrammeResponse) => {
                        if (flexCoacheeProgrammeResponse.status === 200) {
                          setCoacheeProgramme(
                            flexCoacheeProgrammeResponse.data
                          );
                          dispatch(hideLoader());
                        } else {
                          dispatch(hideLoader());
                        }
                      })
                      .catch(() => {
                        dispatch(hideLoader());
                      });
                  })
                  .catch(() => {
                    dispatch(hideLoader());
                  });
              } else {
                dispatch(hideLoader());
              }
            })
            .catch(() => {
              dispatch(hideLoader());
            });
        } else {
          dispatch(hideLoader());
        }
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  }, [dispatch, sessionState]);

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      loadAll();
    }
  }, [loadAll]);

  const getCompletedPercentage = useCallback(() => {
    if (transaction && coacheeProgramme) {
      if (
        transaction.coachingSessions?.filter(
          (item) =>
            item.chemistry1 === false &&
            item.chemistry2 === false &&
            item.coachConfirmedCompleted &&
            item.coacheeConfirmedCompleted
        )
      ) {
        return (
          ((transaction.coachingSessions?.filter(
            (item) =>
              item.chemistry1 === false &&
              item.chemistry2 === false &&
              item.coachConfirmedCompleted &&
              item.coacheeConfirmedCompleted
          )?.length ?? 0) /
            (coacheeProgramme?.numberOfSessions ?? 0)) *
          100
        );
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [transaction, coacheeProgramme]);

  const viewPastCoaching = useCallback(() => {
    if (user && coacheeProgramme) {
      dispatch(setSelectedUser(user));
      dispatch(setScreen(AppScreens.CoacheePastCoaching));
    }
  }, [user, coacheeProgramme, dispatch]);

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row align-items-center border-bottom'>
              <h1>Coachee Profile</h1>
            </div>

            <div className='row p-3 border-bottom align-items-center'>
              <div className='col-2'>
                <div className='rnd-cnr p-3 bg-medblue text-center text-white'>
                  <div className='image-avatar bordered border-white bg-medblue mb-2'>
                    <img src='./images/avatar.png' />
                  </div>
                  <h5 className='mb-1'>
                    {user?.firstname} {user?.lastname}
                  </h5>
                  <div className='small darkblue'>
                    {getCompletedPercentage()}% completed
                  </div>
                  &nbsp;
                  <br />
                  &nbsp;
                  <br />
                  {/* <a className="btn btn-sml btn-transparent light-italic p-0 mt-3 text-white">
                    <i className="fa fa-plus-circle me-1"></i> remove coachee
                  </a> */}
                </div>
              </div>
              <div className='col'>
                <h5 className='mb-0 medblue'>Department</h5>
                <div className='mb-2'>{coacheeProgramme?.department}</div>
                <h5 className='mb-0 medblue'>Position</h5>
                <div className='mb-2'>{user?.position}</div>
                <h5 className='mb-0 medblue'>Line Manager</h5>
                <div className='mb-2'>{coacheeProgramme?.departmentHead}</div>
                <h5 className='mb-0 medblue'>Completed ASsessments</h5>
                <div className='mb-2'>Insights</div>
              </div>
            </div>

            <div className='row p-3 border-bottom'>
              <h4 className='medblue'>Progress</h4>
              <div>{getCompletedPercentage()}% complete</div>
              <div className='col pe-5 mt-2'>
                <div className='progress bg-fadedblue rounded-pill'>
                  <div
                    className='progress-bar bg-brightblue rounded-pill'
                    role='progressbar'
                    style={{
                      width: getCompletedPercentage() + '%',
                    }}
                  ></div>
                </div>
                <div className='row mt-4 text-start'>
                  <div className='col'>
                    <table className='table tbl-no-border mb-0'>
                      <tbody>
                        <tr>
                          <td>Allocated sessions</td>
                          <td>: {coacheeProgramme?.numberOfSessions}hrs</td>
                        </tr>
                        <tr>
                          <td>Chemistry Sessions completed</td>
                          <td>
                            :{' '}
                            {transaction?.coachingSessions
                              ? transaction.coachingSessions?.filter(
                                  (item) =>
                                    item.chemistry1 === true ||
                                    (item.chemistry2 === true &&
                                      item.coachConfirmedCompleted &&
                                      item.coacheeConfirmedCompleted)
                                )?.length ?? 0
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td>Sessions completed</td>
                          <td>
                            :{' '}
                            {transaction?.coachingSessions
                              ? transaction.coachingSessions?.filter(
                                  (item) =>
                                    item.chemistry1 === false &&
                                    item.chemistry2 === false &&
                                    item.coachConfirmedCompleted &&
                                    item.coacheeConfirmedCompleted
                                )?.length ?? 0
                              : 0}
                            hrs
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Three ways</td>
                          <td>: 1/3</td>
                        </tr> */}
                        <tr>
                          <td>Late cancellations</td>
                          <td>: 0hr</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='col-5 align-self-center'>
                    <div className='row'>
                      <div className='col-3 ms-4 text-end'>
                        <img
                          src='./images/avatar.png'
                          className='selected-coach-img'
                        />
                      </div>
                      <div className='col align-self-center'>
                        <div>Coach Selected</div>
                        <div className='fnt-wt-400'>
                          {coach?.firstname} {coach?.lastname}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='n-card p-3 bg-darkblue text-center text-white'>
                  <div>Past Coaching</div>
                  <div className='div'>
                    <img
                      src='./images/svg/coaching.svg'
                      className='icon-medblue mt-2'
                    />
                  </div>
                  <a
                    href='#'
                    onClick={() => viewPastCoaching()}
                    className='btn btn-sml btn-white mt-3'
                  >
                    View
                  </a>
                </div>
              </div>
            </div>

            <div className='row p-3'>
              <div className='col-3'>
                <h4 className='medblue mb-0'>Allocated Budget</h4>
                <div>
                  R{' '}
                  {(coacheeProgramme?.numberOfSessions ?? 0) *
                    (coacheeProgramme?.coachingRate ?? 0)}
                </div>
              </div>
              <div className='col-3'>
                <h4 className='medblue mb-0'>Actual spend</h4>
                <div>
                  R{' '}
                  {(coacheeProgramme?.coachingRate ?? 0) *
                    (transaction?.coachingSessions?.filter(
                      (item) =>
                        item.chemistry1 === false &&
                        item.chemistry2 === false &&
                        item.coachConfirmedCompleted &&
                        item.coacheeConfirmedCompleted
                    )?.length ?? 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoacheeProfile;
