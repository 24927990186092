import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { ReportViewerProps } from '../../modals/report-viewer';
import { Timesheet } from '../../interfaces/timesheet';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: 20,
  },
  col: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    width: 150,
    fontSize: 12,
  },
  heading: {
    margin: 10,
    padding: 10,
    fontSize: 18,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
});

interface TimesheetReportProps {
  params: ReportViewerProps;
}

const TimesheetReport = ({ params }: TimesheetReportProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.heading}>Timesheet Report</Text>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.bold}>Coachee</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Date</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Session Hours</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Coach</Text>
            </View>
          </View>
          {params.data?.map((timesheet: Timesheet) => {
            timesheet.items?.map(billingLine => {
              return (
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Text>
                      {billingLine?.user?.firstname}{' '}
                      {billingLine?.user?.lastname}
                    </Text>
                  </View>
                  <View style={styles.col}>
                    <Text>{billingLine.bookingDate}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text>1</Text>
                  </View>
                  <View style={styles.col}>
                    <Text>
                      {billingLine?.coach?.firstname}{' '}
                      {billingLine?.coach?.lastname}
                    </Text>
                  </View>
                </View>
              );
            });
          })}
        </View>
      </Page>
    </Document>
  );
};

export default TimesheetReport;
