import { hideLoader, hideMessage, showLoader } from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { RootState } from '../../redux/store';
import { useState } from 'react';
import { Gender, Race } from '../../enums';
import { getCoacheesForProgramme } from '../../api/report.api';
import { CoacheeTransactions } from '../../interfaces/coachee-transactions';

interface AttendanceReportFilterProps {
  onFilter: (
    fromDate?: string,
    toDate?: string,
    race?: string,
    gender?: string,
    coachees?: Array<CoacheeTransactions>,
  ) => void;
  onCancel: () => void;
}

function AttendanceReportFilter({
  onFilter,
  onCancel,
}: AttendanceReportFilterProps) {
  const sessionState = useSelector((state: RootState) => state.session);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [race, setRace] = useState('');
  const [gender, setGender] = useState('');
  const [coacheeTransactions, setCoacheeTransactions] = useState<
    Array<CoacheeTransactions> | undefined
  >();
  const [selectedCoachees, setSelectedCoachees] = useState<
    Array<CoacheeTransactions>
  >([]);

  const dispatch = useDispatch();

  const onOk = useCallback(() => {
    dispatch(hideMessage());
    if (fromDate === '') {
      alert('From date is required');
      return;
    } else if (toDate === '') {
      alert('To date is required');
      return;
    } else {
      onFilter(fromDate, toDate, race, gender, selectedCoachees);
    }
  }, [dispatch, fromDate, toDate, race, gender, selectedCoachees, onFilter]);

  const onClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (coacheeTransactions === undefined) {
      dispatch(showLoader());

      getCoacheesForProgramme(sessionState.selectedProgramme?._id ?? '')
        .then(response => {
          dispatch(hideLoader());
          if (response.status === 200) {
            setCoacheeTransactions(response.data);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [coacheeTransactions, dispatch, sessionState]);

  const onAddCoachee = useCallback(
    (coachee?: CoacheeTransactions) => {
      if (coachee) {
        let newCoachees = [...selectedCoachees];
        newCoachees.push(coachee);
        setSelectedCoachees(newCoachees);
      }
    },
    [selectedCoachees],
  );

  return (
    <div
      className="modal modal-visible modal-backdrop2"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Coaching Attendance Report Filter</h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-floating mb-3">
                  <input
                    type="date"
                    className="form-control"
                    placeholder=""
                    value={fromDate}
                    onChange={val => setFromDate(val.target.value)}
                  />
                  <label>From Date</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-floating mb-3">
                  <input
                    type="date"
                    className="form-control"
                    placeholder=""
                    value={toDate}
                    onChange={val => setToDate(val.target.value)}
                  />
                  <label>To Date</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-floating mb-3">
                  <select
                    className="form-control"
                    id="floatingInput"
                    value={''}
                    onChange={val =>
                      onAddCoachee(
                        coacheeTransactions?.filter(
                          item => item.user._id === val.target.value,
                        )[0],
                      )
                    }>
                    <option value=""></option>
                    {coacheeTransactions?.map(coachee => {
                      return (
                        <option value={coachee.user?._id}>
                          {coachee.user?.firstname} {coachee.user?.lastname}
                        </option>
                      );
                    })}
                  </select>
                  <label>Add Coachees Manually</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <ul>
                  {selectedCoachees.map(coachee => {
                    return (
                      <li>
                        {coachee.user?.firstname} {coachee.user?.lastname}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-floating mb-3">
                  <select
                    className="form-control"
                    id="floatingInput"
                    value={race}
                    onChange={val => setRace(val.target.value as Race)}>
                    <option value=""></option>
                    <option value={Race.Black}>{Race.Black}</option>
                    <option value={Race.Coloured}>{Race.Coloured}</option>
                    <option value={Race.Indian}>{Race.Indian}</option>
                    <option value={Race.White}>{Race.White}</option>
                  </select>
                  <label>Race</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-floating mb-3">
                  <select
                    className="form-control"
                    id="floatingInput"
                    value={gender}
                    onChange={val => setGender(val.target.value as Race)}>
                    <option value=""></option>
                    <option value={Gender.Female}>{Gender.Female}</option>
                    <option value={Gender.Male}>{Gender.Male}</option>
                    <option value={Gender.NonNinary}>{Gender.NonNinary}</option>
                  </select>
                  <label>Gender</label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-darkblue"
              data-dismiss="modal"
              onClick={onOk}>
              OK
            </button>
            <button
              type="button"
              className="btn btn-darkblue"
              data-dismiss="modal"
              onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceReportFilter;
