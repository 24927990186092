import { useState, useRef, useEffect, useCallback } from 'react';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { Department, Flex, Org, User } from '../../../interfaces';
import { flexGetAll } from '../../../api/flex.api';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, setModal, showLoader } from '../../../redux/ui/uiSlice';
import {
  setOrg,
  setSelectedDepartment,
} from '../../../redux/session/sessionSlice';
import { Modals } from '../../../enums';
import { RootState } from '../../../redux/store';

function ClientList() {
  const [orgs, setOrgs] = useState<Array<Org> | undefined>();
  const isLoaded = useRef(false);
  const dispatch = useDispatch();
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);

  const load = useCallback(() => {
    dispatch(showLoader());
    const flex: Flex = {
      collection: 'org',
    };
    flexGetAll(flex)
      .then(flexResults => {
        dispatch(hideLoader());
        if (flexResults.status === 200) {
          setOrgs(flexResults.data);
        } else {
          alert('Error 2:' + JSON.stringify(flexResults.data));
        }
      })
      .catch(err => {
        dispatch(hideLoader());
        alert('Error 1:' + JSON.stringify(err));
      });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      load();
    }
  }, [isLoaded, load]);

  const onViewTeams = useCallback(
    (org: Org) => {
      dispatch(setOrg(org));
    },
    [dispatch],
  );

  const getDepartments = useCallback((org: Org) => {
    return org?.departments?.filter(dep => (dep.department ?? '') !== '');
  }, []);

  const onAddCoachingProgramme = useCallback(
    (department: Department) => {
      dispatch(setSelectedDepartment(department));
      dispatch(setModal(Modals.NewCoacheeRequest));
    },
    [dispatch],
  );

  return (
    <div className="container-fluid h-100 d-flex">
      <div className="row flex-grow-1 bg-white">
        <SideMenu />
        <div className="col page-content p-0">
          <NavBar />
          {sessionState.org === null ? (
            <div className="p-5">
              <div className="row gx-0 align-items-center">
                <div className="col page-heading heading-darkblue py-2 ps-3 border-bottom">
                  <div className="row g-0 align-items-center">
                    <img
                      src="images/svg/coaching.svg"
                      className="heading-image"
                    />
                    <div className="col">
                      <h1 className="my-0">Client List</h1>
                      <div>
                        <strong>Organizations: </strong>
                        {orgs?.length}
                      </div>
                    </div>
                    {/* <div className="col-12 ps-5">
                    <a className="btn btn-sml btn-transparent light-italic p-0">
                      <i className="fa fa-plus-circle me-1"></i> Add an
                      Organization
                    </a>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="row mt-3 g-3 row-cols-xl-4 row-cols-lg-2 row-cols-md-1">
                {orgs?.map((org: Org) => {
                  return (
                    <div className="col">
                      <div className="n-card p-3 client">
                        <div className="client-img-container">
                          <img src="./images/placeholder.png" />
                        </div>
                        <div className="client-title large text-center">
                          {org?.organisation}
                        </div>
                        {/* <div className="hrs val large text-center pb-3">
                        0<span className="hrs large">hrs</span>
                      </div> */}
                        <div className="row align-items-center g-0">
                          <div className="col-4 text-center">
                            <img
                              src="images/svg/user-group.svg"
                              className="icon-brightblue"
                              alt=""
                            />
                          </div>
                          <div className="col">
                            <div>Teams</div>
                            <div>{getDepartments(org).length}</div>
                          </div>
                        </div>
                        <hr className="m-1" />
                        <div className="text-center pt-3">
                          <a
                            className="btn btn-sml btn-darkblue"
                            href="#"
                            onClick={() => onViewTeams(org)}>
                            View Teams
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="p-5">
              <div className="row gx-0 align-items-center">
                <div className="col page-heading heading-darkblue py-2 ps-3 border-bottom">
                  <div className="row g-0 align-items-center">
                    <img
                      src="images/svg/coaching.svg"
                      className="heading-image"
                    />
                    <div className="col">
                      <h1 className="my-0">
                        {sessionState.org?.organisation}
                      </h1>
                      <div>
                        <strong>Teams: </strong>
                        {
                          sessionState.org?.departments?.filter(
                            dep => (dep.department ?? '') !== '',
                          ).length
                        }
                      </div>
                    </div>
                    <div className="col-12 ps-5">
                      <a
                        className="btn btn-sml btn-transparent light-italic p-0"
                        onClick={() => dispatch(setOrg(null))}>
                        <i className="fa fa-plus-circle me-1"></i> Back to
                        clients
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 g-3 row-cols-xl-4 row-cols-lg-2 row-cols-md-1">
                {sessionState.org &&
                getDepartments(sessionState.org).length > 0 ? (
                  <>
                    {getDepartments(sessionState.org).map(
                      (team: Department) => {
                        return (
                          <div className="col">
                            <div className="n-card p-3 client">
                              <div className="client-img-container">
                                <img src="./images/placeholder.png" alt="" />
                              </div>
                              <div className="client-title large text-center">
                                {team.department}
                              </div>
                              {/* <div className="hrs val large text-center pb-3">
                        0<span className="hrs large">hrs</span>
                      </div> */}
                              {/* <div className="row align-items-center g-0">
                              <div className="col-4 text-center">
                                <img
                                  src="images/svg/user-group.svg"
                                  className="icon-brightblue"
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div>Teams</div>
                                <div></div>
                              </div>
                            </div> */}
                              <hr className="m-1" />
                              <div className="text-center pt-3">
                                <a
                                  className="btn btn-sml btn-darkblue"
                                  href="#"
                                  onClick={() => onAddCoachingProgramme(team)}>
                                  Add Programme
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      },
                    )}
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientList;
