import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Department,
  User,
  Org,
  CoachProgramme,
  Coachee,
} from '../../interfaces';
import { OrgProgrammeReport } from '../../interfaces/backend.report';

export type Session = {
  user: User | null;
  org: Org | null;
  orgUsers: Array<User> | null;
  selectedDepartment: Department | null;
  selectedUser: User | null;
  selectedOrg: Org | null;
  selectedProgramme: CoachProgramme | null;
  selectedCoach: User | null;
  selectedCoachee: Coachee | null;
  selectedReportProgramme: OrgProgrammeReport | null;
};

const initialState: Session = {
  user: null,
  org: null,
  orgUsers: null,
  selectedDepartment: null,
  selectedUser: null,
  selectedOrg: null,
  selectedProgramme: null,
  selectedCoach: null,
  selectedCoachee: null,
  selectedReportProgramme: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    sessionLogin: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    sessionLogout: (state) => {
      state.user = null;
      state.org = null;
      state.orgUsers = null;
    },
    setOrg: (state, action: PayloadAction<Org | null>) => {
      state.org = action.payload;
    },
    setOrgUsers: (state, action: PayloadAction<Array<User>>) => {
      state.orgUsers = action.payload;
    },
    setSelectedDepartment: (state, action: PayloadAction<Department>) => {
      state.selectedDepartment = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<User>) => {
      state.selectedUser = action.payload;
    },
    setSelectedOrg: (state, action: PayloadAction<Org | null>) => {
      state.selectedOrg = action.payload;
    },
    setSelectedProgramme: (
      state,
      action: PayloadAction<CoachProgramme | null>
    ) => {
      state.selectedProgramme = action.payload;
    },
    setSelectedCoach: (state, action: PayloadAction<User>) => {
      state.selectedCoach = action.payload;
    },
    setSelectedCoachee: (state, action: PayloadAction<Coachee>) => {
      state.selectedCoachee = action.payload;
    },
    setSelectedReportProgramme: (
      state,
      action: PayloadAction<OrgProgrammeReport | null>
    ) => {
      state.selectedReportProgramme = action.payload;
    },
  },
});

export const {
  sessionLogin,
  sessionLogout,
  setOrg,
  setSelectedDepartment,
  setSelectedUser,
  setSelectedOrg,
  setSelectedProgramme,
  setSelectedCoach,
  setSelectedCoachee,
  setSelectedReportProgramme,
} = sessionSlice.actions;
export default sessionSlice.reducer;
