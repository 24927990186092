import { hideLoader, setModal, showLoader } from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RootState } from '../../redux/store';
import { Modals } from '../../enums';
import moment from 'moment';
import {
  Flex,
  ListItem,
  MonthlyReport,
  Report,
  Themes,
} from '../../interfaces';
import { flexGetAll, flexInsert } from '../../api';
import { listFromEnum } from '../../utils';

function EditMonthlyReport() {
  const sessionState = useSelector((state: RootState) => state.session);
  const uiState = useSelector((state: RootState) => state.ui);
  const isLoaded = useRef(false);
  const dispatch = useDispatch();

  const [reports, setReports] = useState<Array<Report> | undefined>(undefined);
  const themes: Array<ListItem> = listFromEnum(Themes);
  const [activeThemes, setActiveThemes] = useState<Array<ListItem>>([]);
  const report: Report = uiState.modalData;

  const onPublish = useCallback(() => {
    dispatch(showLoader());

    console.log('activeThemes', activeThemes);

    const monthlyReport: MonthlyReport = {
      orgid: sessionState?.org?._id ?? '',
      createdOn: new Date(report.submittedOn ?? ''),
      themes: activeThemes,
      reports: reports,
    };

    const flex: Flex = {
      collection: 'monthly-reports',
      model: monthlyReport,
    };

    flexInsert(flex).then(() => {
      dispatch(hideLoader());
      dispatch(setModal(Modals.None));
    });
  }, [dispatch, activeThemes, reports, report, sessionState]);

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  const loadData = useCallback(() => {
    dispatch(showLoader());
    const flex: Flex = {
      collection: 'reports',
      query: {
        orgid: sessionState.org?._id ?? '',
      },
    };
    flexGetAll(flex)
      .then((flexResponse) => {
        dispatch(hideLoader());
        if (flexResponse.status === 200) {
          setReports(flexResponse.data);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert('Error 1:' + JSON.stringify(err));
      });
  }, [dispatch, sessionState.org]);

  const hasTheme = useCallback(
    (lookup: ListItem) => {
      let total = 0;
      reports?.map((report) => {
        const reportThemes = report.theme?.split(',');
        const theme = reportThemes?.filter((item) => item === lookup.value);
        if (theme && theme.length > 0) {
          total += theme?.length;
        }
      });
      return total;
    },
    [reports]
  );

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      loadData();
    }
  }, [loadData]);

  const setNotes = useCallback(
    (report: Report, newNotes: string) => {
      const newReport: Report = {
        ...report,
        notes: newNotes,
      };

      const newReports: Array<Report> = [];
      reports?.map((existingReport) => {
        if (existingReport._id === report._id) {
          newReports.push(newReport);
        } else {
          newReports.push(existingReport);
        }
        return '';
      });
      setReports(newReports);
    },
    [reports]
  );

  const setInclude = useCallback(
    (report: Report, newInclude?: boolean) => {
      const newReport: Report = {
        ...report,
        include: newInclude,
      };

      const newReports: Array<Report> = [];
      reports?.map((existingReport) => {
        if (existingReport._id === report._id) {
          newReports.push(newReport);
        } else {
          newReports.push(existingReport);
        }
        return '';
      });
      setReports(newReports);
    },
    [reports]
  );

  const onToggleTheme = useCallback(
    (theme: ListItem) => {
      if (
        activeThemes?.filter(
          (existingTheme) => existingTheme.value === theme.value
        ).length > 0
      ) {
        const newThemes: Array<ListItem> = activeThemes?.filter(
          (existingTheme) => existingTheme.value !== theme.value
        );
        setActiveThemes(newThemes);
        console.log('newThemes', newThemes);
      } else {
        const newThemes: Array<ListItem> = [...activeThemes];
        newThemes.push(theme);
        setActiveThemes(newThemes);

        console.log('newThemes', newThemes);
      }
    },
    [activeThemes]
  );

  const themeToggled = useCallback(
    (theme: ListItem) => {
      if (
        activeThemes?.filter(
          (existingTheme) => existingTheme.value === theme.value
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    [activeThemes]
  );

  return (
    <div
      className='modal modal-visible modal-backdrop2'
      id='exampleModal'
      role='dialog'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>Monthly Report</h4>
          </div>
          <div className='modal-body'>
            <p>
              Edit monthly report before it is published to the organization
            </p>
            <div className='row'>
              <div className='col-lg-3 textRight'>Month:</div>
              <div className='col-lg-3'>
                {moment(new Date(report.submittedOn ?? '')).format('MMMM YYYY')}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-3 textRight'>Coaching Completed:</div>
              <div className='col-lg-3'>{reports?.length}</div>
            </div>
            <div className='row'>
              <div className='col-lg-3 textRight'>Team:</div>
              <div className='col-lg-3'>
                {sessionState?.selectedProgramme?.department}
              </div>
            </div>
            <br />
            <p>
              <strong>Themes and Objectives</strong>
            </p>
            <div className='col-lg-12 detail-table'>
              <div className='row'>
                <div className='col-lg-4'>
                  <strong>Times selected</strong>
                </div>
                <div className='col-lg-4'>
                  <strong>Themes and Objectives</strong>
                </div>
                <div className='col-lg-4'></div>
              </div>
              {themes?.map((theme) => {
                if (hasTheme(theme)) {
                  return (
                    <div className='row' style={{ paddingBottom: 4 }}>
                      <div className='col-lg-4'>{hasTheme(theme)}</div>
                      <div className='col-lg-4'>{theme.label}</div>
                      <div className='col-lg-4'>
                        {' '}
                        <button
                          type='button'
                          className={
                            themeToggled(theme)
                              ? 'btn btn-darkblue'
                              : 'btn btn-green'
                          }
                          data-dismiss='modal'
                          onClick={() => onToggleTheme(theme)}
                        >
                          {themeToggled(theme) ? 'Activate' : 'Deactivate'}
                        </button>
                      </div>
                    </div>
                  );
                }
                return '';
              })}
            </div>
            <br />
            <p>
              <strong>Additional Notes</strong>
              <br />
              <small>you can manually edit the additional notes</small>
            </p>
            <div className='col-lg-12 detail-table'>
              <div className='row'>
                <div className='col-lg-3'>
                  <strong>Session Date</strong>
                </div>
                <div className='col-lg-2'>
                  <strong>Coachee</strong>
                </div>
                <div className='col-lg-2'>
                  <strong>Coach</strong>
                </div>
                <div className='col-lg-3'>
                  <strong>Session Note</strong>
                </div>
                <div className='col-lg-1'></div>
              </div>
              {reports?.map((report) => {
                return (
                  <div className='row'>
                    <div className='col-lg-3'>
                      {moment(new Date(report.submittedOn ?? '')).format(
                        'YYYY-MM-DD'
                      )}
                    </div>
                    <div className='col-lg-2'>
                      {report.user?.firstname} {report.user?.lastname}
                    </div>
                    <div className='col-lg-2'>
                      {report.coach?.firstname} {report.coach?.lastname}
                    </div>
                    <div className='col-lg-3'>
                      <textarea
                        className='form-control'
                        onChange={(val) => setNotes(report, val.target.value)}
                      >
                        {report.notes}
                      </textarea>
                    </div>
                    <div className='col-lg-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                        checked={report.include}
                        onChange={(val) =>
                          setInclude(report, val.target.checked)
                        }
                      />
                      Include
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='modal-footer'>
            <div className='row'>
              <div className='col-lg-7'>
                <button
                  type='button'
                  className='btn btn-darkblue'
                  data-dismiss='modal'
                  onClick={onPublish}
                >
                  Publish Report
                </button>
              </div>
              <div className='col-lg-5'>
                <button
                  type='button'
                  className='btn btn-darkblue'
                  data-dismiss='modal'
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditMonthlyReport;
