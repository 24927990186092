import axios, { AxiosResponse } from 'axios';
import { Config } from '../config';

export const getAllOrgProgrammes = (orgid: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: 'get',
      url:
        `${Config.API_SERVER}/backend-reports/get-all-org-programmes/` + orgid,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};
