import NavBar from "../../../components/nav-bar";
import SideMenu from "../../../components/side-menu";

function OrgPastTeamCoaching() {
  return (
    <div className="container-fluid h-100 d-flex">
      <div className="row flex-grow-1 bg-white">
        <SideMenu />
        <div className="col page-content p-0">
          <NavBar />
          <div className="p-5">
            <div className="row gx-0 align-items-center mb-4">
              <div className="col page-heading heading-darkblue py-3 ps-3 border-bottom">
                <div className="row g-0 align-items-center">
                  <img
                    src="images/svg/coaching.svg"
                    className="heading-image"
                  />
                  <div className="col">
                    <h1 className="my-0">Past Coaching</h1>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row p-2">
              <div className="col n-card p-3 bg-fadedblue">
                <div className="row align-items-center">
                  <div className="col-2">
                    <div className="n-card p-3 bg-darkblue text-center text-white">
                      <div className="icon-avatar bg-white m-auto">
                        <img
                          src="./images/svg/user-group.svg"
                          className="icon-darkblue"
                        />
                      </div>
                      <div className="mt-2 mb-2">IT Team</div>
                      <div className="hrs val text-white">
                        160<span className="hrs text-white">hrs</span>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <table className="table tbl-no-border mb-0">
                      <tbody>
                        <tr>
                          <td>Programme kick-off</td>
                          <td>: 30th June 2020</td>
                        </tr>
                        <tr>
                          <td>Programme completed on</td>
                          <td>: 15th February 2021</td>
                        </tr>
                        <tr>
                          <td>Programme type</td>
                          <td>: Leadership coaching</td>
                        </tr>
                        <tr>
                          <td>NDA signed</td>
                          <td>: N/A</td>
                        </tr>
                        <tr>
                          <td>Chemistry session completed</td>
                          <td>: 16</td>
                        </tr>
                        <tr>
                          <td>Selected coaches</td>
                          <td>: 8</td>
                        </tr>
                        <tr>
                          <td>Coaching Sessions completed</td>
                          <td>: 160 hours</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}

            <hr className="mb-2" />
            <h4 className="darkblue">Coachees:</h4>
            <hr className="mt-2" />

            <table className="table align-middle">
              <thead className="text-center">
                <tr>
                  <th>Coachee</th>
                  <th>Completed sessions</th>
                  <th>Three ways</th>
                  <th>Late cancellations</th>
                  <th>Selected coach</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {/* <tr className="tbl-tr">
                  <td className="text-start">
                    <img
                      src="./images/avatar.png"
                      className="tbl-img d-inline mx-2"
                    />
                    Rosetta Davis
                  </td>
                  <td>10hrs</td>
                  <td>2/3</td>
                  <td>1</td>
                  <td>Gae Nkosi</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgPastTeamCoaching;
