import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { ReportViewerProps } from '../../modals/report-viewer';
import { Report } from '../../interfaces';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: 20,
  },
  col: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    width: 150,
    fontSize: 12,
  },
  heading: {
    margin: 10,
    padding: 10,
    fontSize: 18,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
});

interface MonthlyReportProps {
  params: ReportViewerProps;
}

const MonthlyReport = ({ params }: MonthlyReportProps) => {
  // const uiState = useSelector((state: RootState) => state.ui);
  // const report: ReportViewerProps = uiState.modalData;
  // console.log('report',report);

  console.log('params', params);

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View>
          <Text style={styles.heading}>Monthly Report</Text>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.bold}>Date</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Coachee</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Coach</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Objective</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Notes</Text>
            </View>
          </View>
          {params.data?.map((report: Report) => {
            return (
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>
                    {moment(new Date(report.submittedOn ?? '')).format(
                      'YYYY-MM-DD'
                    )}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {report?.user?.firstname} {report?.user?.lastname}
                  </Text>
                </View>

                <View style={styles.col}>
                  <Text>
                    {report?.coach?.firstname} {report?.coach?.lastname}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>{report.objective}</Text>
                </View>
                <View style={styles.col}>
                  <Text>{report.notes}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default MonthlyReport;
