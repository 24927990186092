import { Modals, UserType } from '../../enums';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { setModal } from '../../redux/ui/uiSlice';
import { Org, Department, Mail } from '../../interfaces';
import { showLoader, hideLoader } from '../../redux/ui/uiSlice';
import { flexInsert } from '../../api';
import { Flex, User } from '../../interfaces';
import { register } from '../../api';
import { sendMail } from '../../api/comms.api';
import { Config } from '../../config';
import { makeId } from '../../utils';

function EditOrgModal() {
  const dispatch = useDispatch();
  const [adminEmail, setAdminEmail] = useState('');
  const [org, setOrg] = useState<Org>({
    departments: [{}, {}, {}, {}, {}, {}],
  });

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  const onSave = useCallback(() => {
    if (!org.organisation) {
      alert('Please enter the organisation name.');
      return;
      // } else if (!org.orgnumber) {
      //   alert('Please enter the organisation number.');
      //   return;
    } else {
      dispatch(showLoader());
      const flex: Flex = {
        collection: 'org',
        model: org,
      };
      flexInsert(flex)
        .then(response => {
          if (response.status === 200) {
            const newOrgId = response.data.insertedId;

            const newPassword = makeId(6);

            const newUser: User = {
              email: adminEmail,
              orgid: newOrgId,
              password: newPassword,
              usertype: UserType.Company,
            };

            register(newUser)
              .then(userResponse => {
                dispatch(hideLoader());
                setOrg({
                  departments: [{}, {}, {}, {}, {}, {}],
                });
                dispatch(setModal(Modals.None));
                const mail: Mail = {
                  to: [adminEmail ?? ''],
                  bcc: [Config.APP_ADMIN],
                  subject: 'Organisation profile created',
                  html:
                    '<p>Welcome ' +
                    org.organisation +
                    '</p>' +
                    '<p>Your organisation profile was created with the password: ' +
                    newPassword +
                    '.</p><p>Regards,<br />Nabantu Team',
                };

                sendMail(mail)
                  .then(() => {})
                  .catch(err => {
                    dispatch(hideLoader());
                    if (err?.data && err.data?.message) {
                      alert(err.data.message);
                    } else {
                      alert('Unable to complete this operation.');
                    }
                  });
              })
              .catch(err => {
                console.log(err);
                dispatch(hideLoader());
                if (err?.data && err.data?.message) {
                  alert(err.data.message);
                } else {
                  alert('Unable to complete this operation.');
                }
              });
          } else {
            dispatch(hideLoader());
            alert('This operation could not be completed at this time.');
          }
        })
        .catch(err => {
          console.log(err);
          dispatch(hideLoader());
          if (err?.data && err.data?.message) {
            alert(err.data.message);
          } else {
            alert('Unable to complete this operation.');
          }
        });
    }
  }, [dispatch, org, adminEmail]);

  const onSetDepartmentName = useCallback(
    (department: Department, departmentIndex: number, newName: string) => {
      const newDepartment: Department = { ...department, department: newName };

      const newOrg: Org = { ...org, departments: org.departments };
      newOrg.departments[departmentIndex] = newDepartment;
      setOrg(newOrg);
    },
    [org],
  );

  const onSetDepartmentHead = useCallback(
    (department: Department, departmentIndex: number, newName: string) => {
      const newDepartment: Department = {
        ...department,
        departmentHead: newName,
      };

      const newOrg: Org = { ...org, departments: org.departments };
      newOrg.departments[departmentIndex] = newDepartment;
      setOrg(newOrg);
    },
    [org],
  );

  return (
    <div
      className="modal modal-visible modal-backdrop2"
      id="editOrgModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add Organisation</h4>
          </div>
          <div className="modal-body">
            <form className="mt-1">
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder=""
                      value={adminEmail}
                      onChange={val => setAdminEmail(val.target.value)}
                    />
                    <label>Admin Email</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder=""
                      value={org.organisation}
                      onChange={val =>
                        setOrg({ ...org, organisation: val.target.value })
                      }
                    />
                    <label>Organisation</label>
                  </div>
                  {/* <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingInput'
                      placeholder=''
                      value={org.orgnumber}
                      onChange={(val) =>
                        setOrg({ ...org, orgnumber: val.target.value })
                      }
                    />
                    <label>Organisation Number</label>
                  </div> */}
                  {/* <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingInput'
                      placeholder=''
                      value={org.orgpaymentcode}
                      onChange={(val) =>
                        setOrg({ ...org, orgpaymentcode: val.target.value })
                      }
                    />
                    <label>Payment Code</label>
                  </div> */}
                </div>
                <div className="col-lg-8">
                  {org.departments?.map((department, index) => {
                    return (
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder=""
                              value={department.department}
                              onChange={val =>
                                onSetDepartmentName(
                                  department,
                                  index,
                                  val.target.value,
                                )
                              }
                            />
                            <label>Department</label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder=""
                              value={department.departmentHead}
                              onChange={val =>
                                onSetDepartmentHead(
                                  department,
                                  index,
                                  val.target.value,
                                )
                              }
                            />
                            <label>Department Head</label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-darkblue" onClick={onSave}>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditOrgModal;
