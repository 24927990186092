import Comp from "../../../components";

function Reports() {
  return (
    <>
      <div className="container-fluid h-100 d-flex bordered">
        <div className="row flex-grow-1 bg-white">
          <Comp.SideMenu></Comp.SideMenu>
          <div className="col page-content p-0">
            <Comp.NavBar></Comp.NavBar>
            <div className="p-4"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
