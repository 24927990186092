import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCoacheesForProgramme,
  getOrgProfile,
} from '../../../api/report.api';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { Flex, Mail, OrgProfile } from '../../../interfaces';
import { RootState } from '../../../redux/store';
import { hideLoader, showLoader } from '../../../redux/ui/uiSlice';
import { CoacheeTransactions } from '../../../interfaces/coachee-transactions';
import { TransactionDisplay } from '../../../interfaces/transaction';
import FileUploader from '../../../components/file-uploader';
import { Config } from '../../../config';
import { flexInsert } from '../../../api/flex.api';
import { createNotification } from '../../../api/comms.api';
import { NotificationType } from '../../../enums';
import { sendMail } from '../../../api/comms.api';

function PastCoachingDetails() {
  const sessionState = useSelector((state: RootState) => state.session);
  const [coacheeTransactions, setCoacheeTransactions] = useState<
    Array<CoacheeTransactions> | undefined
  >();

  const [orgProfile, setOrgProfile] = useState<OrgProfile | undefined>();
  const dispatch = useDispatch();

  const getSelectedCoaches = useCallback(() => {
    let total = 0;
    sessionState.selectedReportProgramme?.coachees.map((coachee) => {
      coachee.transactions.map((tx) => {
        if (tx.coachingSessions) {
          total +=
            tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.bookingDate
            ).length > 0
              ? 1
              : 0;
        }
      });
    });
    return total;
  }, [sessionState.selectedReportProgramme]);

  const getCompletedChemistryHours = useCallback(
    (coachProgrammeid: string | undefined) => {
      if (orgProfile !== undefined) {
        let total = 0;
        orgProfile?.transactions?.map((transaction) => {
          if (transaction.coachingSessions) {
            // if (
            //   (coachProgrammeid ?? '') !== '' &&
            //   coachProgrammeid === transaction.coachProgrammeid
            // ) {
            total += transaction.coachingSessions?.filter(
              (item) =>
                item.chemistry1 === true ||
                (item.chemistry2 === true &&
                  item.coachConfirmedCompleted &&
                  item.coacheeConfirmedCompleted)
            )?.length;
            // } else if ((coachProgrammeid ?? '') === '') {
            //   total += transaction.coachingSessions?.filter(
            //     (item) =>
            //       item.chemistry1 === false &&
            //       item.chemistry2 === false &&
            //       item.coachConfirmedCompleted &&
            //       item.coacheeConfirmedCompleted
            //   )?.length;
            // }
          }
        });
        return total;
      }
    },
    [orgProfile]
  );

  const getCompletedHours = useCallback(
    (coachProgrammeid: string | undefined) => {
      if (orgProfile !== undefined) {
        let total = 0;
        orgProfile?.transactions?.map((transaction) => {
          if (transaction.coachingSessions) {
            // if (
            //   (coachProgrammeid ?? '') !== '' &&
            //   coachProgrammeid === transaction.coachProgrammeid
            // ) {
            total += transaction.coachingSessions?.filter(
              (item) =>
                item.chemistry1 === false &&
                item.chemistry2 === false &&
                item.coachConfirmedCompleted &&
                item.coacheeConfirmedCompleted
            )?.length;
            // } else if ((coachProgrammeid ?? '') === '') {
            //   total += transaction.coachingSessions?.filter(
            //     (item) =>
            //       item.chemistry1 === false &&
            //       item.chemistry2 === false &&
            //       item.coachConfirmedCompleted &&
            //       item.coacheeConfirmedCompleted
            //   )?.length;
            // }
          }
        });
        return total;
      }
    },
    [orgProfile]
  );

  const onCertificateUploaded = (
    coachee: CoacheeTransactions,
    fileName: string
  ) => {
    const flex: Flex = {
      collection: 'library',
      model: {
        section: 'Certificates',
        title: 'Certificate',
        pages: 1,
        link: Config.API_SERVER.replace('/api', '/uploads/') + fileName,
        userid: coachee?.user?._id?.toString(),
      },
    };
    flexInsert(flex).then(() => {
      const message = 'Your coaching certificate was uploaded to your library.';
      const mail: Mail = {
        to: [coachee?.user?.email ?? ''],
        subject: 'Certificate Available',
        html: message,
      };
      sendMail(mail)
        .then(() => {})
        .catch(() => {});
      createNotification(
        Config.ADMIN_USER ?? '',
        coachee?.user?._id ?? '',
        'Your coaching certificate was uploaded to your library.',
        NotificationType.CertificateUpload,
        undefined
      ).then(() => {
        alert('Certificate uploaded successfully!');
      });
    });
  };

  useEffect(() => {
    if (orgProfile === undefined && sessionState.selectedOrg?._id !== '') {
      dispatch(showLoader());
      getOrgProfile(sessionState.selectedOrg?._id ?? '')
        .then((orgProfileResponse) => {
          dispatch(hideLoader());
          if (orgProfileResponse.status === 200) {
            setOrgProfile(orgProfileResponse.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert('Error: ' + JSON.stringify(err));
        });
    }
  }, [orgProfile, dispatch, sessionState]);

  useEffect(() => {
    if (coacheeTransactions === undefined) {
      dispatch(showLoader());
      getCoacheesForProgramme(sessionState.selectedProgramme?._id ?? '')
        .then((response) => {
          dispatch(hideLoader());
          console.log('response.status', response.status);
          console.log('response.data', response.data);
          if (response.status === 200) {
            setCoacheeTransactions(response.data);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [coacheeTransactions, dispatch, sessionState]);

  const getCompletedSessions = (transactions: Array<TransactionDisplay>) => {
    let total = 0;
    transactions?.map((tx) => {
      total +=
        tx.coachingSessions?.filter(
          (item) =>
            item.chemistry1 === false &&
            item.chemistry2 === false &&
            item.coachConfirmedCompleted &&
            item.coacheeConfirmedCompleted
        )?.length ?? 0;
    });
    return total;
  };

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row gx-0 align-items-center'>
              <div className='col page-heading heading-darkblue py-4 ps-3 border-bottom'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/coaching.svg'
                    className='heading-image'
                  />
                  <div className='col'>
                    <h1 className='my-0'>Past Coaching</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 mt-4'>
              <div className='n-card p-3 bg-fadedblue'>
                <div className='row align-items-center'>
                  <div className='col-2'>
                    <div className='n-card p-3 bg-darkblue text-center text-white'>
                      <div className='icon-avatar bg-white m-auto'>
                        <img
                          src='./images/svg/user-group.svg'
                          className='icon-darkblue'
                        />
                      </div>
                      <div className='mt-2 mb-2'>
                        {sessionState?.selectedProgramme?.department}
                      </div>
                      <div className='hrs val text-white'>
                        {sessionState?.selectedProgramme?.numberOfSessions}
                        <span className='hrs text-white'>hrs</span>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <table className='table tbl-no-border mb-0'>
                      <tbody>
                        <tr>
                          <td>Programme kick-off</td>
                          <td>
                            :
                            {sessionState.selectedProgramme?.createdAt !==
                            undefined
                              ? moment(
                                  new Date(
                                    sessionState.selectedProgramme?.createdAt
                                  )
                                ).calendar()
                              : ''}
                          </td>
                        </tr>
                        <tr>
                          <td>Programme completed on</td>
                          <td>: N/A</td>
                        </tr>
                        <tr>
                          <td>Programme type</td>
                          <td>
                            : {sessionState.selectedProgramme?.coachingType}
                          </td>
                        </tr>
                        <tr>
                          <td>NDA signed</td>
                          <td>: N/A</td>
                        </tr>
                        <tr>
                          <td>Chemistry session completed</td>
                          <td>
                            :{' '}
                            {getCompletedChemistryHours(
                              sessionState.selectedProgramme?._id
                            )}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Selected coaches</td>
                          <td>: {getSelectedCoaches()}</td>
                        </tr>
                        <tr>
                          <td>Coaching Sessions completed</td>
                          <td>
                            :{' '}
                            {getCompletedHours(
                              sessionState.selectedProgramme?._id
                            )}{' '}
                            hours
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <hr className='mb-2' />
            <h4 className='darkblue'>Coachees:</h4>
            <hr className='mt-2' />

            <table className='table align-middle'>
              <thead className='text-center'>
                <tr>
                  <th>Coachee</th>
                  <th>Completed sessions</th>
                  {/* <th>Three ways</th> */}
                  <th>Late cancellations</th>
                  <th>Selected coach</th>
                  <th>Certificate</th>
                </tr>
              </thead>
              <tbody className='text-center'>
                {coacheeTransactions?.map((coachee: CoacheeTransactions) => {
                  return (
                    <tr className='tbl-tr'>
                      <td className='text-start'>
                        <img
                          src='./images/avatar.png'
                          className='tbl-img d-inline mx-2'
                        />
                        {coachee.user.firstname + ' ' + coachee.user.lastname}
                      </td>
                      <td>
                        {getCompletedSessions(coachee?.transactions ?? [])} hrs
                      </td>
                      {/* <td>3/3</td> */}
                      <td>0</td>
                      <td>
                        {coachee?.transactions &&
                        coachee?.transactions?.length > 0
                          ? coachee?.transactions[0].coach?.firstname +
                            ' ' +
                            coachee?.transactions[0].coach?.lastname
                          : ''}
                      </td>
                      <td>
                        {getCompletedSessions(coachee?.transactions ?? []) ===
                        (sessionState.selectedReportProgramme
                          ?.numberOfSessions ?? 0) ? (
                          <FileUploader
                            label={'Upload Certificate'}
                            onFileUploaded={(fileName: string) => {
                              onCertificateUploaded(coachee, fileName);
                            }}
                          ></FileUploader>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastCoachingDetails;
