import { useSelector, useDispatch } from 'react-redux';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { RootState } from '../../../redux/store';
import { AppScreens, Rate } from '../../../enums/index';
import { Flex } from '../../../interfaces';
import { hideLoader, setScreen, showLoader } from '../../../redux/ui/uiSlice';
import { flexUpdate } from '../../../api/flex.api';
import { setSelectedCoach } from '../../../redux/session/sessionSlice';
import { useState } from 'react';
import EditCoachRates from '../../../modals/edit-coach-rates';

function CoachProfile() {
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const [editRatesVisible, setEditRatesVisible] = useState(false);

  const getNabatuRate = () => {
    switch (sessionState.selectedCoach?.rate) {
      case Rate.AssociateCoach:
        return 1000;
      case Rate.MidLevelCoach:
        return 1500;
      case Rate.ExecutiveCoach:
        return 2000;
      case Rate.MasterCoach:
        return 3000;
      default:
        return 0;
    }
  };

  const toggleBlock = () => {
    const block =
      (sessionState.selectedCoach?.blocked ?? false) === true ? false : true;
    const flex: Flex = {
      collection: 'users',
      _id: sessionState.selectedCoach?._id,
      model: {
        blocked: block,
      },
    };
    dispatch(showLoader());
    flexUpdate(flex)
      .then(() => {
        dispatch(hideLoader());
        dispatch(
          setSelectedCoach({ ...sessionState.selectedCoach, blocked: block }),
        );
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  };

  const onUpdateCoachRates = (personalRate?: number, nabantuRate?: Rate) => {
    setEditRatesVisible(false);
    const flex: Flex = {
      collection: 'users',
      _id: sessionState.selectedCoach?._id,
      model: {
        personalCoachingRate: personalRate ?? 0,
        rate: nabantuRate,
      },
    };
    dispatch(showLoader());
    flexUpdate(flex)
      .then(() => {
        dispatch(hideLoader());
        dispatch(
          setSelectedCoach({
            ...sessionState.selectedCoach,
            personalCoachingRate: personalRate ?? 0,
            rate: nabantuRate,
          }),
        );
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  };

  const onViewTimeSheets = () => {
    dispatch(setScreen(AppScreens.CoachTimesheets));
  };

  return (
    <>
      <div className="container-fluid h-100 d-flex">
        <div className="row flex-grow-1 bg-white">
          <SideMenu />
          <div className="col page-content p-0">
            <NavBar />
            <div className="p-5">
              <div className="row gx-0 align-items-center">
                <div className="col page-heading heading-darkblue py-3 ps-3 border-bottom">
                  <div className="row g-0 align-items-center">
                    <img
                      src="images/svg/coaching.svg"
                      className="heading-image"
                      alt=""
                    />
                    <div className="col">
                      <h1 className="my-0">Coach Profile</h1>
                      <div>Coach Data</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row gx-0 mt-3">
                <div className="col-lg-3 col-md-4 n-card p-3 text-center bg-medblue me-4">
                  <img
                    src="./images/avatar.png"
                    className="profile-img"
                    alt=""
                  />
                  <div className="title mb-0">
                    {sessionState.selectedCoach?.firstname}{' '}
                    {sessionState.selectedCoach?.lastname}
                  </div>
                  <div className="subtitle mt-0 mb-3">
                    {sessionState.selectedCoach?.location}
                  </div>
                  <div className="label">Gender</div>
                  <div className="value">
                    {sessionState.selectedCoach?.gender}
                  </div>
                  <div className="label">Race</div>
                  <div className="value">
                    {sessionState.selectedCoach?.race}
                  </div>
                  <div className="label">Date of birth</div>
                  <div className="value">
                    {sessionState.selectedCoach?.dateofbirth}
                  </div>
                  <div className="label">Phone Number</div>
                  <div className="value">
                    {sessionState.selectedCoach?.phonenumber}
                  </div>
                  <div className="label">Email Address</div>
                  <div className="value">
                    {sessionState.selectedCoach?.email}
                  </div>
                  <div className="label">Subscribed to app?</div>
                  <div className="value">Yes</div>
                  {/* <button type="button" className="btn btn-sml btn-white">
                  Give Subscription Discount
                </button> */}
                </div>
                <div className="col pe-0">
                  <h4 className="medblue">Speciality</h4>
                  {sessionState.selectedCoach?.coachingTypes?.map(item => {
                    return <p>{item}</p>;
                  })}
                  <hr />
                  <h4 className="medblue">Qualifications</h4>
                  {sessionState.selectedCoach?.qualifications?.map(item => {
                    return <p>{item}</p>;
                  })}
                  <hr />
                  <h4 className="medblue">Acreditation</h4>
                  <p>{sessionState.selectedCoach?.accreditation}</p>
                  <hr />
                  <h4 className="medblue">Coaching Hours</h4>
                  <p>{sessionState.selectedCoach?.hours}</p>
                  <hr />
                  <div className="row ps-2 align-items-center">
                    <div className="col-8 n-card bg-medblue p-3 text-white">
                      <div className="row">
                        <div className="col-8 border-end border-lightblue">
                          <h4 className="mb-0">Coach Rates</h4>
                          <div className="darkblue">
                            {sessionState.selectedCoach?.rate}
                          </div>
                          <div className="row mt-3">
                            <div className="col">
                              <div className="budget-label">
                                Nabantu App Rate
                              </div>
                              <div>R {getNabatuRate().toFixed(2)}/hr</div>
                            </div>
                            <div className="col border-start ">
                              <div className="budget-label">Personal Rate</div>
                              <div>
                                R{' '}
                                {sessionState.selectedCoach?.personalCoachingRate?.toFixed(
                                  2,
                                )}
                                /hr
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col text-center">
                              <button
                                onClick={() => setEditRatesVisible(true)}
                                type="button"
                                className="btn btn-sml btn-white mt-3">
                                Update info
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col text-center align-self-end">
                          <div>
                            <img src="images/icons/icon_billing.png" alt="" />
                          </div>
                          <button
                            onClick={onViewTimeSheets}
                            type="button"
                            className="btn btn-sml btn-white mt-3">
                            View Timesheets
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="tbl-tr p-3">
                        <h4 className="medblue mb-0">App status</h4>
                        <div>
                          {sessionState.selectedCoach?.blocked === true
                            ? 'Blocked'
                            : 'Active'}
                        </div>
                        <div className="text-center mt-2">
                          <button
                            onClick={() => toggleBlock()}
                            type="button"
                            className="btn btn-medblue">
                            Change
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {editRatesVisible ? (
        <EditCoachRates
          onUpdate={onUpdateCoachRates}
          onCancel={() => setEditRatesVisible(false)}></EditCoachRates>
      ) : null}
    </>
  );
}

export default CoachProfile;
