import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgProfile } from '../../../api/report.api';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { CoachProgrammeDisplay, OrgProfile } from '../../../interfaces';
import { RootState } from '../../../redux/store';
import { hideLoader, setScreen, showLoader } from '../../../redux/ui/uiSlice';
import moment from 'moment';
import { AppScreens } from '../../../enums';
import { setSelectedProgramme } from '../../../redux/session/sessionSlice';

function PastCoaching() {
  const sessionState = useSelector((state: RootState) => state.session);

  const [orgProfile, setOrgProfile] = useState<OrgProfile | undefined>();
  const dispatch = useDispatch();

  const getSelectedCoaches = useCallback(() => {
    let total = 0;
    sessionState.selectedReportProgramme?.coachees.map((coachee) => {
      coachee.transactions.map((tx) => {
        if (tx.coachingSessions) {
          total +=
            tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.bookingDate
            ).length > 0
              ? 1
              : 0;
        }
      });
    });
    return total;
  }, [sessionState.selectedReportProgramme]);

  const getCompletedChemistryHours = useCallback(
    (coachProgrammeid: string | undefined) => {
      if (orgProfile !== undefined) {
        let total = 0;
        orgProfile?.transactions?.map((transaction) => {
          if (transaction.coachingSessions) {
            // if (
            //   (coachProgrammeid ?? '') !== '' &&
            //   coachProgrammeid === transaction.coachProgrammeid
            // ) {
            total += transaction.coachingSessions?.filter(
              (item) =>
                item.chemistry1 === true ||
                (item.chemistry2 === true &&
                  item.coachConfirmedCompleted &&
                  item.coacheeConfirmedCompleted)
            )?.length;
            // } else if ((coachProgrammeid ?? '') === '') {
            //   total += transaction.coachingSessions?.filter(
            //     (item) =>
            //       item.chemistry1 === false &&
            //       item.chemistry2 === false &&
            //       item.coachConfirmedCompleted &&
            //       item.coacheeConfirmedCompleted
            //   )?.length;
            // }
          }
        });
        return total;
      }
    },
    [orgProfile]
  );

  const getCompletedHours = useCallback(
    (coachProgrammeid: string | undefined) => {
      if (orgProfile !== undefined) {
        let total = 0;
        orgProfile?.transactions?.map((transaction) => {
          if (transaction.coachingSessions) {
            // if (
            //   (coachProgrammeid ?? '') !== '' &&
            //   coachProgrammeid === transaction.coachProgrammeid
            // ) {
            total += transaction.coachingSessions?.filter(
              (item) =>
                item.chemistry1 === false &&
                item.chemistry2 === false &&
                item.coachConfirmedCompleted &&
                item.coacheeConfirmedCompleted
            )?.length;
            // } else if ((coachProgrammeid ?? '') === '') {
            //   total += transaction.coachingSessions?.filter(
            //     (item) =>
            //       item.chemistry1 === false &&
            //       item.chemistry2 === false &&
            //       item.coachConfirmedCompleted &&
            //       item.coacheeConfirmedCompleted
            //   )?.length;
            // }
          }
        });
        return total;
      }
    },
    [orgProfile]
  );

  const getTotalMoneySpent = useCallback(() => {
    if (orgProfile !== undefined) {
      let total = 0;
      orgProfile?.transactions?.map((transaction) => {
        const perSessionCost =
          (transaction.totalAmount ?? 0) / transaction.numberOfSessions;
        if (transaction.coachingSessions) {
          total +=
            transaction.coachingSessions?.filter(
              (item) =>
                item.chemistry1 === false &&
                item.chemistry2 === false &&
                item.coachConfirmedCompleted &&
                item.coacheeConfirmedCompleted
            )?.length * perSessionCost;
        }
      });
      return total;
    }
  }, [orgProfile]);

  const getHoursInProgress = useCallback(
    (coachProgrammeid: string | undefined) => {
      if (orgProfile !== undefined) {
        let total = 0;
        orgProfile?.transactions?.map((transaction) => {
          if (transaction.coachingSessions) {
            if (
              (coachProgrammeid ?? '') !== '' &&
              coachProgrammeid === transaction.coachProgrammeid
            ) {
              total +=
                transaction.numberOfSessions -
                transaction.coachingSessions?.filter(
                  (item) =>
                    item.chemistry1 === false &&
                    item.chemistry2 === false &&
                    item.coachConfirmedCompleted &&
                    item.coacheeConfirmedCompleted
                )?.length;
            } else if ((coachProgrammeid ?? '') === '') {
              total += transaction.coachingSessions?.filter(
                (item) =>
                  item.chemistry1 === false &&
                  item.chemistry2 === false &&
                  item.coachConfirmedCompleted &&
                  item.coacheeConfirmedCompleted
              )?.length;
            }
          }
        });
        return total;
      }
    },
    [orgProfile]
  );

  const getBalance = useCallback(
    (coachProgramme: CoachProgrammeDisplay) => {
      const completedHours = getCompletedHours(coachProgramme._id) ?? 0;
      return (
        (coachProgramme.numberOfSessions - completedHours) *
        (coachProgramme?.coachRate ?? 0)
      );
    },
    [getCompletedHours]
  );

  const getSpent = useCallback(
    (coachProgramme: CoachProgrammeDisplay) => {
      const completedHours = getCompletedHours(coachProgramme._id) ?? 0;
      return completedHours * (coachProgramme?.coachRate ?? 0);
    },
    [getCompletedHours]
  );

  useEffect(() => {
    if (orgProfile === undefined && sessionState.selectedOrg?._id !== '') {
      dispatch(showLoader());
      getOrgProfile(sessionState.selectedOrg?._id ?? '')
        .then((orgProfileResponse) => {
          dispatch(hideLoader());
          if (orgProfileResponse.status === 200) {
            setOrgProfile(orgProfileResponse.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert('Error: ' + JSON.stringify(err));
        });
    }
  }, [orgProfile, dispatch, sessionState]);

  const viewCoachees = (coachingProgramme: CoachProgrammeDisplay) => {
    dispatch(setSelectedProgramme(coachingProgramme));
    dispatch(setScreen(AppScreens.PastCoachingDetails));
  };

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row align-items-center mb-4'>
              <div className='col pb-4 border-bottom'>
                <div className='row align-items-center'>
                  <div className='col-1 text-center p-0'>
                    <img src='images/placeholder.png' className='client-logo' />
                  </div>
                  <div className='col-11'>
                    <h1 className='my-0'>
                      {sessionState.selectedOrg?.organisation}
                    </h1>
                    <div>Past Coaching</div>
                  </div>
                </div>
              </div>
            </div>
            {orgProfile?.coachingProgrammes?.map((coachingProgramme) => {
              return (
                <div className='row mt-3'>
                  <div className='col n-card p-3 bg-fadedblue'>
                    <div className='row align-items-center'>
                      <div className='col-3'>
                        <div className='n-card p-3 bg-darkblue text-center text-white'>
                          <div className='icon-avatar bg-white m-auto'>
                            <img
                              src='./images/svg/user-group.svg'
                              className='icon-darkblue'
                            />
                          </div>
                          <div className='mt-2 mb-2'>
                            {coachingProgramme.department}
                          </div>
                          <div className='hrs val text-white'>
                            {coachingProgramme.numberOfSessions}
                            <span className='hrs text-white'> hrs</span>
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <table className='table tbl-no-border mb-0'>
                          <tbody>
                            <tr>
                              <td>Programme kick-off</td>
                              <td>
                                :
                                {coachingProgramme?.createdAt !== undefined
                                  ? moment(
                                      new Date(coachingProgramme?.createdAt)
                                    ).calendar()
                                  : ''}
                              </td>
                            </tr>
                            <tr>
                              <td>Programme completed on</td>
                              <td>: </td>
                            </tr>
                            <tr>
                              <td>Programme type</td>
                              <td>: {coachingProgramme.coachingType}</td>
                            </tr>
                            <tr>
                              <td>NDA signed</td>
                              <td>: N/A</td>
                            </tr>
                            <tr>
                              <td>Chemistry session completed</td>
                              <td>
                                :{' '}
                                {getCompletedChemistryHours(
                                  coachingProgramme._id
                                )}{' '}
                              </td>
                            </tr>
                            <tr>
                              <td>Selected coaches</td>
                              <td>: {getSelectedCoaches()}</td>
                            </tr>
                            <tr>
                              <td>Coaching Sessions completed</td>
                              <td>
                                : {getCompletedHours(coachingProgramme._id)}{' '}
                                hours
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='n-card bg-fadedblue text-white h-100'>
                      <div className='row h-100'>
                        <div className='col text-center align-self-center'>
                          <div>
                            <img
                              src='images/svg/user-group.svg'
                              className='icon-60 icon-white'
                            />
                          </div>
                          <a
                            onClick={() => viewCoachees(coachingProgramme)}
                            className='btn btn-sml btn-brightblue mt-4'
                          >
                            View Coachees
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastCoaching;
