import { hideLoader, setModal, showLoader } from '../../redux/ui/uiSlice';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { sendMail } from '../../api/comms.api';
import { Mail } from '../../interfaces';
import { Modals } from '../../enums';
import { useState } from 'react';

function InviteCoacheeModal() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();

  const onOkClick = useCallback(() => {
    if (email === '' || name === '' || surname === '') {
      alert('Please complete all the fields.');
      return;
    }
    dispatch(showLoader());
    const mail: Mail = {
      to: [email ?? ''],
      subject: 'You are invited to the Nabantu App',
      html:
        '<p>Dear ' +
        name +
        '</p>' +
        '<p>You are invited to get coached on our Nabantu app. Please download our Nabantu app and register your profile. ' +
        '</p><p>Regards,<br />Nabantu Team',
    };

    sendMail(mail)
      .then(mailResponse => {
        dispatch(hideLoader());
        dispatch(setModal(Modals.None));
      })
      .catch(err => {
        dispatch(hideLoader());
        console.log(err);
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [dispatch, name, surname, email]);

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  return (
    <div
      className="modal modal-visible modal-backdrop2"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Invite a coachee</h4>
          </div>
          <div className="modal-body">
            <p>Add potential coachee information below.</p>
            <form className="mt-1">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput2"
                      placeholder=""
                      value={name}
                      onChange={val => setName(val.target.value)}
                    />
                    <label>Name</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput2"
                      placeholder=""
                      value={surname}
                      onChange={val => setSurname(val.target.value)}
                    />
                    <label>Surname</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput2"
                      placeholder=""
                      value={email}
                      onChange={val => setEmail(val.target.value)}
                    />
                    <label>Email</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-darkblue"
              data-dismiss="modal"
              onClick={onOkClick}>
              OK
            </button>
            <button
              type="button"
              className="btn btn-darkblue"
              data-dismiss="modal"
              onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteCoacheeModal;
