import { Modals } from '../../enums';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { setModal } from '../../redux/ui/uiSlice';
import { Org, Department } from '../../interfaces';
import { showLoader, hideLoader } from '../../redux/ui/uiSlice';
import { flexUpdate } from '../../api';
import { Flex } from '../../interfaces';
import { RootState } from '../../redux/store';
import { setOrg, setSelectedOrg } from '../../redux/session/sessionSlice';

function EditTeamModal() {
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const [department, setDepartment] = useState('');
  const [departmentHead, setDepartmentHead] = useState('');

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  const onSave = useCallback(() => {
    if (department === '' || departmentHead === '') {
      alert('Please complete all fields.');
    } else {
      const newDepartments: Department[] = [];
      const newDepartment: Department = {
        department,
        departmentHead,
      };
      let teamAdded = false;
      sessionState.org?.departments?.map((existingDepartment) => {
        if ((existingDepartment.department ?? '') !== '') {
          newDepartments.push(existingDepartment);
        } else if (!teamAdded) {
          newDepartments.push(newDepartment);
          teamAdded = true;
        }
      });
      const newOrg: Org = { ...sessionState.org, departments: newDepartments };
      dispatch(showLoader());
      const flex: Flex = {
        collection: 'org',
        model: newOrg,
        _id: sessionState.org?._id,
      };
      flexUpdate(flex)
        .then(() => {
          dispatch(setOrg(newOrg));
          dispatch(setSelectedOrg(newOrg));
          dispatch(hideLoader());
          dispatch(setModal(Modals.None));
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(JSON.stringify(err));
        });
    }
  }, [dispatch, sessionState, department, departmentHead]);

  return (
    <div
      className='modal modal-visible modal-backdrop2'
      id='editOrgModal'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>Add Team</h4>
          </div>
          <div className='modal-body'>
            <form className='mt-1'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingInput'
                      placeholder=''
                      value={department}
                      onChange={(val) => setDepartment(val.target.value)}
                    />
                    <label>Department</label>
                  </div>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingInput'
                      placeholder=''
                      value={departmentHead}
                      onChange={(val) => setDepartmentHead(val.target.value)}
                    />
                    <label>Department Head</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-darkblue' onClick={onSave}>
              Save
            </button>
            <button
              type='button'
              className='btn btn-secondary'
              data-dismiss='modal'
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTeamModal;
