import { CoachingType, Modals, NotificationType } from '../../enums';

import { useCallback, useEffect, useRef, useState } from 'react';
import { hideLoader, setModal, showLoader } from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  CoachProgramme,
  Flex,
  Notification,
  Org,
  User,
} from '../../interfaces';
import { Transaction } from '../../interfaces/transaction';
import { flexGetAll, flexGetOne, flexUpdate, flexDelete } from '../../api';
import { createNotification } from '../../api/comms.api';
import { getProfilePicture } from '../../utils';

function CoachSelectionNotificationModal() {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const notification: Notification = uiState.modalData ?? {};
  const [coachingProgramme, setCoachingProgramme] = useState<CoachProgramme>();
  const [transaction, setTransaction] = useState<Transaction>();
  const [coach, setCoach] = useState<User>();
  const [user, setUser] = useState<User>();
  const [org, setOrg] = useState<Org>();
  const [departmentHead, setDepartmentHead] = useState('');
  const [coachingType, setCoachingType] = useState<CoachingType | undefined>();
  const [numberOfSessions, setNumberOfSessions] = useState<
    string | undefined
  >();
  const [programmeRate, setProgrammeRate] = useState<string | undefined>();
  const [coachPersonalRate, setCoachPersonalRate] = useState<
    string | undefined
  >();
  const [privateCoaching, setPrivateCoaching] = useState(false);
  const confirmPressed = useRef(false);

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  useEffect(() => {
    if (transaction === undefined) {
      dispatch(showLoader());
      flexGetOne('transactions', notification.transactionid?.toString() ?? '')
        .then((transactionResponse) => {
          const transaction: Transaction = transactionResponse.data;
          flexGetOne('users', transaction.userid?.toString() ?? '').then(
            (userResponse) => {
              const user: User = userResponse.data;
              flexGetOne('users', transaction.coachid?.toString() ?? '').then(
                (coachResponse) => {
                  const flex: Flex = {
                    collection: 'coaching-programme',
                    query: {
                      orgCode: transaction.orgcode,
                    },
                  };
                  if ((transaction.orgcode ?? '') !== '') {
                    flexGetAll(flex).then((coachProgrammeResponse) => {
                      const coachingProgramme: CoachProgramme =
                        coachProgrammeResponse.data[0];
                      flexGetOne('org', coachingProgramme.orgid ?? '').then(
                        (orgResponse) => {
                          dispatch(hideLoader());
                          setTransaction(transaction);
                          setCoach(coachResponse.data);
                          setOrg(orgResponse.data);
                          if (orgResponse.data) {
                            const tempOrg: Org = orgResponse.data;

                            tempOrg?.departments?.map((dept) => {
                              if (dept.department === user.departmentName)
                                setDepartmentHead(dept.departmentHead ?? '');
                            });
                          }
                          setUser(userResponse.data);
                          setCoachingProgramme(coachingProgramme);
                          setCoachingType(coachingProgramme.coachingType);
                          setNumberOfSessions(
                            coachingProgramme.numberOfSessions?.toString()
                          );
                          setProgrammeRate(
                            coachingProgramme.coachingRate?.toString()
                          );
                          setCoachPersonalRate(
                            transaction.coachAmount?.toString()
                          );
                        }
                      );
                    });
                  } else {
                    dispatch(hideLoader());
                    setTransaction(transaction);
                    setPrivateCoaching(true);
                    setCoach(coachResponse.data);
                    setUser(userResponse.data);
                    setCoachingProgramme(coachingProgramme);
                    // setCoachingType(coachingProgramme.coachingType);
                    setNumberOfSessions(
                      transaction.numberOfSessions?.toString()
                    );
                    setProgrammeRate((transaction.coachAmount ?? 0).toFixed(2));
                    setCoachPersonalRate(transaction.coachAmount?.toString());
                  }
                }
              );
            }
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(hideLoader());
          if (err?.data && err.data?.message) {
            alert(err.data.message);
          } else {
            alert('Unable to complete this operation.');
          }
        });
    }
  }, [dispatch, notification, transaction, coachingProgramme]);

  const onConfirm = useCallback(() => {
    if (!confirmPressed.current) {
      confirmPressed.current = true;

      const newProgramme: CoachProgramme = {
        ...coachingProgramme,
        coachRate: Number(coachPersonalRate ?? '0'),
        coachingRate: Number(programmeRate ?? '0'),
        numberOfSessions: Number(numberOfSessions ?? '0'),
        coachingType: coachingType ?? CoachingType.SalesCoaching,
        coachees: coachingProgramme?.coachees ?? [],
        orgid: coachingProgramme?.orgid ?? '',
        department: coachingProgramme?.department ?? '',
        departmentHead: coachingProgramme?.departmentHead ?? '',
        programmeName: coachingProgramme?.programmeName ?? '',
      };
      console.log('newProgramme', newProgramme);
      const flexProgramme: Flex = {
        collection: 'coaching-programme',
        model: newProgramme,
        _id: coachingProgramme?._id,
      };
      const flexTrans: Flex = {
        collection: 'transactions',
        model: {
          ...transaction,
          approved: true,
        },
        _id: transaction?._id ?? '',
      };
      flexUpdate(flexProgramme).then(() => {
        flexUpdate(flexTrans).then(() => {
          const flexNotification: Flex = {
            collection: 'notifications',
            _id: notification._id ?? '',
          };
          flexDelete(flexNotification).then(() => {
            /* ES: Temporary disable this message - can be causing the duplicated coaching messages
            const message =
              user?.firstname +
              ' ' +
              user?.lastname +
              ' would like to schedule a coaching session with you on ' +
              transaction?.bookingDate +
              ' @ ' +
              transaction?.bookingTimeSlot?.startTime +
              ' - ' +
              transaction?.bookingTimeSlot?.endTime;
            createNotification(
              user?._id ?? '',
              coach?._id ?? '',
              message,
              NotificationType.CoachingInvite,
              transaction?._id
            ).then(() => {
              dispatch(setModal(Modals.None));
            });
            */
          });
        });
      });
    }
  }, [
    notification,
    coachingProgramme,
    programmeRate,
    coachPersonalRate,
    numberOfSessions,
    coachingType,
    // coach?._id,
    transaction,
    // user,
    // dispatch,
  ]);

  // const onConfirm = useCallback(() => {
  //   if (coachingProgramme) {
  //     // eslint-disable-next-line no-restricted-globals
  //     if (confirm('Are you sure you want to confirm this coach selection?')) {
  //       const newCoachees: Array<Coachee> = [
  //         ...coachingProgramme.coachees.filter(
  //           item =>
  //             item.email?.toUpperCase().trim() !==
  //             user?.email?.toUpperCase().trim(),
  //         ),
  //       ];
  //       const newCoachee: Coachee = {
  //         firstname: user?.firstname,
  //         email: user?.email,
  //         numberOfSessions: Number(numberOfSessions ?? '1'),
  //         coachingType: coachingType,
  //         coachingRate: Number(programmeRate),
  //         coachRate: Number(coachPersonalRate),
  //       };
  //       newCoachees.push(newCoachee);

  //       const newProgramme: CoachProgramme = {
  //         ...coachingProgramme,
  //         coachees: newCoachees,
  //       };

  //       const flexProgramme: Flex = {
  //         collection: 'coaching-programme',
  //         model: newProgramme,
  //         _id: coachingProgramme._id,
  //       };
  //       dispatch(showLoader());
  //       flexUpdate(flexProgramme)
  //         .then(updateResponse => {
  //           if (updateResponse.status === 200) {
  //             const flexTrans: Flex = {
  //               collection: 'transactions',
  //               model: {
  //                 ...transaction,
  //                 transactionStatus: 'Processed',
  //                 approved: true,
  //               },
  //               _id: transaction?._id ?? '',
  //             };
  //             flexUpdate(flexTrans)
  //               .then(updateTransResponse => {
  //                 dispatch(hideLoader());
  //                 if (updateTransResponse.status === 200) {
  //                   dispatch(setModal(Modals.None));
  //                 } else {
  //                   alert('Unable to complete this transaction.');
  //                 }
  //               })
  //               .catch(err => {
  //                 console.log(err);
  //                 dispatch(hideLoader());
  //                 if (err?.data && err.data?.message) {
  //                   alert(err.data.message);
  //                 } else {
  //                   alert('Unable to complete this operation.');
  //                 }
  //               });
  //           }
  //         })
  //         .catch(err => {
  //           console.log(err);
  //           dispatch(hideLoader());
  //           if (err?.data && err.data?.message) {
  //             alert(err.data.message);
  //           } else {
  //             alert('Unable to complete this operation.');
  //           }
  //         });
  //     }
  //   }
  // }, [
  //   coachPersonalRate,
  //   numberOfSessions,
  //   coachingProgramme,
  //   coachingType,
  //   dispatch,
  //   programmeRate,
  //   user,
  //   transaction,
  // ]);

  return (
    <div
      className='modal modal-visible modal-backdrop2'
      id='CoachSelectionNotificationModal'
      role='dialog'
      aria-labelledby='CoachSelectionNotificationModal'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>Coach Selection Notification</h4>
            <button
              type='button'
              className='btn btn-darkblue close-btn'
              data-dismiss='modal'
              aria-label='Close'
              onClick={() => onClose()}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-lg-12'>
                <p>
                  Coachee has selected their coach for a programme review the
                  information below and confirm
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-3'>Coachee</div>
              <div className='col-lg-3'>
                : {user?.firstname} {user?.lastname}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-3'>Session Type</div>
              <div className='col-lg-6'>: {transaction?.sessionType}</div>
            </div>
            <div className='row'>
              <div className='col-lg-3'>Session Date</div>
              <div className='col-lg-6'>
                : {transaction?.bookingDate}{' '}
                {transaction?.bookingTimeSlot?.startTime} -{' '}
                {transaction?.bookingTimeSlot?.endTime}
              </div>
            </div>
            {!privateCoaching ? (
              <>
                <div className='row'>
                  <div className='col-lg-3'>Organisation</div>
                  <div className='col-lg-3'>: {org?.organisation}</div>
                </div>
                <div className='row'>
                  <div className='col-lg-3'>Department</div>
                  <div className='col-lg-3'>: {user?.departmentName}</div>
                </div>
                <div className='row'>
                  <div className='col-lg-3'>Line Manager</div>
                  <div className='col-lg-3'>: {departmentHead}</div>
                </div>
              </>
            ) : null}
            <br />
            <div className='row'>
              <div className='col-lg-5 center'>
                <img
                  className='bigProfilePic'
                  src={getProfilePicture(user)}
                  alt=''
                />
                <br />
                <h3>
                  {' '}
                  {user?.firstname} {user?.lastname}
                </h3>
              </div>
              <div className='col-lg-2'>
                <img
                  style={{ height: 70 }}
                  src='/images/arrow-right.png'
                  // src={
                  //   Config.API_SERVER.replace('/api', '') +
                  //   '/' +
                  //   coach?.profilepicture?.path
                  // }
                  alt=''
                />
              </div>
              <div className='col-lg-5 center'>
                <img
                  className='bigProfilePic'
                  src={getProfilePicture(coach)}
                  // src={
                  //   Config.API_SERVER.replace('/api', '') +
                  //   '/' +
                  //   coach?.profilepicture?.path
                  // }
                  alt=''
                />
                <br />
                <h3>
                  {' '}
                  {coach?.firstname} {coach?.lastname}
                </h3>
              </div>
            </div>
            <br />
            <form className='mt-1'>
              <div className='row'>
                <div className='col-lg-12'>
                  <strong>Coaching programme details</strong>
                  <br />
                  you can manually edit programme details if needed
                </div>

                <div className='row' style={{ paddingTop: 20 }}>
                  {!privateCoaching ? (
                    <div className='col-lg-4'>
                      <div className='form-floating mb-3'>
                        <select
                          className='form-control'
                          id='floatingInput'
                          value={coachingType}
                          onChange={(val) =>
                            setCoachingType(val.target.value as CoachingType)
                          }
                        >
                          <option value=''></option>
                          <option value={CoachingType.GroupTeamCoaching}>
                            {CoachingType.GroupTeamCoaching}
                          </option>
                          <option value={CoachingType.StressResilience}>
                            {CoachingType.StressResilience}
                          </option>
                          <option value={CoachingType.ConflictCoaching}>
                            {CoachingType.ConflictCoaching}
                          </option>
                          <option value={CoachingType.EntrepreneurCoaching}>
                            {CoachingType.EntrepreneurCoaching}
                          </option>
                          <option value={CoachingType.SalesCoaching}>
                            {CoachingType.SalesCoaching}
                          </option>
                          <option value={CoachingType.SomaticBodyCoaching}>
                            {CoachingType.SomaticBodyCoaching}
                          </option>
                          <option value={CoachingType.OntologicalCoaching}>
                            {CoachingType.OntologicalCoaching}
                          </option>
                          <option value={CoachingType.LeadershipStrategy}>
                            {CoachingType.LeadershipStrategy}
                          </option>
                          <option value={CoachingType.LeadershipDevelopment}>
                            {CoachingType.LeadershipDevelopment}
                          </option>
                          <option value={CoachingType.ExecutiveCoaching}>
                            {CoachingType.ExecutiveCoaching}
                          </option>
                          <option value={CoachingType.LeadingChange}>
                            {CoachingType.LeadingChange}
                          </option>
                          <option value={CoachingType.LeaderAsCoach}>
                            {CoachingType.LeaderAsCoach}
                          </option>
                          <option
                            value={CoachingType.LeadershipBrandReputation}
                          >
                            {CoachingType.LeadershipBrandReputation}
                          </option>
                          <option value={CoachingType.LanguageForLeaders}>
                            {CoachingType.LanguageForLeaders}
                          </option>
                          <option value={CoachingType.PerceptionManagement}>
                            {CoachingType.PerceptionManagement}
                          </option>
                          <option
                            value={
                              CoachingType.EmotionalLearningForBetterLeadership
                            }
                          >
                            {CoachingType.EmotionalLearningForBetterLeadership}
                          </option>
                        </select>
                        <label>Programme Type</label>
                      </div>
                    </div>
                  ) : null}
                  <div className='col-lg-4'>
                    <div className='form-floating mb-3'>
                      <input
                        min={1}
                        max={160}
                        maxLength={3}
                        type='number'
                        className='form-control'
                        id='floatingInput'
                        value={numberOfSessions}
                        onChange={(val) =>
                          setNumberOfSessions(val.target.value)
                        }
                      />
                      <label>Hours</label>
                    </div>
                  </div>
                </div>
                <strong>Coaching rates</strong>
                <div className='row'>
                  {!privateCoaching ? (
                    <div className='col-lg-4'>
                      <div className='form-floating mb-3'>
                        <input
                          min={1}
                          max={160}
                          maxLength={3}
                          type='number'
                          className='form-control'
                          id='floatingInput'
                          value={programmeRate}
                          onChange={(val) => setProgrammeRate(val.target.value)}
                        />
                        <label>Programme rate per hour</label>
                      </div>
                    </div>
                  ) : null}
                  <div className='col-lg-4'>
                    <div className='form-floating mb-3'>
                      <input
                        min={1}
                        max={160}
                        maxLength={3}
                        type='number'
                        className='form-control'
                        id='floatingInput'
                        value={coachPersonalRate}
                        onChange={(val) =>
                          setCoachPersonalRate(val.target.value)
                        }
                      />
                      <label>Coach personal rate</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-darkblue'
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoachSelectionNotificationModal;
