import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { OrgProfile } from '../../../interfaces';
import { RootState } from '../../../redux/store';
import {
  hideLoader,
  setModal,
  setModalData,
  showLoader,
} from '../../../redux/ui/uiSlice';
import {
  getCoacheesForProgramme,
  getOrgProfile,
} from '../../../api/report.api';
import { CoacheeTransactions } from '../../../interfaces/coachee-transactions';
import { Modals } from '../../../enums';
import { AttendanceFilter } from '../../../interfaces/attendance-filter';
import AttendanceReportFilter from '../../../modals/attendance-report-filter';
import { ReportViewerProps } from '../../../modals/report-viewer';

export interface Report {
  coachee: string;
  date: string;
  hours: string;
  coach: string;
}

function CoachingAttendanceReport() {
  const sessionState = useSelector((state: RootState) => state.session);
  const [orgProfile, setOrgProfile] = useState<OrgProfile | undefined>();
  const dispatch = useDispatch();
  const [coacheeTransactions, setCoacheeTransactions] = useState<
    Array<CoacheeTransactions> | undefined
  >();
  const [filter, setFilter] = useState<AttendanceFilter | undefined>();
  const [filterVisible, setFilterVisible] = useState(false);
  const [report, setReport] = useState<Array<Report> | undefined>();
  const isLoaded = useRef(false);

  const getCoachingHours = useCallback(() => {
    let total = 0;
    report?.map((report) => {
      total += Number(report.hours);
    });
    return total;
  }, [report]);

  useEffect(() => {
    if (orgProfile === undefined && sessionState.selectedOrg?._id !== '') {
      dispatch(showLoader());
      getOrgProfile(sessionState.selectedOrg?._id ?? '')
        .then((orgProfileResponse) => {
          dispatch(hideLoader());
          if (orgProfileResponse.status === 200) {
            setOrgProfile(orgProfileResponse.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert('Error: ' + JSON.stringify(err));
        });
    }
  }, [orgProfile, dispatch, sessionState]);

  useEffect(() => {
    if (coacheeTransactions === undefined) {
      dispatch(showLoader());

      getCoacheesForProgramme(sessionState.selectedProgramme?._id ?? '')
        .then((response) => {
          dispatch(hideLoader());
          if (response.status === 200) {
            setCoacheeTransactions(response.data);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [coacheeTransactions, dispatch, sessionState]);

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      setFilterVisible(true);
    }
  }, [filter, filterVisible]);

  const onFilterApplied = useCallback(
    (
      fromDate?: string,
      toDate?: string,
      race?: string,
      gender?: string,
      coachees?: Array<CoacheeTransactions>
    ) => {
      setReport(undefined);
      setFilterVisible(false);
      setFilter({ fromDate, toDate, race, gender, coachees });
    },
    []
  );

  useEffect(() => {
    if (report === undefined && filter !== undefined) {
      const reportData: Array<Report> = [];
      const fromDate = new Date(filter.fromDate ?? '');
      const toDate = new Date(filter.toDate ?? '');
      coacheeTransactions?.map((coachee) => {
        coachee?.transactions?.map((transaction) => {
          transaction.coachingSessions
            ?.filter(
              (item) =>
                item.chemistry1 === false &&
                item.chemistry2 === false &&
                item.coachConfirmedCompleted &&
                item.coacheeConfirmedCompleted
            )
            ?.map((session) => {
              const bookingDate = new Date(session.bookingDate ?? '');
              if (bookingDate >= fromDate && bookingDate <= toDate) {
                let include = true;

                if ((filter?.race ?? '') !== '') {
                  if (filter.race !== coachee?.user?.race) {
                    include = false;
                  }
                }

                if ((filter?.gender ?? '') !== '') {
                  if (filter.gender !== coachee?.user?.gender) {
                    include = false;
                  }
                }

                if (include) {
                  const newReport: Report = {
                    coachee:
                      coachee?.user?.firstname + ' ' + coachee?.user?.lastname,
                    coach:
                      transaction?.coach?.firstname +
                      ' ' +
                      transaction?.coach?.lastname,
                    date: session.bookingDate ?? '',
                    hours: '1',
                  };
                  reportData.push(newReport);
                }
              }
              return '';
            });
          return '';
        });
        return '';
      });
      setReport(reportData);
    }
  }, [report, filter, coacheeTransactions]);

  const onReport = useCallback(() => {
    const reportParams: ReportViewerProps = {
      report: 'Attendance Report',
      data: report,
    };
    dispatch(setModalData(reportParams));
    dispatch(setModal(Modals.ReportViewer));
  }, [dispatch, report]);

  return (
    <>
      <div className='container-fluid h-100 d-flex'>
        <div className='row flex-grow-1 bg-white'>
          <SideMenu />
          <div className='col page-content p-0'>
            <NavBar />
            <div className='p-5'>
              <div className='row gx-0 align-items-center'>
                <div className='col page-heading heading-brightblue py-3 ps-3 border-bottom'>
                  <div className='row'>
                    <div className='col'>
                      <h1 className='my-0'>Coaching Attendance Report</h1>
                      <hr />
                    </div>
                    <div className='row align-items-center'>
                      <div className='col'>
                        {filter !== undefined ? (
                          <table className='table tbl-no-border mb-0'>
                            <tbody>
                              <tr>
                                <td>Report Duration</td>
                                <td>
                                  : {filter.fromDate} - {filter.toDate}
                                </td>
                              </tr>
                              <tr>
                                <td>Coaching type</td>
                                <td>: Leadership Coaching</td>
                              </tr>
                              <tr>
                                <td>Coaching Hours</td>
                                <td>: {getCoachingHours()} hrs</td>
                              </tr>
                              <tr>
                                <td>Outstanding Coaching hours</td>
                                <td>
                                  :{' '}
                                  {
                                    sessionState.selectedReportProgramme
                                      ?.numberOfSessions
                                  }{' '}
                                  hrs
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                      <div className='col-3 text-end'>
                        <a
                          className='btn btn-sml btn-brightblue mb-4'
                          onClick={() => setFilterVisible(true)}
                        >
                          Edit Filter
                        </a>
                        <br />
                        <a
                          className='btn btn-sml btn-brightblue mb-4'
                          onClick={onReport}
                        >
                          Download report
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-3'>
                <table className='table align-middle'>
                  <thead className='text-center'>
                    <tr>
                      <th>Coachees</th>
                      <th>Date</th>
                      <th>Session hours</th>
                      <th>Coach</th>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    {report?.map((report) => {
                      return (
                        <tr className='tbl-tr'>
                          <td className='text-start'>{report.coachee}</td>
                          <td>{report.date}</td>
                          <td>{report.hours}</td>
                          <td>{report.coach}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {report?.length === 0 ? <p>No data available.</p> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {filterVisible ? (
        <AttendanceReportFilter
          onFilter={onFilterApplied}
          onCancel={() => setFilterVisible(false)}
        ></AttendanceReportFilter>
      ) : null}
    </>
  );
}

export default CoachingAttendanceReport;
