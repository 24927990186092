import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import {
  showLoader,
  hideLoader,
  showMessage,
  setModal,
} from '../../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';
import { User, Flex, Mail } from '../../../interfaces';
import { useCallback, useState } from 'react';
import { flexUpdate } from '../../../api';
import { sendMail } from '../../../api/comms.api';
import { Modals } from '../../../enums';

function ApplicationDetails() {
  const sessionState = useSelector((state: RootState) => state.session);
  const [user, setUser] = useState(sessionState.selectedUser);
  const dispatch = useDispatch();

  const save = useCallback(
    (updatedUser: User) => {
      dispatch(showLoader());
      const flex: Flex = {
        collection: 'users',
        model: updatedUser,
        _id: user?._id,
      };
      flexUpdate(flex)
        .then(response => {
          dispatch(hideLoader());
          if (response.status === 200) {
            setUser(updatedUser);
            // if (updatedUser.accepted) {
            //   const mail: Mail = {
            //     to: [updatedUser.email ?? ''],
            //     subject: 'Nabantu Coach Profile Approved',
            //     html:
            //       '<p>Hi ' +
            //       updatedUser.firstname +
            //       ',</p><p>Your coaching profile was approved. Please login to the app to update your subscription payment.</p><p>Regards,<br />Nabantu Team',
            //   };

            //   sendMail(mail)
            //     .then(() => {
            //       dispatch(
            //         showMessage({
            //           title: '',
            //           message:
            //             updatedUser.firstname +
            //             ' ' +
            //             updatedUser.lastname +
            //             ' has been notified and will be automatically added to the app when he/she subscribes.',
            //         }),
            //       );
            //     })
            //     .catch(err => {
            //       console.log(err);
            //       dispatch(hideLoader());
            //       if (err?.data && err.data?.message) {
            //         alert(err.data.message);
            //       } else {
            //         alert('Unable to complete this operation.');
            //       }
            //     });
            // }
          } else {
            alert('This operation could not be completed at this time.');
          }
        })
        .catch(err => {
          dispatch(hideLoader());
          if (err?.data && err.data?.message) {
            alert(err.data.message);
          } else {
            alert('Unable to complete this operation.');
          }
        });
    },
    [dispatch, user],
  );

  const onConfirmQualification = useCallback(() => {
    const newUser: User = { ...user, qualificationsConfirmed: new Date() };
    save(newUser);
  }, [save, user]);

  const onConfirmAccreditation = useCallback(() => {
    const newUser: User = { ...user, accreditationConfirmed: new Date() };
    save(newUser);
  }, [save, user]);

  const onAcceptCoach = useCallback(() => {
    setTimeout(() => {
      dispatch(setModal(Modals.AcceptCoach));
    }, 500);
  }, [dispatch]);

  return (
    <div className="container-fluid h-100 d-flex">
      <div className="row flex-grow-1 bg-white">
        <SideMenu />
        <div className="col page-content p-0">
          <NavBar />
          <div className="p-5">
            <div className="row gx-0 align-items-center border-bottom">
              <h1>Coach Application</h1>
            </div>

            <div className="row gx-0 mt-3">
              <div className="col-lg-3 col-md-4 n-card p-3 text-center bg-medblue me-4">
                <img src="./images/avatar.png" className="profile-img" alt="" />
                <div className="title mb-0">
                  {sessionState.selectedUser?.firstname}{' '}
                  {sessionState.selectedUser?.lastname}
                </div>
                <div className="subtitle mt-0 mb-3">
                  {sessionState.selectedUser?.location}
                </div>
                <div className="label">Gender</div>
                <div className="value">{sessionState.selectedUser?.gender}</div>
                <div className="label">Race</div>
                <div className="value">{sessionState.selectedUser?.race}</div>
                <div className="label">Date of birth</div>
                <div className="value">
                  {sessionState.selectedUser?.dateofbirth}
                </div>
                <div className="label">Phone Number</div>
                <div className="value">
                  {sessionState.selectedUser?.phonenumber}
                </div>
                <div className="label">Email Address</div>
                <div className="value">{sessionState.selectedUser?.email}</div>
                <div className="label">Subscribed to app?</div>
                <div className="value">No</div>
                {/* <button type='button' className='btn btn-sml btn-white'>
                  Give Subscription Discount
                </button> */}
              </div>
              <div className="col pe-0">
                <h4 className="medblue">Speciality</h4>
                {sessionState.selectedUser?.coachingTypes?.map(item => {
                  return (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label className="form-check-label">{item}</label>
                    </div>
                  );
                })}
                <hr />
                <h4 className="medblue">Qualifications</h4>
                {sessionState.selectedUser?.qualifications?.map(item => {
                  return (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label className="form-check-label">{item}</label>
                    </div>
                  );
                })}

                {!user?.qualificationsConfirmed ? (
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-sml btn-darkblue mt-2"
                      onClick={() => onConfirmQualification()}>
                      Confirm Information
                    </button>
                  </div>
                ) : null}
                <hr />
                <h4 className="medblue">Acreditation</h4>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">
                    {sessionState.selectedUser?.accreditation}
                  </label>
                </div>
                {!user?.accreditationConfirmed ? (
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-sml btn-darkblue mt-2"
                      onClick={() => onConfirmAccreditation()}>
                      Confirm Information
                    </button>
                  </div>
                ) : null}
                <hr />
                <h4 className="medblue">Coaching Hours</h4>
                <p>{sessionState.selectedUser?.hours ?? 0} hrs</p>
                <hr />
                <h4 className="medblue">Coach Rate</h4>
                <p>R {sessionState.selectedUser?.personalCoachingRate} p/h</p>
                <hr />
                <h4 className="medblue">Coach Level</h4>
                <p>{sessionState.selectedUser?.rate}</p>
                <hr />

                {!user?.accepted &&
                user?.accreditationConfirmed &&
                user?.qualificationsConfirmed ? (
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-medblue"
                      onClick={() => onAcceptCoach()}>
                      Add to app
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationDetails;
