import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { ReportViewerProps } from '../../modals/report-viewer';
import { Report } from '../../pages/admin/coaching-attendance-report';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: 20,
  },
  col: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    width: 150,
    fontSize: 12,
  },
  heading: {
    margin: 10,
    padding: 10,
    fontSize: 18,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
});

interface AttendanceReportProps {
  params: ReportViewerProps;
}

const AttendanceReport = ({ params }: AttendanceReportProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.heading}>Attendance Report</Text>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.bold}>Coachee</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Date</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Session Hours</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.bold}>Coach</Text>
            </View>
          </View>
          {params.data?.map((report: Report) => {
            return (
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text>{report.coachee}</Text>
                </View>
                <View style={styles.col}>
                  <Text>{report.date}</Text>
                </View>
                <View style={styles.col}>
                  <Text>{report.hours}</Text>
                </View>
                <View style={styles.col}>
                  <Text>{report.coach}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default AttendanceReport;
