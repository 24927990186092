import React, { useState, useCallback, useEffect } from 'react';
import { CoachProgrammeDisplay, Flex, User } from '../../../interfaces';
import { AppScreens, Modals, Rate, UserType } from '../../../enums';
import Comp from '../../../components';
import {
  showLoader,
  hideLoader,
  setModal,
  setModalData,
  setScreen,
} from '../../../redux/ui/uiSlice';
import { useDispatch } from 'react-redux';
import { flexDelete, flexGetAll, flexGetOne, flexUpdate } from '../../../api';
import ConfirmModal from '../../../modals/confirm-modal';
import { setSelectedCoach } from '../../../redux/session/sessionSlice';
import { getProfilePicture } from '../../../utils';

function Coaches() {
  const [coaches, setCoaches] = useState<Array<User> | undefined>(undefined);
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const onSelectToDelete = useCallback(
    (coach: User) => {
      setSelectedUser(coach);
      setConfirmModalVisible(true);
    },
    [setSelectedUser, setConfirmModalVisible]
  );

  const onConfirmDeleteOption = useCallback(
    (option: number) => {
      setConfirmModalVisible(false);
      if (option === 1) {
        dispatch(showLoader());
        const flex: Flex = {
          collection: 'users',
          _id: selectedUser?._id,
        };

        flexDelete(flex)
          .then(() => {
            dispatch(hideLoader());
            setCoaches(undefined);
            setSelectedUser(undefined);
          })
          .catch((err) => {
            console.log(err);
            dispatch(hideLoader());
            if (err?.data && err.data?.message) {
              alert(err.data.message);
            } else {
              alert('Unable to complete this operation.');
            }
          });
      }
    },
    [dispatch, selectedUser]
  );

  const onInviteCoachClick = () => {
    dispatch(setModal(Modals.InviteCoachModal));
  };

  const loadCoaches = useCallback(() => {
    dispatch(showLoader());
    const flex: Flex = {
      collection: 'users',
      query: {
        usertype: UserType.Coach,
      },
    };
    flexGetAll(flex)
      .then((coachesResponse) => {
        dispatch(hideLoader());
        setCoaches(coachesResponse.data);
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [dispatch]);

  useEffect(() => {
    if (coaches === undefined) {
      loadCoaches();
    }
  }, [coaches, loadCoaches]);

  const onViewSelectedCoach = useCallback(
    (user: User) => {
      dispatch(setSelectedCoach(user));
      dispatch(setScreen(AppScreens.CoachProfile));
    },
    [dispatch]
  );

  return (
    <>
      <div className='container-fluid h-100 d-flex bordered'>
        <div className='row flex-grow-1 bg-white'>
          <Comp.SideMenu></Comp.SideMenu>
          <div className='col page-content p-0'>
            <Comp.NavBar></Comp.NavBar>
            <div className='p-5'>
              <div className='row gx-0 align-items-center'>
                <div className='col page-heading heading-darkblue py-2 ps-3 border-bottom'>
                  <div className='row g-0 align-items-center'>
                    <img
                      src='images/svg/coaching.svg'
                      className='heading-image'
                      alt=''
                    />
                    <div className='col'>
                      <h1 className='my-0'>Nabantu Coaches</h1>
                      <div>Coach Data</div>
                    </div>
                    <div className='col-3 text-end'>
                      <a
                        onClick={onInviteCoachClick}
                        className='btn btn-brightblue btn-block'
                      >
                        Invite Coach
                      </a>
                    </div>
                  </div>
                  <hr />
                  <div className='row align-items-center'>
                    <div className='col-5'>
                      <table className='table tbl-no-border mb-0'>
                        <tbody>
                          <tr>
                            <td>Associate coaches</td>
                            <td>
                              :{' '}
                              {coaches
                                ?.filter(
                                  (coach) => coach.rate === Rate.AssociateCoach
                                )
                                .length.toString()}
                            </td>
                          </tr>
                          <tr>
                            <td>Mid-level coaches</td>
                            <td>
                              :{' '}
                              {coaches
                                ?.filter(
                                  (coach) => coach.rate === Rate.MidLevelCoach
                                )
                                .length.toString()}
                            </td>
                          </tr>
                          <tr>
                            <td>Excecutive coaches</td>
                            <td>
                              :{' '}
                              {coaches
                                ?.filter(
                                  (coach) => coach.rate === Rate.ExecutiveCoach
                                )
                                .length.toString()}
                            </td>
                          </tr>
                          <tr>
                            <td>Master coaches</td>
                            <td>
                              :{' '}
                              {coaches
                                ?.filter(
                                  (coach) => coach.rate === Rate.MasterCoach
                                )
                                .length.toString()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-4'>
                <div className='bg-fadedblue p-3 rnd-cnr'>
                  <div className='row align-items-center px-2'>
                    {/*   <div className="col"></div>
                    <div className="col">
                      <input
                        className="form-control me-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </div>
                    <div className="col-2">
                      <div className="dropdown">
                        <a
                          className="btn dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Filter
                        </a>

                        <ul
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              filter 1
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              filter 2
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              filter 3
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                  <table className='table align-middle'>
                    <thead className='text-center'>
                      <tr>
                        <th scope='col'>Coach</th>
                        <th scope='col'>Coach level</th>
                        <th scope='col'>Accreditaion</th>
                        <th scope='col'>Coaching Hours</th>
                        <th scope='col'>Personal rate</th>
                        <th scope='col'>App visibility</th>
                        <th scope='col'></th>
                      </tr>
                    </thead>
                    <tbody className='text-center'>
                      {coaches?.map((coach) => {
                        return (
                          <tr className='tbl-tr'>
                            <td
                              className='text-start'
                              onClick={() => onViewSelectedCoach(coach)}
                            >
                              <img
                                src={getProfilePicture(coach)}
                                className='tbl-img d-inline mx-2'
                                alt=''
                              />
                              {coach.firstname} {coach.lastname}
                            </td>
                            <td>{coach.rate}</td>
                            <td>{coach.accreditation}</td>
                            <td>{coach.hours}</td>
                            <td>R {coach.personalCoachingRate}/hr</td>
                            <td>
                              {(coach.blocked ?? false) === true
                                ? 'Blocked'
                                : coach.accepted && coach.paypalref
                                ? 'Active'
                                : 'Not Active'}
                            </td>
                            <td className='text-end'>
                              <a
                                onClick={() => onSelectToDelete(coach)}
                                className='btn bg-darkblue text-white'
                              >
                                Remove
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmModalVisible ? (
        <ConfirmModal
          title='Confirmation'
          message='Are you sure you want to remove this coach from the system?'
          buttonTitle1='Yes'
          buttonTitle2='No'
          onClose={onConfirmDeleteOption}
        ></ConfirmModal>
      ) : null}
    </>
  );
}

export default Coaches;
