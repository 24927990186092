import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCoacheesForProgramme,
  getOrgProfile,
} from '../../../api/report.api';
import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import { Coachee, Flex, OrgProfile } from '../../../interfaces';
import { RootState } from '../../../redux/store';
import { hideLoader, setScreen, showLoader } from '../../../redux/ui/uiSlice';
import { CoacheeTransactions } from '../../../interfaces/coachee-transactions';
import {
  Transaction,
  TransactionDisplay,
} from '../../../interfaces/transaction';
import { flexGetAll, flexUpdate } from '../../../api/flex.api';
import { AppScreens } from '../../../enums';
import { setSelectedCoachee } from '../../../redux/session/sessionSlice';
import {
  OrgProgrammeReport,
  UserForReport,
} from '../../../interfaces/backend.report';

function OrgReports() {
  const sessionState = useSelector((state: RootState) => state.session);
  const [coacheeTransactions, setCoacheeTransactions] = useState<
    Array<CoacheeTransactions> | undefined
  >();

  const [orgProfile, setOrgProfile] = useState<OrgProfile | undefined>();
  const dispatch = useDispatch();
  const isLoaded = useRef(false);
  const [transactions, setTransactions] = useState<
    Array<TransactionDisplay> | undefined
  >();

  const getSelectedCoaches = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      programme?.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            total +=
              tx.coachingSessions?.filter(
                (coachingSession) =>
                  !coachingSession.chemistry1 &&
                  !coachingSession.chemistry2 &&
                  coachingSession.bookingDate
              ).length > 0
                ? 1
                : 0;
          }
        });
      });
      return total;
    },
    []
  );

  const getCompletedChemistryHours = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      programme?.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            total += tx.coachingSessions?.filter(
              (coachingSession) =>
                (coachingSession.chemistry1 || coachingSession.chemistry2) &&
                coachingSession.coachConfirmedCompleted &&
                coachingSession.coacheeConfirmedCompleted
            ).length;
          }
        });
      });
      return total;
    },
    []
  );

  const getCompletedHours = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      programme?.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            total += tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.coachConfirmedCompleted &&
                coachingSession.coacheeConfirmedCompleted
            ).length;
          }
        });
      });
      return total;
    },
    []
  );

  const getCompletedChemistrySessions = (transactions: Array<Transaction>) => {
    let total = 0;
    transactions?.map((tx) => {
      total +=
        tx.coachingSessions?.filter(
          (item) =>
            item.chemistry1 === true ||
            (item.chemistry2 === true &&
              item.coachConfirmedCompleted &&
              item.coacheeConfirmedCompleted)
        )?.length ?? 0;
    });
    return total;
  };

  const getCompletedSessions = (transactions: Array<Transaction>) => {
    let total = 0;
    transactions?.map((tx) => {
      total +=
        tx.coachingSessions?.filter(
          (item) =>
            item.chemistry1 === false &&
            item.chemistry2 === false &&
            item.coachConfirmedCompleted &&
            item.coacheeConfirmedCompleted
        )?.length ?? 0;
    });
    return total;
  };

  // useEffect(() => {
  //   if (orgProfile === undefined && sessionState.org?._id !== '') {
  //     dispatch(showLoader());
  //     getOrgProfile(sessionState.org?._id ?? '')
  //       .then((orgProfileResponse) => {
  //         dispatch(hideLoader());
  //         if (orgProfileResponse.status === 200) {
  //           setOrgProfile(orgProfileResponse.data);
  //         }
  //       })
  //       .catch((err) => {
  //         dispatch(hideLoader());
  //         alert('Error: ' + JSON.stringify(err));
  //       });
  //   }
  // }, [orgProfile, dispatch, sessionState]);

  // useEffect(() => {
  //   if (coacheeTransactions === undefined) {
  //     dispatch(showLoader());
  //     getCoacheesForProgramme(sessionState.selectedProgramme?._id ?? '')
  //       .then((response) => {
  //         dispatch(hideLoader());
  //         console.log('response.status', response.status);
  //         console.log('response.data', response.data);
  //         if (response.status === 200) {
  //           setCoacheeTransactions(response.data);
  //         }
  //       })
  //       .catch(() => {
  //         dispatch(hideLoader());
  //       });
  //   }
  // }, [coacheeTransactions, dispatch, sessionState]);

  const getCoacheeSessionsCompletedPercentage = useCallback(
    (coachee: UserForReport) => {
      let total = 0;
      coachee?.transactions?.map((tx) => {
        total +=
          tx.coachingSessions?.filter(
            (item) =>
              item.chemistry1 === false &&
              item.chemistry2 === false &&
              item.coachConfirmedCompleted &&
              item.coacheeConfirmedCompleted
          )?.length ?? 0;
      });
      return (
        (total /
          (sessionState.selectedReportProgramme?.numberOfSessions ?? 0)) *
        100
      );
    },
    [sessionState.selectedReportProgramme]
  );

  const loadAll = useCallback(() => {
    dispatch(showLoader());

    const flexTransactions: Flex = {
      collection: 'transactions',
      query: {
        orgid: sessionState.org?._id,
      },
    };
    flexGetAll(flexTransactions).then((flexTransactionsResponse) => {
      if (flexTransactionsResponse.status === 200) {
        setTransactions(flexTransactionsResponse.data);
        dispatch(hideLoader());
      }
    });
  }, [dispatch, sessionState]);

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      loadAll();
    }
  }, [loadAll]);

  const viewReports = useCallback(() => {
    dispatch(setScreen(AppScreens.OrgMonthlyReport));
  }, [dispatch]);

  const removeCoachee = useCallback(
    (coachee: Coachee) => {
      //@ts-ignore
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure want to remove this coachee from the programme?')
      ) {
        dispatch(showLoader());
        const newCoachees: Array<Coachee> = [];
        sessionState.selectedProgramme?.coachees?.map(
          (existingCoachee: Coachee) => {
            if (
              coachee.email?.toUpperCase().trim() !==
              existingCoachee?.email?.toUpperCase().trim()
            ) {
              newCoachees.push(existingCoachee);
            }
          }
        );

        const newCoachingProgramme: any = {
          ...sessionState.selectedProgramme,
          coachees: newCoachees,
        };

        const flex: Flex = {
          collection: 'coaching-programme',
          _id: sessionState.selectedProgramme?._id ?? '',
          model: newCoachingProgramme,
        };

        flexUpdate(flex).then(() => {
          dispatch(hideLoader());
          loadAll();
        });
      }
    },
    [dispatch, sessionState, loadAll]
  );

  const viewCoacheeProfile = useCallback(
    (coachee: Coachee) => {
      dispatch(setSelectedCoachee(coachee));
      dispatch(setScreen(AppScreens.CoacheeProfile));
    },
    [dispatch]
  );

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row gx-0 align-items-center'>
              <div className='col page-heading heading-brightblue py-2 ps-3 border-bottom'>
                <div className='row g-0 align-items-center'>
                  <img
                    src='images/svg/user-group.svg'
                    className='heading-image'
                    alt=''
                  />
                  <div className='col'>
                    <h1 className='my-0'>Marketing Team</h1>
                    <div>&nbsp;</div>
                  </div>
                  <hr />
                  <div className='col-9 border-end border-lightblue'>
                    <table className='table tbl-no-border mb-0'>
                      <tbody>
                        <tr>
                          <td>Programme kick-off</td>
                          <td>
                            :
                            {sessionState.selectedProgramme?.createdAt !==
                            undefined
                              ? moment(
                                  new Date(
                                    sessionState.selectedProgramme?.createdAt
                                  )
                                ).calendar()
                              : ''}
                          </td>
                        </tr>
                        <tr>
                          <td>Programme completed on</td>
                          <td>: N/A</td>
                        </tr>
                        <tr>
                          <td>Programme type</td>
                          <td>
                            : {sessionState.selectedProgramme?.coachingType}
                          </td>
                        </tr>
                        <tr>
                          <td>NDA signed</td>
                          <td>: N/A</td>
                        </tr>
                        <tr>
                          <td>Chemistry session completed</td>
                          <td>
                            :{' '}
                            {getCompletedChemistryHours(
                              sessionState.selectedReportProgramme ?? undefined
                            )}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Selected coaches</td>
                          <td>
                            :{' '}
                            {getSelectedCoaches(
                              sessionState.selectedReportProgramme ?? undefined
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Coaching Sessions completed</td>
                          <td>
                            :{' '}
                            {getCompletedHours(
                              sessionState.selectedReportProgramme ?? undefined
                            )}{' '}
                            hours
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='col-3 ps-3 text-center'>
                    <a
                      className='btn btn-sml btn-brightblue btn-block mb-4'
                      href='#'
                      onClick={() => viewReports()}
                    >
                      View reports
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-4'>
              <div className='row text-center th'>
                <div className='col-3'>Coachee</div>
                <div className='col-1'>Allocated hours</div>
                <div className='col-4'>Progress</div>
                <div className='col-1'>Late cancellations</div>
                <div className='col-2'>Allocated Budget</div>
                <div className='col-1'>Remove</div>
              </div>

              <div className='p-2'>
                {sessionState.selectedReportProgramme?.coachees?.map(
                  (coachee) => {
                    return (
                      <div className='row align-items-center text-center tbl-tr py-3'>
                        <a
                          href='#'
                          onClick={() => viewCoacheeProfile(coachee)}
                          className='col-3 text-start d-table ps-4'
                        >
                          <img
                            src='./images/avatar.png'
                            className='tbl-img d-table-cell'
                          />
                          <div className='d-table-cell align-middle ps-2'>
                            {coachee.firstname} {coachee.lastname}
                          </div>
                        </a>
                        <div className='col-1'>
                          {sessionState.selectedProgramme?.numberOfSessions ??
                            0}
                        </div>
                        <div className='col-4'>
                          <div className='progress bg-fadedblue rounded-pill'>
                            <div
                              className='progress-bar bg-brightblue rounded-pill'
                              role='progressbar'
                              style={{
                                width:
                                  getCoacheeSessionsCompletedPercentage(
                                    coachee
                                  ) + '%',
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className='col-1'>0</div>
                        <div className='col-2'>
                          R{' '}
                          {(sessionState.selectedProgramme?.numberOfSessions ??
                            0) *
                            (sessionState.selectedProgramme?.coachingRate ?? 0)}
                        </div>
                        <div className='col-1'>
                          <a href='#' onClick={() => removeCoachee(coachee)}>
                            <img
                              src='images/icons/icon_blue_minus.png'
                              alt=''
                              width={20}
                              height={20}
                            />
                          </a>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgReports;
