import NavBar from '../../../components/nav-bar';
import SideMenu from '../../../components/side-menu';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { AppScreens } from '../../../enums';
import { showLoader, hideLoader, setScreen } from '../../../redux/ui/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { OrgProgrammeReport } from '../../../interfaces/backend.report';
import { getAllOrgProgrammes } from '../../../api/backend-report.api';
import {
  setSelectedProgramme,
  setSelectedReportProgramme,
} from '../../../redux/session/sessionSlice';

function ClientProfile() {
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const [orgProgrammes, setOrgProgrammes] =
    useState<Array<OrgProgrammeReport>>();
  const initialised = useRef(false);

  const getSelectedCoaches = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;

      if (programme === undefined) {
        orgProgrammes?.map((programme) => {
          programme.coachees.map((coachee) => {
            coachee.transactions.map((tx) => {
              if (tx.coachingSessions) {
                total +=
                  tx.coachingSessions?.filter(
                    (coachingSession) =>
                      !coachingSession.chemistry1 &&
                      !coachingSession.chemistry2 &&
                      coachingSession.bookingDate
                  ).length > 0
                    ? 1
                    : 0;
              }
            });
          });
        });
      } else {
        programme.coachees.map((coachee) => {
          coachee.transactions.map((tx) => {
            if (tx.coachingSessions) {
              total +=
                tx.coachingSessions?.filter(
                  (coachingSession) =>
                    !coachingSession.chemistry1 &&
                    !coachingSession.chemistry2 &&
                    coachingSession.bookingDate
                ).length > 0
                  ? 1
                  : 0;
            }
          });
        });
      }
      return total;
    },
    [orgProgrammes]
  );

  const getCompletedHours = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      if (programme === undefined) {
        orgProgrammes?.map((programme) => {
          programme.coachees.map((coachee) => {
            coachee.transactions.map((tx) => {
              if (tx.coachingSessions) {
                total += tx.coachingSessions?.filter(
                  (coachingSession) =>
                    !coachingSession.chemistry1 &&
                    !coachingSession.chemistry2 &&
                    coachingSession.coachConfirmedCompleted &&
                    coachingSession.coacheeConfirmedCompleted
                ).length;
              }
            });
          });
        });
      } else {
        programme.coachees.map((coachee) => {
          coachee.transactions.map((tx) => {
            if (tx.coachingSessions) {
              total += tx.coachingSessions?.filter(
                (coachingSession) =>
                  !coachingSession.chemistry1 &&
                  !coachingSession.chemistry2 &&
                  coachingSession.coachConfirmedCompleted &&
                  coachingSession.coacheeConfirmedCompleted
              ).length;
            }
          });
        });
      }
      return total;
    },
    [orgProgrammes]
  );

  const getTotalMoneySpent = useCallback(() => {
    let total = 0;
    orgProgrammes?.map((programme) => {
      programme.coachees.map((coachee) => {
        coachee.transactions.map((tx) => {
          if (tx.coachingSessions) {
            const completed = tx.coachingSessions?.filter(
              (coachingSession) =>
                !coachingSession.chemistry1 &&
                !coachingSession.chemistry2 &&
                coachingSession.coachConfirmedCompleted &&
                coachingSession.coacheeConfirmedCompleted
            ).length;
            total += completed * (programme.coachRate ?? 0);
          }
        });
      });
    });
    return total;
  }, [orgProgrammes]);

  const getHoursInProgress = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      if (programme === undefined) {
        orgProgrammes?.map((programme) => {
          programme.coachees.map((coachee) => {
            coachee.transactions.map((tx) => {
              if (tx.coachingSessions) {
                total += tx.coachingSessions?.filter(
                  (coachingSession) =>
                    !coachingSession.chemistry1 &&
                    !coachingSession.chemistry2 &&
                    !coachingSession.coachConfirmedCompleted &&
                    !coachingSession.coacheeConfirmedCompleted
                ).length;
              }
            });
          });
        });
      } else {
        programme.coachees.map((coachee) => {
          coachee.transactions.map((tx) => {
            if (tx.coachingSessions) {
              total += tx.coachingSessions?.filter(
                (coachingSession) =>
                  !coachingSession.chemistry1 &&
                  !coachingSession.chemistry2 &&
                  !coachingSession.coachConfirmedCompleted &&
                  !coachingSession.coacheeConfirmedCompleted
              ).length;
            }
          });
        });
      }
      return total;
    },
    [orgProgrammes]
  );

  const getBudget = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      if (programme === undefined) {
        orgProgrammes?.map((programme) => {
          if (programme.coachees.length === 0) {
          } else {
            total +=
              (programme.coachRate ?? 0) *
              programme.coachees.length *
              programme.numberOfSessions;
          }
        });
      } else {
        console.log('programme.coachees.length', programme.coachRate);
        total +=
          (programme.coachRate ?? 0) *
          programme.coachees.length *
          programme.numberOfSessions;
      }
      return total;
    },
    [orgProgrammes]
  );

  const getSpent = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      if (programme === undefined) {
        orgProgrammes?.map((programme) => {
          programme.coachees.map((coachee) => {
            coachee.transactions.map((tx) => {
              if (tx.coachingSessions) {
                const completed = tx.coachingSessions?.filter(
                  (coachingSession) =>
                    !coachingSession.chemistry1 &&
                    !coachingSession.chemistry2 &&
                    coachingSession.coachConfirmedCompleted &&
                    coachingSession.coacheeConfirmedCompleted
                ).length;
                total += completed * (programme.coachRate ?? 0);
              }
            });
          });
        });
      } else {
        programme.coachees.map((coachee) => {
          coachee.transactions.map((tx) => {
            if (tx.coachingSessions) {
              const completed = tx.coachingSessions?.filter(
                (coachingSession) =>
                  !coachingSession.chemistry1 &&
                  !coachingSession.chemistry2 &&
                  coachingSession.coachConfirmedCompleted &&
                  coachingSession.coacheeConfirmedCompleted
              ).length;
              total += completed * (programme.coachRate ?? 0);
            }
          });
        });
      }
      return total;
    },
    [orgProgrammes]
  );

  const getBalance = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      return getBudget(programme) - getSpent(programme);
    },
    [getBudget, getSpent]
  );

  const getCoachCount = useCallback(
    (programme: OrgProgrammeReport | undefined) => {
      let total = 0;
      if (programme === undefined) {
        orgProgrammes?.map((programme) => {
          total += programme.coaches.length;
        });
      } else {
        total += programme.coaches.length;
      }
      return total;
    },
    [orgProgrammes]
  );

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      dispatch(showLoader());
      getAllOrgProgrammes(sessionState.selectedOrg?._id ?? '')
        .then((orgProfileResponse) => {
          dispatch(hideLoader());
          if (orgProfileResponse.status === 200) {
            setOrgProgrammes(orgProfileResponse.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert('Error: ' + JSON.stringify(err));
        });
    }
  }, [dispatch, sessionState]);

  const viewPastCoaching = () => {
    dispatch(setScreen(AppScreens.PastCoaching));
  };

  const onViewCoachees = (programme: OrgProgrammeReport | undefined) => {
    dispatch(setSelectedProgramme(programme ?? null));
    dispatch(setSelectedReportProgramme(programme ?? null));
    dispatch(setScreen(AppScreens.CoachingProgress));
  };

  const onInvoice = (programme: OrgProgrammeReport | undefined) => {
    dispatch(setSelectedProgramme(programme ?? null));
    dispatch(setSelectedReportProgramme(programme ?? null));
    alert('Unexpected Error: Please screenshot and send to admin.');
    // dispatch(setScreen(AppScreens.BillingBreakdown));
  };

  return (
    <div className='container-fluid h-100 d-flex'>
      <div className='row flex-grow-1 bg-white'>
        <SideMenu />
        <div className='col page-content p-0'>
          <NavBar />
          <div className='p-5'>
            <div className='row align-items-center'>
              <h1>Organisation Profile</h1>
              <hr />
              <div className='col py-2 border-bottom'>
                <div className='row align-items-center'>
                  <div className='col-1 text-center p-0'>
                    <img src='images/placeholder.png' className='client-logo' />
                  </div>
                  <div className='col-11'>
                    <h1 className='my-0'>
                      {sessionState.selectedOrg?.organisation}
                    </h1>
                    <div>Overall coaching info</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row align-items-center py-3 border-bottom mb-3'>
              <div className='col border-end border-lightblue'>
                <table className='table tbl-no-border mb-0'>
                  <tbody>
                    <tr>
                      <td>Number of teams</td>
                      <td>: {orgProgrammes?.length}</td>
                    </tr>
                    <tr>
                      <td>Coaches</td>
                      <td>: {getCoachCount(undefined)}</td>
                    </tr>
                    <tr>
                      <td>Coaches selected</td>
                      <td>: {getSelectedCoaches(undefined)}</td>
                    </tr>
                    <tr>
                      <td>Completed coaching</td>
                      <td>: {getCompletedHours(undefined)} hours</td>
                    </tr>
                    <tr>
                      <td>Coaching in progress</td>
                      <td>: {getHoursInProgress(undefined)} hours</td>
                    </tr>
                    <tr>
                      <td>Total money spent</td>
                      <td>: R {getTotalMoneySpent()?.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='col-3 d-grid'>
                {/* <a
                  className="btn btn-sml btn-brightblue btn-block mb-4"
                  href="">
                  Edit profile info
                </a> */}
                <a
                  className='btn btn-sml btn-brightblue btn-block'
                  onClick={() => viewPastCoaching()}
                >
                  View past coaching
                </a>
              </div>
            </div>

            <h4 className='medblue'>Coaching in progress</h4>

            {orgProgrammes?.map((coachingProgramme) => {
              return (
                <div className='row mt-4'>
                  <div className='col-2 n-card bg-medblue py-3 px-2 text-center text-white'>
                    <div className='icon-avatar bg-white m-auto'>
                      <img
                        src='./images/svg/user-group.svg'
                        className='icon-medblue'
                      />
                    </div>
                    <div className='h5 mt-2 mb-0'>
                      {coachingProgramme.department}
                    </div>
                    <div className='fnt-wt-400 fnt-small'>
                      {coachingProgramme.coachees.length} Coachees
                    </div>
                    {/* <a className="btn btn-sml btn-transparent text-white light-italic mt-2">
                      <i className="fa fa-plus-circle me-1"></i> Add a coachee
                    </a> */}
                  </div>
                  <div className='col-4 p-3'>
                    <table className='table tbl-no-border'>
                      <tbody>
                        <tr>
                          <td>Number of participants</td>
                          <td>
                            <strong>{coachingProgramme.coachees.length}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src='images/icons/bullet.png' /> Sessions
                            completed
                          </td>
                          <td>
                            <strong>
                              {getCompletedHours(coachingProgramme)} hrs
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src='images/icons/bullet.png' /> Cancelled
                            sessions
                          </td>
                          <td>
                            <strong>0 hrs</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src='images/icons/bullet.png' /> Outstanding
                            sessions
                          </td>
                          <td>
                            <strong>
                              {getHoursInProgress(coachingProgramme)} hrs
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className='text-center pt-2'>
                      <a
                        className='btn btn-darkblue btn-block'
                        onClick={() => onViewCoachees(coachingProgramme)}
                      >
                        View Coachees
                      </a>
                    </div>
                  </div>
                  <div className='col-6 n-card p-3 bg-medblue text-white'>
                    <div className='row h-100'>
                      <div className='col-8'>
                        <div>BUDGET</div>
                        <h2 className='text-white'>
                          R {getBudget(coachingProgramme).toFixed(2)}
                        </h2>
                        <div>Programme Coaching rate</div>
                        <div>
                          R{coachingProgramme?.coachingRate?.toFixed(2)}/hr
                        </div>
                        <div className='row mt-3'>
                          <div className='col'>
                            <div className='budget-label'>Balance</div>
                            <div>
                              R {getBalance(coachingProgramme).toFixed(2)}
                            </div>
                          </div>
                          <div className='col border-start '>
                            <div className='budget-label'>Spent</div>
                            <div>
                              R {getSpent(coachingProgramme).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col text-center align-self-center'>
                        <div>
                          <img
                            src='images/svg/billing.svg'
                            className='icon-60 icon-white'
                          />
                        </div>
                        <a
                          onClick={() => onInvoice(coachingProgramme)}
                          className='btn btn-sml btn-white mt-3'
                        >
                          Invoice
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientProfile;
