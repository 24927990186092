import { setModal } from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from '../../redux/store';
import MonthlyReport from '../../reports/monthly-report';
import { PDFViewer } from '@react-pdf/renderer';
import { Modals } from '../../enums';
import AttendanceReport from '../../reports/attendance-report';
import TimesheetReport from '../../reports/timesheet-report';

export interface ReportViewerProps {
  report: string;
  data: any;
}

function ReportViewer() {
  const uiState = useSelector((state: RootState) => state.ui);
  const report: ReportViewerProps = uiState.modalData;
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(setModal(Modals.None));
  }, [dispatch]);

  return (
    <div
      className='modal modal-visible modal-backdrop2'
      id='exampleModal'
      role='dialog'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>{report.report}</h4>
          </div>
          <div className='modal-body'>
            <PDFViewer style={{ width: 730, height: 600 }}>
              <>
                {report.report === 'Attendance Report' ? (
                  <AttendanceReport params={report}></AttendanceReport>
                ) : null}
                {report.report === 'Timesheet Report' ? (
                  <TimesheetReport params={report}></TimesheetReport>
                ) : null}
                {report.report === 'Monthly Report' ? (
                  <MonthlyReport params={report}></MonthlyReport>
                ) : null}
              </>
            </PDFViewer>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-darkblue'
              data-dismiss='modal'
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportViewer;
