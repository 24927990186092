import axios, { AxiosResponse } from "axios";
import { Config } from "../config";

export const uploadDocument = (formData: FormData) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: "post",
      url: `${Config.API_SERVER}/upload/document`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};

export const uploadImage = (formData: FormData) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios({
      method: "post",
      url: `${Config.API_SERVER}/upload/image`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err.response));
  });
};
