import { hideMessage } from '../../redux/ui/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from '../../redux/store';
import { useState } from 'react';
import { Rate } from '../../enums';

interface EditCoachRatesProps {
  onUpdate: (personalRate?: number, nabantuRate?: Rate) => void;
  onCancel: () => void;
}

function EditCoachRates({ onUpdate, onCancel }: EditCoachRatesProps) {
  const sessionState = useSelector((state: RootState) => state.session);
  const [personalRate, setPersonalRate] = useState(
    sessionState.selectedCoach?.personalCoachingRate ?? 0,
  );
  const [nabantuRate, setNabantuRate] = useState(
    sessionState.selectedCoach?.rate ?? Rate.AssociateCoach,
  );

  const onOk = useCallback(() => {
    if ((personalRate ?? 0) === 0) {
      alert('Personal rate is required');
      return;
    } else {
      onUpdate(personalRate, nabantuRate);
    }
  }, [nabantuRate, personalRate, onUpdate]);

  const onClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <div
      className="modal modal-visible modal-backdrop2"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Update Coach Rates</h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    value={personalRate}
                    onChange={val => setPersonalRate(Number(val.target.value))}
                  />
                  <label>Personal Rate</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-floating mb-3">
                  <select
                    className="form-control"
                    id="floatingInput"
                    value={nabantuRate}
                    onChange={val => setNabantuRate(val.target.value as Rate)}>
                    <option value=""></option>
                    <option value={Rate.AssociateCoach}>
                      {Rate.AssociateCoach}
                    </option>
                    <option value={Rate.MidLevelCoach}>
                      {Rate.MidLevelCoach}
                    </option>
                    <option value={Rate.ExecutiveCoach}>
                      {Rate.ExecutiveCoach}
                    </option>
                    <option value={Rate.MasterCoach}>{Rate.MasterCoach}</option>
                  </select>
                  <label>Nabantu Rate</label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-darkblue"
                data-dismiss="modal"
                onClick={onOk}>
                OK
              </button>
              <button
                type="button"
                className="btn btn-darkblue"
                data-dismiss="modal"
                onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCoachRates;
