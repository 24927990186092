import React, { useState, useCallback, useEffect } from 'react';
import { Flex, Notification } from '../../../interfaces';
import Comp from '../../../components';
import {
  showLoader,
  hideLoader,
  setModal,
  setModalData,
  setScreen,
} from '../../../redux/ui/uiSlice';
import { useDispatch } from 'react-redux';
import { flexDelete, flexGetAll } from '../../../api';
import ConfirmModal from '../../../modals/confirm-modal';
import { Modals } from '../../../enums';
import { Config } from '../../../config';

function Notifications() {
  const [Notifications, setNotifications] = useState<
    Array<Notification> | undefined
  >(undefined);
  const dispatch = useDispatch();
  const [selectedNotification, setSelectedNotification] = useState<
    Notification | undefined
  >();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const onSelectToDelete = useCallback(
    (item: Notification) => {
      setSelectedNotification(item);
      setConfirmModalVisible(true);
    },
    [setConfirmModalVisible],
  );

  const onView = useCallback(
    (item: Notification) => {
      dispatch(setModalData(item));
      dispatch(setModal(Modals.CoachSelectionNotificationModal));
    },
    [dispatch],
  );

  const onConfirmDeleteOption = useCallback(
    (option: number) => {
      setConfirmModalVisible(false);
      if (option === 1) {
        dispatch(showLoader());
        const flex: Flex = {
          collection: 'notifications',
          _id: selectedNotification?._id,
        };

        flexDelete(flex)
          .then(() => {
            dispatch(hideLoader());
            setNotifications(undefined);
            setSelectedNotification(undefined);
          })
          .catch(err => {
            console.log(err);
            dispatch(hideLoader());
            if (err?.data && err.data?.message) {
              alert(err.data.message);
            } else {
              alert('Unable to complete this operation.');
            }
          });
      }
    },
    [dispatch, selectedNotification],
  );

  const loadNotifications = useCallback(() => {
    dispatch(showLoader());
    const flex: Flex = {
      collection: 'notifications',
      query: {
        receiverid: Config.ADMIN_USER,
      },
    };
    flexGetAll(flex)
      .then(NotificationsResponse => {
        dispatch(hideLoader());
        setNotifications(NotificationsResponse.data);
      })
      .catch(err => {
        console.log(err);
        dispatch(hideLoader());
        if (err?.data && err.data?.message) {
          alert(err.data.message);
        } else {
          alert('Unable to complete this operation.');
        }
      });
  }, [dispatch]);

  useEffect(() => {
    if (Notifications === undefined) {
      loadNotifications();
    }
  }, [Notifications, loadNotifications]);

  return (
    <>
      <div className="container-fluid h-100 d-flex bordered">
        <div className="row flex-grow-1 bg-white">
          <Comp.SideMenu></Comp.SideMenu>
          <div className="col page-content p-0">
            <Comp.NavBar></Comp.NavBar>
            <div className="p-5">
              <div className="row gx-0 align-items-center">
                <div className="col page-heading heading-darkblue py-2 ps-3 border-bottom">
                  <div className="row g-0 align-items-center">
                    <img
                      src="images/svg/Notificationing.svg"
                      className="heading-image"
                      alt=""
                    />
                    <div className="col">
                      <h1 className="my-0">Notifications</h1>
                      <div>Notification Data</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="bg-fadedblue p-3 rnd-cnr">
                  <div className="row align-items-center px-2">
                    {/*   <div className="col"></div>
                    <div className="col">
                      <input
                        className="form-control me-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </div>
                    <div className="col-2">
                      <div className="dropdown">
                        <a
                          className="btn dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Filter
                        </a>

                        <ul
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              filter 1
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              filter 2
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              filter 3
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                  <table className="table align-middle">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Message</th>
                        <th scope="col">Type</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {Notifications?.map(item => {
                        return (
                          <tr className="tbl-tr">
                            {/* <td className="text-start">
                              <img
                                src="images/avatar.png"
                                className="tbl-img d-inline mx-2"
                                alt=""
                              />
                              {Notification.firstname} {Notification.lastname}
                            </td> */}
                            <td>{item.createdAt.toString()}</td>
                            <td>{item.message}</td>
                            <td>{item.notificationType}</td>
                            <td className="text-end">
                              <a
                                onClick={() => onView(item)}
                                className="btn bg-darkblue text-white">
                                View
                              </a>
                            </td>
                            <td className="text-end">
                              <a
                                onClick={() => onSelectToDelete(item)}
                                className="btn bg-darkblue text-white">
                                Remove
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmModalVisible ? (
        <ConfirmModal
          title="Confirmation"
          message="Are you sure you want to remove this Notification?"
          buttonTitle1="Yes"
          buttonTitle2="No"
          onClose={onConfirmDeleteOption}></ConfirmModal>
      ) : null}
    </>
  );
}

export default Notifications;
