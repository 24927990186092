export enum AppScreens {
  Login = 'Login',
  Dashboard = 'Dashboard',
  Reports = 'Reports',
  Billing = 'Billing',
  Coaches = 'Coaches',
  Coachees = 'Coachees',
  AdminDropBox = 'AdminDropBox',
  CoachingApplication = 'CoachingApplication',
  ClientList = 'ClientList',
  ClientProfile = 'ClientProfile',
  PastCoaching = 'PastCoaching',
  PastCoachingDetails = 'PastCoachingDetails',
  CoachingProgress = 'CoachingProgress',
  CoachingAttendanceReport = 'CoachingAttendanceReport',
  MonthlyReport = 'MonthlyReport',
  BillingBreakdown = 'BillingBreakdown',
  CoachProfile = 'CoachProfile',
  OrgDashboard = 'OrgDashboard',
  MarketingTeam = 'MarketingTeam',
  CoacheeProfile = 'CoacheeProfile',
  CoacheePastCoaching = 'CoacheePastCoaching',
  OrgReports = 'OrgReports',
  OrgMonthlyReport = 'OrgMonthlyReport',
  OrgBilling = 'OrgBilling',
  OrgTeamBilling = 'OrgTeamBilling',
  OrgPastCoaching = 'OrgPastCoaching',
  OrgPastTeamCoaching = 'OrgPastTeamCoaching',
  Notifications = 'Notifications',
  CoachTimesheets = 'CoachTimesheets',
  ManageLibrary = 'ManageLibrary',
}

export enum Modals {
  None = '',
  CoachProgrammeSetup = 'CoachProgrammeSetup',
  CoachProgrammeCoaches = 'CoachProgrammeCoaches',
  EditOrg = 'EditOrg',
  EditTeam = 'EditTeam',
  NewCoacheeRequest = 'NewCoacheeRequest',
  CoachDenial = 'CoachDenial',
  CoachSelectionNotificationModal = 'CoachSelectionNotificationModal',
  InviteCoachModal = 'InviteCoachModal',
  InviteCoacheeModal = 'InviteCoacheeModal',
  AttendanceReportFilter = 'AttendanceReportFilter',
  AcceptCoach = 'AcceptCoach',
  ReportViewer = 'ReportViewer',
  EditMonthlyReport = 'EditMonthlyReport',
}

export enum UserType {
  Admin = 0,
  Company = 1,
  Coach = 2,
  Coachee = 3,
}

export enum Colors {
  DarkBlue = '#1B1B4D',
  LightBlue = '#2657A4',
  White = '#FFFFFF',
  Black = '#000000',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  NonNinary = 'Non-binary',
}

export enum Race {
  Black = 'Black',
  Coloured = 'Coloured',
  Indian = 'Indian',
  White = 'White',
}

export enum Language {
  Afrikaans = 'Afrikaans',
  English = 'English',
  Ndebele = 'Ndebele',
  NorthernSotho = 'Northern Sotho',
  Sotho = 'Sotho',
  Swati = 'Swati',
  Tsonga = 'Tsonga',
  Tswana = 'Tswana',
  Venda = 'Venda',
  Xhosa = 'Xhosa',
  Zulu = 'Zulu',
}

export enum Qualifications {
  BusinessManagemen = 'Business Management',
  Finance = 'Finance',
}

export enum Accreditation {
  IFC = 'IFC',
  Comensa = 'Comensa',
}

export enum Rate {
  AssociateCoach = 'Associate coach',
  MidLevelCoach = 'Mid-level coach',
  ExecutiveCoach = 'Executive coach',
  MasterCoach = 'Master Coach',
}

export enum DocumentType {
  ProfilePicture = 0,
}

export enum DocumentStatus {
  Pending = 0,
  Rejected = 1,
  Approved = 2,
}

export enum RequestType {
  CoacheeAddition = 0,
}

export enum CoachingType {
  GroupTeamCoaching = 'Group / Team Coaching',
  StressResilience = 'Stress / Resilience Coaching',
  ConflictCoaching = 'Conflict Coaching',
  EntrepreneurCoaching = 'Entrepreneur Coaching',
  SalesCoaching = 'Sales Coaching',
  SomaticBodyCoaching = 'Somatic / Body Coaching',
  OntologicalCoaching = 'Ontological Coaching',
  LeadershipStrategy = 'Leadership Strategy',
  LeadershipDevelopment = 'Leadership Development',
  ExecutiveCoaching = 'Executive Coaching',
  LeadingChange = 'Leading Change',
  LeaderAsCoach = 'Leader as Coach',
  LeadershipBrandReputation = 'Leadership Brand & Reputation',
  LanguageForLeaders = 'Language for Leaders',
  PerceptionManagement = 'Perception Management',
  EmotionalLearningForBetterLeadership = 'Emotional Learning for Better Leadership',
}

export enum SessionType {
  Chemistry = 'Chemistry',
  Coaching = 'Coaching',
}

export enum MeetingType {
  Virtual = 'Virtual',
  FaceToFace = 'FaceToFace',
}

export enum TransactionType {
  CreditCard = 'CreditCard',
  OrgCode = 'OrgCode',
  PromoCode = 'PromoCode',
}

export enum TransactionStatus {
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Processed = 'Processed',
}

export enum NotificationType {
  ChatInvite = 'Chat Invite',
  Message = 'Message',
  CoachingInvite = 'Coaching Invite',
  ConfirmCoachingComplete = 'Confirm Coaching Complete',
  SessionReminder = 'Session Reminder',
  FirstChemistryCompleted = '1st Chemistry Session Completed',
  SecondChemistryCompleted = '2nd Chemistry Session Completed',
  ReminderSessionsLeft = 'Reminder Sessions Left',
  RateCoachingSession = 'Rate Coaching Session',
  AdminCoachingApprovalRequest = 'AdminCoachingApprovalRequest',
  CertificateUpload = 'CertificateUpload',
  RequestReport = 'RequestReport',
  CoacheeSelectedCoach = 'Coachee Selected Coach',
  // ConfirmCoaching = 'Confirm Coaching',
}

export enum TypeOfPayment {
  OnceOff = 'Once Off',
  PerSession = 'Per Session',
}
